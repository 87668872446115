import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const Caret: React.FC<IconBasePropsWithColor> = ({ width = 20, height = 20, fill = 'none' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill}>
      <path
        d="M12.2568 9.41667L8.92845 6.07583C8.81185 5.95894 8.66316 5.87931 8.50123 5.84706C8.33931 5.81481 8.17145 5.83138 8.01896 5.89468C7.86647 5.95798 7.73622 6.06515 7.64474 6.2026C7.55325 6.34004 7.50466 6.50156 7.50512 6.66667L7.49512 13.3333C7.49482 13.4982 7.54342 13.6594 7.63476 13.7966C7.7261 13.9338 7.85608 14.0409 8.00826 14.1042C8.16044 14.1675 8.32798 14.1843 8.4897 14.1524C8.65142 14.1205 8.80005 14.0414 8.91678 13.925L12.2551 10.5967C12.4113 10.4404 12.4991 10.2285 12.4991 10.0075C12.4991 9.78653 12.4113 9.57461 12.2551 9.41833L12.2568 9.41667Z"
        fill="#0077C5"
      />
    </svg>
  );
};

export default Caret;
