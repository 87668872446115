import React, { Fragment, useEffect } from 'react';

import Hr from 'components/Shared/Hr/Hr';
import Chevron from 'components/Shared/Icons/Chevron';
import useComponentVisible from 'hooks/useComponentVisible';
import { useIsMobileScreen } from 'hooks/useIsMobile';
// import { Close } from 'components/Icons';
import SegmentIO from 'reporting/SegmentIO';
import { breakpoints, colors } from 'styles/cp';
// import { isIOSDevice } from 'server/helpers/deviceInfo';

export interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLSelectElement>> {
  country: Record<string, any>;
  onChange: (value: any) => void;
  cdn: string;
}

export const CountryField: React.FC<Props> = ({ cdn, country, onChange }) => {
  const [filter, setFilter] = React.useState<null | string>(null);
  const [options, setOptions] = React.useState<Array<any>>([]);
  const topOptions = [
    { n: 'United States of America', a2: 'US', a3: 'USA' },
    { n: 'Canada', a2: 'CA', a3: 'CAN' },
    { n: 'United Kingdom', a2: 'GB', a3: 'GBR' },
  ];

  const isMobile = useIsMobileScreen();
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMobile) {
      import('server/helpers/country-codes.json').then((countriesModule) => {
        const countries = countriesModule.default;
        setOptions(countries);
      });
    }
  });

  // const mobileInputRef = useRef<null | HTMLInputElement>(null);
  // const mobileContainerRef = useRef<null | any>(null);

  const optionsToShow = () => {
    if (isMobile) {
      return options;
    }
    return filter
      ? options.filter(({ n, a2, a3 }) => {
          const filterLowerCase = filter.toLowerCase();
          return (
            n.toLowerCase().includes(filterLowerCase) ||
            a2.toLowerCase().includes(filterLowerCase) ||
            a3.toLowerCase().includes(filterLowerCase)
          );
        })
      : options.filter(({ n }) => !topOptions.find((x) => x.n === n));
  };

  const valueToShow = () => {
    if (filter !== null) {
      return filter;
    }
    if (!isMobile) {
      return country.a3;
    }
    return country.n;
  };

  const onAbandonChange = () => {
    setFilter(null);
  };

  const {
    ref: optionsRef,
    isComponentVisible: areOptionsVisible,
    setIsComponentVisible,
  } = useComponentVisible(false, onAbandonChange);
  // const [isMobileVisible, setIsMobileVisible] = React.useState(false);

  const onClick = async (e: any) => {
    e.stopPropagation();
    SegmentIO.countryFieldClicked();
    setFilter('');
    if (!isMobile) {
      const countriesModule = await import('server/helpers/country-codes.json');
      const countries = countriesModule.default;
      setOptions(countries);
      setIsComponentVisible(true);
    } else {
      inputRef.current?.focus();
    }
  };

  const handleOptionClick = (option: any) => {
    const newCountry = options.find((x) => x.n === option.n);
    onChange(newCountry);
    SegmentIO.countryFieldUpdated(newCountry.a3);
    setFilter(null);
    setIsComponentVisible(false);
    // setIsMobileVisible(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isMobile) {
      const country = options.find((x) => x.n === e.target.value);
      if (country) {
        handleOptionClick(country);
      } else {
        setFilter(e.target.value);
      }
    } else {
      setFilter(e.target.value);
    }
  };

  const onBlur = () => {
    if (isMobile) {
      setFilter(null);
    }
  };

  // const onMobileClose = () => {
  //   setIsMobileVisible(false);
  //   setFilter(null);
  // };

  // noinspection CssInvalidPseudoSelector
  return (
    <Fragment>
      <div className="country-field">
        <label>
          <span className="icon">
            <img
              height={12}
              width={20}
              src={`${cdn}/flags/${country.a2.toLowerCase()}.svg`}
              alt={`${country.n} flag`}
            />
          </span>
          {!isMobile && (
            <span className="chevron">
              <Chevron rotate={areOptionsVisible ? 180 : 0} />
            </span>
          )}
          <input
            ref={inputRef}
            value={valueToShow()}
            type={isMobile ? 'text' : 'search'}
            list={isMobile ? 'countries' : ''}
            className="input"
            placeholder="Country"
            onChange={onInputChange}
            onClick={onClick}
            onBlur={() => onBlur()}
            autoComplete="nope"
          />
          {isMobile && (
            <datalist id="countries">
              {optionsToShow().map((x, i) => (
                <option key={i} value={x.n} />
              ))}
            </datalist>
          )}
        </label>
        {areOptionsVisible && !isMobile && (
          <ul ref={optionsRef} className="options">
            {filter === '' && (
              <Fragment>
                {topOptions.map((x, i) => (
                  <li key={i} className="option" onClick={() => handleOptionClick(x)} tabIndex={i}>
                    <img
                      height={12}
                      width={20}
                      src={`${cdn}/flags/${x.a2.toLowerCase()}.svg`}
                      alt={`${x.n} flag`}
                    />
                    <span className="name">{isMobile ? x.n : x.a3}</span>
                  </li>
                ))}
                <Hr marginBottom={4} marginTop={4} />
              </Fragment>
            )}
            {optionsToShow().map((x, i) => (
              <li key={i} className="option" onClick={() => handleOptionClick(x)} tabIndex={i}>
                <img
                  height={12}
                  width={20}
                  src={`${cdn}/flags/${x.a2.toLowerCase()}.svg`}
                  alt={`${x.n} flag`}
                />
                <span className="name">{isMobile ? x.n : x.a3}</span>
              </li>
            ))}
          </ul>
        )}
        {/* {isMobileVisible && (
          <div className="mobile-options-container">
            <div ref={mobileContainerRef} className="mobile-options">
              <span className="close-icon" onClick={onMobileClose} role="button">
                <Close width={15} height={15} />
              </span>
              <input
                value={valueToShow()}
                ref={mobileInputRef}
                type="search"
                className="input"
                placeholder="Enter country name/code"
                onChange={onInputChange}
                autoComplete="nope"
              />
              <ul className="options">
                {filter === '' && (
                  <Fragment>
                    {topOptions.map((x, i) => (
                      <li
                        key={i}
                        className="option"
                        onClick={() => handleOptionClick(x)}
                        tabIndex={i}
                      >
                        <img
                          height={12}
                          width={20}
                          src={`${cdn}/flags/${x.a2.toLowerCase()}.svg`}
                          alt={`${x.n} flag`}
                        />
                        <span className="name">{isMobile ? x.n : x.a3}</span>
                      </li>
                    ))}
                    <Hr marginBottom={4} marginTop={4} />
                  </Fragment>
                )}
                {optionsToShow.map((x, i) => (
                  <li key={i} className="option" onClick={() => handleOptionClick(x)} tabIndex={i}>
                    <img
                      height={12}
                      width={20}
                      src={`${cdn}/flags/${x.a2.toLowerCase()}.svg`}
                      alt={`${x.n} flag`}
                    />
                    <span className="name">{isMobile ? x.n : x.a3}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )} */}
      </div>
      <style jsx>{`
        .country-field {
          width: 100%;
          position: relative;
          font-weight: 400;

          label {
            position: relative;
            display: flex;
            align-items: center;

            .icon {
              display: inline-block;
              stroke-width: 0;
              stroke: currentColor;
              fill: currentColor;
              filter: ${filter !== null ? 'opacity(0.3)' : 'none'};
            }
          }

          label > .icon {
            position: absolute;
            top: 57%;
            left: 10px;
            transform: translateY(-50%);
            color: silver;
          }

          label > .chevron {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
            color: silver;
          }

          input {
            flex: 1;
            min-width: 0;
            background: white;
            min-height: 36px;
            max-width: 124px;
            padding: 8px 8px 8px 36px;
            border: 1px solid #d4d7dc;
            border-radius: 4px;
            font-weight: 400;
            font-family: AvenirNextforINTUIT-Regular;

            @media screen and (max-width: ${breakpoints.md}) {
              font-size: 14px;
              max-width: 100%;
            }
          }

          input:hover {
            border: 1px solid ${colors.green};
          }

          input:active,
          input:focus {
            outline: none;
            border: 2px solid ${colors.green};
          }

          input[type='search']::-webkit-search-cancel-button {
            -webkit-appearance: none;
            opacity: 0;
            pointer-events: none;
          }

          input::-webkit-contacts-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            position: absolute;
            right: 0;
          }

          .options {
            text-align: left;
            width: 100%;
            position: absolute;
            background: white;
            padding: 8px 0;
            z-index: 1;
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid #d4d7dc;
            border-radius: 4px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            font-family: AvenirNextforINTUIT-Regular;

            @media screen and (max-width: ${breakpoints.md}) {
              max-width: 280px;
              margin: 0 auto;
              left: 0;
              right: 0;
            }

            .option {
              padding: 8px 10px;
              cursor: pointer;
              &:hover {
                background: #e5e5e5;
              }

              .name {
                margin-left: 8px;
              }
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default CountryField;

// @keyframes slidein {
//   from {
//     top: 100%;
//   }

//   to {
//     top: 0;
//   }
// }

// .mobile-options-container {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 999;
//   background: white;
//   text-align: center;
//   animation: 0.3s 1 normal slidein;

//   .mobile-options {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;

//     input {
//       width: 280px;
//       padding: 8px 8px 8px 16px;
//       margin-top: 50px;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 14px;
//       line-height: 20px;
//       border: none;
//       box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
//       font-family: AvenirNextforINTUIT-Regular;
//     }

//     .close-icon {
//       position: absolute;
//       top: 20px;
//       right: 30px;
//     }
//   }
// }
