import React from 'react';
import { FormattedMessage } from 'react-intl';

import ScrollingForm, {
  InjectedScrollingFormProps,
} from 'components/Core/Payment/PaymentForm/ScrollingForm/ScrollingForm';
import Dropdown from 'components/Shared/Dropdown/Dropdown';
import { DropdownOptionType } from 'components/Shared/Dropdown/DropdownOption/DropdownOption';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import { fontSize } from 'styles/cp';

type PayByBankFormProps = { bankList: Array<any> } & InjectedScrollingFormProps;

const PayByBankForm: React.FC<PayByBankFormProps> = ({ bankList }) => {
  const handleSelectedOption = (option: DropdownOptionType) => {
    return option?.id;
  };
  const isMobile = useIsMobileScreen();

  return (
    <React.Fragment>
      <div className="pbb-form">
        <div className="pbb-form-text">
          <div className="pbb-form-header">
            <FormattedMessage id="PBB_HEADER" defaultMessage="Choose a bank account" />
          </div>
          <div className="pbb-form-body">
            <FormattedMessage
              id="PBB_BODY"
              defaultMessage="Bank payments are simple, safe, and secure with QuickBooks."
            />
          </div>
        </div>
        <div className="pbb-dropdown">
          <Dropdown
            placeholder="Select your bank"
            onSelectOption={handleSelectedOption}
            options={bankList}
            isMobile={isMobile}
          />
        </div>
      </div>
      <style jsx>{`
        .pbb-form-text {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .pbb-form-header {
            font-size: ${fontSize.md2};
            font-weight: 600;
            line-height: 28px;
          }

          .pbb-form-body {
            font-size: ${fontSize.sm};
            font-weight: 400;
            line-height: 24px;
          }
        }
        .pbb-dropdown {
          padding-top: 30px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default ScrollingForm(PayByBankForm);
