import React from 'react';

import Select from './Select';

import { fontSize, colors } from 'styles/cp';

interface Label {
  label: string | React.ReactNode;
  onChange?: any; //(e: React.ChangeEventHandler) => void;
  disabled?: boolean;
}

export interface Props extends React.PropsWithChildren<Label> {}

const LabeledSelect: React.FC<Props> = (props) => {
  const { label, children, ...rest } = props;

  return (
    <div>
      <span className="label">{label}</span>

      <Select {...rest}>{children}</Select>

      <style jsx>{`
        .label {
          font-family: AvenirNextforINTUIT-Demi;
          line-height: 20px;
          font-size: ${fontSize.xxs};
          color: ${colors.gray};
          display: block;
        }
      `}</style>
    </div>
  );
};

export default LabeledSelect;
