import cardValidator from 'card-validator';

import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const expDateValidator: WalletValidatorFunction = (expDate: any) => {
  const errors: ValidationError[] = [];

  if (typeof expDate !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  const validation = cardValidator.expirationDate(expDate);

  if (!validation.isValid) {
    errors.push(ValidationErrorEnum.INVALID_EXP_DATE);
  }

  return [errors.length === 0, errors];
};

export const expMonthValidator: WalletValidatorFunction = (expMonth: any) => {
  const errors: ValidationError[] = [];

  let _isValid = true;
  if (typeof expMonth !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
    _isValid = false;
  }

  const validation = cardValidator.expirationMonth(expMonth);

  if (!validation.isValid) {
    errors.push(ValidationErrorEnum.INVALID_EXP_MONTH);
    _isValid = false;
  } else {
    if (!validation.isValidForThisYear) {
      errors.push(ValidationErrorEnum.WARNING_INVALID_MONTH_IN_CURRENT_YEAR);
    }
  }

  return [_isValid, errors];
};

export const expYearValidator: WalletValidatorFunction = (expYear: any) => {
  const errors: ValidationError[] = [];

  let _isValid = true;
  if (typeof expYear !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
    _isValid = false;
  }

  const validation = cardValidator.expirationYear(expYear);

  if (!validation.isValid) {
    errors.push(ValidationErrorEnum.INVALID_EXP_YEAR);
    _isValid = false;
  } else {
    if (validation.isCurrentYear) {
      errors.push(ValidationErrorEnum.WARNING_EXPIRATION_IN_CURRENT_YEAR);
    }
  }

  return [_isValid, errors];
};

export const getMonthAndYearFromExpDate = (expDate: any): { expMonth: string; expYear: string } => {
  const validation = cardValidator.expirationDate(expDate);
  return {
    expMonth: validation.month || '',
    expYear: validation.year || '',
  };
};
