import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedDateNoTimeZone from 'components/Shared/FormattedDateNoTimeZone/FormattedDateNoTimeZone';
import Checkbox from 'components/Shared/Inputs/Checkbox';
import { colors } from 'styles/cp';
type changeEvent =
  | React.ChangeEvent<HTMLInputElement>
  | React.MouseEvent<HTMLDivElement, MouseEvent>;
export interface Props {
  isAutoPayOn: boolean;
  startDate: string | Date;
  interval: string;
  disabled: boolean;
  onChange: (next: changeEvent) => any;
}

const AutopaySetup: React.FC<Props> = ({
  isAutoPayOn,
  interval,
  startDate,
  disabled,
  onChange,
}) => {
  return (
    <Fragment>
      <div className="autopay-container" data-testid="Autopay">
        <div className="checkbox">
          <Checkbox onChange={onChange} checked={isAutoPayOn} disabled={disabled} />
        </div>
        <div className="text-wrapper">
          <div className="body-text">
            <FormattedMessage id="TURN_ON" defaultMessage="Turn on " />
            {interval}
            <FormattedMessage id="AUTOPAY_STARTING" defaultMessage=" autopay starting " />
            <FormattedDateNoTimeZone value={startDate} />
          </div>
          <div className="cancel-info">
            <FormattedMessage
              id="AUTOPAY_CANCEL_INFO"
              defaultMessage="Sign in to cancel autopay at any time."
            />
          </div>
        </div>
      </div>

      <style jsx>{`
        .autopay-container {
          display: flex;
          padding-bottom: 16px;
          .checkbox {
            display: inline-block;
            padding-right: 8px;
            margin-top: 2px;
          }

          .text-wrapper {
            display: flex;
            flex-direction: column;
          }

          .body-text {
            display: inline-block;
            margin: auto;
            font-family: AvenirNextforINTUIT-Regular;
            font-size: 14px;
          }

          .new-label {
            display: inline-block;
            padding-left: 8px;
          }

          .cancel-info {
            font-family: AvenirNextforINTUIT-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: ${colors.gray02};
          }
        }
      `}</style>
    </Fragment>
  );
};

export default AutopaySetup;
