import React from 'react';

export interface Props {
  content: string;
}

const FormSectionHeader: React.FC<Props> = ({ content }) => {
  return (
    <React.Fragment>
      <div className="content">{content}</div>

      {/*language=CSS*/}
      <style jsx>{`
        .content {
          font-family: AvenirNextforINTUIT-Demi;
          text-transform: uppercase;
          font-size: 14px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default FormSectionHeader;
