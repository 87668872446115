import classNames from 'classnames';
import React, { Fragment } from 'react';

import { colors } from 'styles/cp';

export interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  name?: string;
  id?: string;
}

interface propsToPass {
  checked: boolean;
  disabled: boolean;
  name?: string;
  id?: string;
}

const Switch = ({ checked = false, onChange, disabled = false, name, id }: SwitchProps) => {
  let inputProps: propsToPass = {
    checked,
    disabled,
  };
  if (name) {
    inputProps.name = name;
  }
  if (id) {
    inputProps.id = id;
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    onChange();
  };

  const prefixCls = 'cpSwitch';

  const rootClassName = classNames(prefixCls, {
    [`${prefixCls}--disabled`]: disabled,
    [`${prefixCls}--checked`]: checked,
  });

  const handleClick = () => {
    if (disabled) {
      return;
    }
    onChange();
  };

  return (
    <Fragment>
      <div className={rootClassName} onClick={handleClick}>
        <span></span>
      </div>
      <input className="cpSwitch-input" type="checkbox" onChange={onInputChange} {...inputProps} />

      <style jsx>{`
        .cpSwitch-input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 1px;
          width: 1px;
          border: 0;
          margin: -1px;
          overflow: hidden;
          padding: 0;
        }
        .cpSwitch {
          display: block;
          position: relative;
          cursor: pointer;
          width: 36px;
          height: 20px;
          border-radius: 10px;
          background-color: ${colors.gray03};
          text-align: left;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          &:focus:enabled {
            border: solid 3px ${colors.intuit_blue};
          }

          span {
            display: inline-block;
            margin: 1px;
            height: 18px;
            width: 18px;
            border-radius: 9px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            background-color: ${colors.white};
          }
        }
        .cpSwitch--checked {
          background-color: ${colors.green};
          text-align: right;
        }
        .cpSwitch--disabled {
          cursor: default;
          pointer-events: none;
          opacity: 0.4;
        }
      `}</style>
    </Fragment>
  );
};

export default Switch;
