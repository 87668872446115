import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const BankTransfer: React.FC<IconBasePropsWithColor> = ({ width = 22, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.52344 9.0752V17.545H2.31095C2.00121 17.5428 1.70299 17.6602 1.48257 17.8701C1.26287 18.0815 1.13898 18.3672 1.13898 18.6668V21.5168H20.8421V18.6668C20.8421 18.3687 20.7196 18.0837 20.5014 17.873C20.2831 17.6631 19.987 17.545 19.678 17.545H19.4684V9.07592H2.52344V9.0752Z"
        fill="white"
        stroke="#008481"
        strokeWidth="1.645"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.13791 9.06999C0.882913 9.07728 0.653128 8.92348 0.571011 8.69023C0.488894 8.45698 0.573893 8.19968 0.780626 8.05609L10.6462 0.679602C10.7471 0.602338 10.8717 0.560791 11.0006 0.560791C11.1303 0.560791 11.2556 0.603067 11.3579 0.679602L21.212 8.06484C21.4151 8.21062 21.4972 8.46646 21.4158 8.69752C21.3344 8.92931 21.1082 9.08311 20.8547 9.07947L1.13935 9.06926L1.13791 9.06999Z"
        fill="white"
      />
      <path
        d="M11 1.12105L20.8541 8.50628H1.13874L10.9892 1.12105H10.9993H11ZM10.9899 0C10.732 0 10.4814 0.0830948 10.2775 0.236164L0.434259 7.63087C0.0532059 7.92681 -0.0951813 8.421 0.0618498 8.86782C0.219601 9.31464 0.649636 9.61859 1.13802 9.62952H20.8411C21.3338 9.62733 21.7718 9.3263 21.9346 8.87729C22.0974 8.42829 21.9497 7.92972 21.5665 7.63087L11.7146 0.236164C11.5107 0.0830948 11.2586 0 11 0H10.9899Z"
        fill="#008481"
      />
      <g>
        <path d="M4.54016 7.3858L11 2.54443L17.4599 7.3858H4.54016Z" fill="#D9F6F6" />
      </g>
      <path
        d="M16.5345 9.85645V17.5456M5.47174 9.85645V17.5456V9.85645ZM9.5279 9.85645V17.5456V9.85645ZM12.4755 9.85645V17.5456V9.85645Z"
        stroke="#00C1BF"
        strokeWidth="0.822"
      />
      <path
        d="M4.33588 17.5439H17.7887M4.33588 19.3341H17.7887H4.33588Z"
        stroke="#00C1BF"
        strokeWidth="0.822"
        strokeLinecap="round"
      />
      <path
        d="M8.4675 7.47135C8.2903 7.47062 8.12174 7.39118 8.01225 7.25706C7.90276 7.12221 7.86242 6.94582 7.90276 6.7789C8.24852 5.36774 9.55303 4.37061 11.0556 4.37061C12.5575 4.37061 13.8627 5.36774 14.2078 6.7789C14.2488 6.94654 14.2078 7.12294 14.0976 7.25779C13.9874 7.39263 13.8188 7.47135 13.6402 7.47135H8.46822H8.4675Z"
        fill="#57B520"
      />
      <g>
        <path
          d="M19.4812 18.6654H18.3172V10.1948L7.7536 20.3943H19.6916V18.8724C19.693 18.8177 19.6714 18.7645 19.6318 18.7259C19.5922 18.6865 19.5381 18.6647 19.4805 18.6647L19.4812 18.6654Z"
          fill="#D9F6F6"
        />
      </g>
    </svg>
  );
};

export default BankTransfer;
