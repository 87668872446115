import { useState, useEffect, useRef, Ref } from 'react';

export default function useComponentVisible(initialIsVisible: boolean, onClickOutside = () => {}) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref: Ref<any> = useRef(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
