import cardValidator from 'card-validator';

import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction, CardType } from './types';

export type CvcValidatorOptions = {
  cardNumber?: string;
  cardType?: string;
  isAmex?: boolean;
};

export const cvcValidator: WalletValidatorFunction = (cvc: any, opts?: CvcValidatorOptions) => {
  const errors: ValidationError[] = [];

  if (typeof cvc !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  let _isAmex = null;

  if (typeof opts === 'object') {
    const { cardNumber, cardType, isAmex } = opts;

    // Priority of verification
    // 1. isAmex
    // 2. cardType
    // 3. cardNumber

    if (typeof isAmex === 'boolean') {
      _isAmex = isAmex;
    } else if (typeof cardType === 'string') {
      if (cardType === CardType.AMERICAN_EXPRESS) {
        _isAmex = true;
      } else {
        _isAmex = false;
      }
    } else if (typeof cardNumber === 'string') {
      const numberValidation = cardValidator.number(cardNumber);
      if (numberValidation.isPotentiallyValid) {
        if (numberValidation.card && numberValidation.card.type === CardType.AMERICAN_EXPRESS) {
          _isAmex = true;
        } else {
          _isAmex = false;
        }
      }
    }
  }

  const validation = cardValidator.cvv(cvc, _isAmex ? 4 : 3);

  if (!validation.isValid) {
    errors.push(ValidationErrorEnum.INVALID_CVC);
    if (_isAmex !== null) {
      // Additionally, more descriptive error message given Amex or not.
      if (_isAmex) {
        errors.push(ValidationErrorEnum.INVALID_CVC_AMEX);
      } else {
        errors.push(ValidationErrorEnum.INVALID_CVC_NON_AMEX);
      }
    }
  }

  return [errors.length === 0, errors];
};
