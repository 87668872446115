import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Lock from 'components/Shared/Icons/Lock';
import Spinner from 'components/Shared/Spinner/Spinner';
import { fontSize, colors } from 'styles/cp';

export type Props = {};

const SpinnerCentered: React.FC<Props> = () => {
  return (
    <Fragment>
      {/*language=SCSS*/}
      <style jsx>{`
        .spinner-wrapper {
          position: relative;
          width: 100px;
          margin: 30px auto 10px auto;

          .lock {
            position: absolute;
            bottom: 27px;
            left: 37px;
            margin: auto;
          }
        }

        .message-wrapper {
          display: flex;
          justify-content: center;
          margin-bottom: 80px;
          font-size: ${fontSize.sm};
          color: ${colors.gray};
          font-family: AvenirNextforINTUIT-Regular;
        }
      `}</style>
      <div className="spinner-wrapper">
        <Spinner aria-label="Loading..." width={100} strokeWidth={2} bgStrokeWidth={2} />
        <div className="lock">
          <Lock width={25} height={31} strokeWidth={1.2} />
        </div>
      </div>
      <div className="message-wrapper">
        <FormattedMessage
          id="ESTABLISHING_SECURE_CONNECTION"
          defaultMessage="Establishing secure connection"
        />
      </div>
    </Fragment>
  );
};

export default SpinnerCentered;
