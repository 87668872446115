import React from 'react';

const deviceInfo: { isIOSDevice: Function } = require('server/helpers/deviceInfo');

interface IScrollingFormState {
  lastScrollTarget: React.RefObject<HTMLElement> | null;
  isLastScrollUserTriggered: boolean;
}

export interface InjectedScrollingFormProps {
  scrollToRef: (ref: React.RefObject<HTMLElement>, isUserTriggered?: boolean) => void;
}

export default function ScrollingForm<
  P extends InjectedScrollingFormProps = InjectedScrollingFormProps
>(WrappedComp: React.ComponentType<P>) {
  return class extends React.Component<
    Omit<P, keyof InjectedScrollingFormProps>,
    IScrollingFormState
  > {
    constructor(props: Omit<P, keyof InjectedScrollingFormProps>) {
      super(props);

      this.state = {
        lastScrollTarget: null,
        isLastScrollUserTriggered: false,
      };

      this.scrollToRef = this.scrollToRef.bind(this);
    }

    scrollToRef(ref: React.RefObject<HTMLElement>, isUserTriggered: boolean = false) {
      if (
        deviceInfo.isIOSDevice(
          (typeof window === 'object' && window.navigator && window.navigator.userAgent) || ''
        ) &&
        isUserTriggered
      ) {
        return;
      }

      // When do we scroll?
      let willScroll = false;
      // 1. if it was user triggered.
      if (isUserTriggered) {
        willScroll = true;
      }
      // Otherwise if the last scroll was user triggered and this time it wasn't.
      // This is because a form error is telling us we need to focus on an erronous field.
      else {
        willScroll = this.state.isLastScrollUserTriggered;
      }

      if (ref && ref.current !== null && this.state.lastScrollTarget !== ref && willScroll) {
        window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop });
        this.setState({
          lastScrollTarget: ref,
          isLastScrollUserTriggered: isUserTriggered,
        });
      }
    }

    render() {
      return <WrappedComp {...(this.props as P)} scrollToRef={this.scrollToRef} />;
    }
  };
}
