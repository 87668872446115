import { FormikBag, withFormik } from 'formik';

import { cardWalletField } from './CardField';
import { WalletField } from './Field';
import { Errors, Form, genericForm, IWalletFormProps } from './Form';
import CardUtils from './util/card';

import { ICardUpdateData, ParentTypeEnum } from 'components/Core/WalletForms/network/src/types';
import {
  CCDataQuery_node_Payments_WalletCard_card,
  Common_NameValueInput,
} from 'components/Core/WalletForms/queries/transformed/lib/schema';
import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';
import {
  expDateValidator,
  expMonthValidator,
  expYearValidator,
} from 'components/Core/WalletForms/validations/src/expDate';
import { nameValidator } from 'components/Core/WalletForms/validations/src/name';
import { zipCodeValidator } from 'components/Core/WalletForms/validations/src/zipCode';

export type CardDataResponse = CCDataQuery_node_Payments_WalletCard_card;
export interface ICardUpdateFormData extends ICardUpdateData {
  expDate: string;
  zipCode: string;
}

export type InitialCardValues = CardDataResponse & {
  default?: boolean;
};

interface IUpdateCardFormProps extends IWalletFormProps<ICardUpdateFormData, ICardUpdateData> {
  initialValues: InitialCardValues;
  parentId: string;
  parentType: ParentTypeEnum;
  id: string;
}

export const CardField: WalletField<ICardUpdateFormData> = cardWalletField;
export type UpdateCardErrors = Errors<ICardUpdateFormData>;
export type UpdateCardFormikBag = FormikBag<IUpdateCardFormProps, ICardUpdateFormData>;
const _UpdateCardForm: Form<IUpdateCardFormProps, ICardUpdateFormData> = genericForm;
export const UpdateCardForm = withFormik<IUpdateCardFormProps, ICardUpdateFormData>({
  mapPropsToValues: ({ initialValues }: IUpdateCardFormProps) => {
    if (initialValues === null) {
      throw Error('Initial values cannot be null');
    }

    const { expMonth, expYear, address } = initialValues;

    // Convert the expMonth and expYear into expDate
    // e.g.: 08 2019 should be 0819
    let expDate = CardUtils.getExpDateFromExpMonthAndYear(expMonth, expYear);
    let zipCode = CardUtils.getZipCodeFromAddress(address) || '00000';

    const values = {
      name: initialValues.name || '',
      cardType: initialValues.cardType,
      expMonth: initialValues.expMonth,
      expYear: initialValues.expYear,
      expDate,
      zipCode,
      default: initialValues.default,
      __typename: initialValues.__typename,
    };

    return values;
  },

  handleSubmit: async (values: ICardUpdateFormData, formikBag: UpdateCardFormikBag) => {
    // We've been working with zipCode, and expDate,
    // We need to translate them back to what the wallet network needs.

    const oldValues = formikBag.props.initialValues as InitialCardValues;

    const { expMonth, expYear, name: oldName, address } = oldValues;

    const oldZipCode = CardUtils.getZipCodeFromAddress(address);
    const newValues: ICardUpdateData = {};

    if (oldName !== values.name) {
      newValues.name = values.name;
    }

    if (expMonth !== values.expMonth) {
      newValues.expMonth = values.expMonth;
      newValues.expYear = CardUtils.ensure4DigitYear(values.expYear as string);
    }

    if (expYear !== values.expYear) {
      newValues.expYear = CardUtils.ensure4DigitYear(values.expYear as string);
      newValues.expMonth = values.expMonth;
    }

    if (oldValues.default !== values.default) {
      newValues.default = values.default;
    }

    if (oldZipCode !== values.zipCode) {
      if (address === null) {
        newValues.address = {
          addressComponents: [],
        };
      } else if (address.addressComponents === null) {
        newValues.address = {
          ...address,
          addressComponents: [],
        };
      } else {
        const otherAddressFields = address.addressComponents.filter((comp) => {
          // Only filter out postalCode field.
          if (comp === null || comp.name !== 'postalCode') {
            return true;
          }

          return false;
        });

        newValues.address = {
          ...address,
          addressComponents: [...otherAddressFields],
        };
      }

      newValues.address.addressComponents &&
        newValues.address.addressComponents.push({
          name: 'postalCode',
          value: values.zipCode,
        } as Common_NameValueInput);
    }

    formikBag.props.handleSubmit(newValues, formikBag);
  },

  validate: (values: ICardUpdateFormData, _props: IUpdateCardFormProps): UpdateCardErrors => {
    const errors: UpdateCardErrors = {};

    // Expiration Date, Month, Year - complicated.
    // The form may be showing expiration date (expDate).
    // Or it maybe showing expiration month (expMonth) and year (expYear).
    // Determine which one it is by process of elimination
    if (!values.expDate && !values.expMonth && !values.expYear) {
      errors.expDate = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
      errors.expYear = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
      errors.expMonth = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else if (values.expDate) {
      // User is interacting with the expDate field
      const [valid, messages] = expDateValidator(values.expDate);
      if (!valid && messages) {
        errors.expDate = messages;
      }
    } else {
      // User is interacting with the expMonth and expYear fields.
      const [expYearValid, expYearMessages] = expYearValidator(values.expYear);

      if (!expYearValid && expYearMessages) {
        errors.expYear = expYearMessages;
      }

      const [expMonthValid, expMonthMessages] = expMonthValidator(values.expMonth);

      if (!expMonthValid && expMonthMessages) {
        errors.expMonth = expMonthMessages;
      }

      if (
        expYearMessages.indexOf(ValidationErrorEnum.WARNING_EXPIRATION_IN_CURRENT_YEAR) > -1 &&
        expMonthMessages.indexOf(ValidationErrorEnum.WARNING_INVALID_MONTH_IN_CURRENT_YEAR) > -1
      ) {
        errors.expMonth = [ValidationErrorEnum.INVALID_EXP_DATE];
        errors.expYear = [ValidationErrorEnum.INVALID_EXP_DATE];
      }
    }

    if (!values.zipCode) {
      errors.zipCode = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = zipCodeValidator(values.zipCode);
      if (!valid && messages) {
        errors.zipCode = messages;
      }
    }

    if (!values.name) {
      errors.name = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = nameValidator(values.name);
      if (!valid && messages) {
        errors.name = messages;
      }
    }

    return errors;
  },
  //@ts-ignore
})(_UpdateCardForm);
