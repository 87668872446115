import {
  getDayNumber,
  getDayWithOrdinal,
  getMonthName,
  getMonthNumber,
  getOrdinal,
} from 'shared/dates';
import { capitalizeFirstLetter } from 'shared/utils';

const { SplunkReporter } = require('reporting/splunk/SplunkReporter');

const splunkReporter = SplunkReporter.getInstance();
const logger = 'components/subscription/utils';

/**
 * Generates a human-readable string for weekly frequencies.
 * @param frequencyInterval - The interval of the frequency (e.g., 2 for every other week).
 * @param dayInWeek - The day of the week the frequency applies to.
 * @returns A string representing the weekly frequency (e.g., "Every 2 weeks on Monday").
 */
const getWeekly = ({
  frequencyInterval,
  dayInWeek,
}: {
  frequencyInterval: number;
  dayInWeek?: string;
}) => {
  const prefix = frequencyInterval > 1 ? `Every ${frequencyInterval} weeks` : 'Weekly';
  const suffix = dayInWeek ? ` on ${capitalizeFirstLetter(dayInWeek)}` : '';
  return `${prefix}${suffix}`;
};

/**
 * Generates a human-readable string for yearly frequencies.
 * @param monthIndex - The month index (0-11) the frequency applies to.
 * @param dayOfMonth - The day of the month the frequency applies to.
 * @param startDate - The start date of the subscription, used as a fallback if monthIndex and dayOfMonth are not provided.
 * @returns A string representing the yearly frequency (e.g., "Every year on March 15th").
 */
const getYearly = ({
  monthIndex,
  dayOfMonth,
  startDate,
}: {
  monthIndex?: number;
  dayOfMonth?: number;
  startDate?: string;
}) => {
  if (dayOfMonth === 31 && monthIndex) {
    return `Every year on the last day of ${getMonthName(monthIndex)}`;
  }
  if (monthIndex && dayOfMonth) {
    return `Every year on ${getMonthName(monthIndex)} ${dayOfMonth}`;
  }
  if (startDate) {
    return `Every year on ${getMonthName(getMonthNumber(startDate))} ${getDayNumber(startDate)}`;
  }
  return '';
};

/**
 * Generates a human-readable string for monthly frequencies.
 * @param frequencyInterval - The interval of the frequency (e.g., 2 for every other month).
 * @param dayInMonth - The day of the month the frequency applies to.
 * @param startDate - The start date of the subscription, used as a fallback if dayInMonth is not provided.
 * @returns A string representing the monthly frequency (e.g., "Every 2 months on the 15th").
 */
const getMonthly = ({
  frequencyInterval,
  dayInMonth,
  startDate,
}: {
  frequencyInterval: number;
  dayInMonth?: number;
  startDate?: string;
}) => {
  const prefix = frequencyInterval > 1 ? `Every ${frequencyInterval} months` : 'Monthly';
  const day = dayInMonth ?? (startDate ? getDayNumber(startDate) : null);
  const suffix = day ? ` on the ${getDayWithOrdinal(day)}` : '';
  return `${prefix}${suffix}`;
};

/**
 * Generates a human-readable string for relative monthly frequencies (e.g., "Every month on the third Friday").
 * @param frequencyInterval - The interval of the frequency (e.g., 2 for every other month).
 * @param dayInWeek - The day of the week the frequency applies to.
 * @param weekIndex - The week index (1-4) the frequency applies to.
 * @returns A string representing the relative monthly frequency (e.g., "Every 2 months on the third Friday").
 */
const getRelativeMonthly = ({
  frequencyInterval,
  dayInWeek,
  weekIndex,
}: {
  frequencyInterval: number;
  dayInWeek?: string;
  weekIndex?: number;
}) => {
  const prefix = frequencyInterval > 1 ? `Every ${frequencyInterval} months` : 'Monthly';
  const suffix =
    weekIndex && dayInWeek
      ? ` on the ${getOrdinal(weekIndex)} ${capitalizeFirstLetter(dayInWeek)}`
      : '';
  return `${prefix}${suffix}`;
};

/**
 * Generates a human-readable string for a given frequency.
 * @param frequency - The frequency type (e.g., "DAILY", "WEEKLY", "MONTHLY").
 * @param frequencyInterval - The interval of the frequency.
 * @param daysOfWeek - An array of days of the week the frequency applies to.
 * @param monthIndex - The month index the frequency applies to.
 * @param daysOfMonth - An array of days of the month the frequency applies to.
 * @param weekIndex - The week index the frequency applies to.
 * @param startDate - The start date of the subscription.
 * @returns A string representing the frequency (e.g., "Every 2 weeks on Monday", "Every year on March 15th").
 */
const getIntervalSentence = ({
  frequency,
  frequencyInterval,
  daysOfWeek,
  monthIndex,
  daysOfMonth,
  weekIndex,
  startDate,
}: {
  frequency?: string;
  frequencyInterval?: number;
  daysOfWeek?: string[];
  monthIndex?: number;
  daysOfMonth?: number[];
  weekIndex?: number;
  startDate?: string;
}) => {
  if (!frequencyInterval) return '';

  switch (frequency) {
    case 'DAILY':
      return frequencyInterval > 1 ? `Every ${frequencyInterval} days` : 'Daily';
    case 'WEEKLY':
      return getWeekly({ frequencyInterval, dayInWeek: daysOfWeek?.[0] });
    case 'MONTHLY':
      return getMonthly({ frequencyInterval, dayInMonth: daysOfMonth?.[0], startDate });
    case 'RELATIVE_MONTHLY':
      return getRelativeMonthly({ frequencyInterval, dayInWeek: daysOfWeek?.[0], weekIndex });
    case 'YEARLY':
      return getYearly({ monthIndex, dayOfMonth: daysOfMonth?.[0], startDate });
    default:
      return '';
  }
};

/**
 * Determines the appropriate message ID for a given frequency to be used with react-intl.
 * @param frequency - The frequency type (e.g., "DAILY", "WEEKLY", "MONTHLY").
 * @param frequencyInterval - The interval of the frequency.
 * @param daysOfMonth - An array of days of the month the frequency applies to.
 * @param daysOfWeek - An array of days of the week the frequency applies to.
 * @returns The message ID corresponding to the frequency (e.g., "DAILY_INTERVAL", "EVERY_WEEK_INTERVAL").
 */
const getFormattedMessageId = ({
  frequency,
  frequencyInterval,
  daysOfMonth,
  daysOfWeek,
}: {
  frequency?: string;
  frequencyInterval?: number;
  daysOfMonth?: number[];
  daysOfWeek?: string[];
}) => {
  if (!frequencyInterval) return '';

  switch (frequency) {
    case 'DAILY':
      return frequencyInterval > 1 ? 'DAILY_INTERVAL' : 'DAILY';
    case 'WEEKLY':
      return daysOfWeek?.length
        ? frequencyInterval > 1
          ? 'WEEKLY_INTERVAL'
          : 'EVERY_WEEK_INTERVAL'
        : frequencyInterval > 1
        ? 'WEEKLY_INTERVAL_IMMEDIATELY'
        : 'WEEKLY';
    case 'MONTHLY':
      return frequencyInterval > 1 ? 'MONTHLY_INTERVAL' : 'EVERY_MONTH_INTERVAL';
    case 'RELATIVE_MONTHLY':
      return daysOfWeek?.length
        ? frequencyInterval > 1
          ? 'RELATIVE_MONTHLY_INTERVAL'
          : 'RELATIVE_EVERY_MONTH_INTERVAL'
        : frequencyInterval > 1
        ? 'MONTHLY_INTERVAL_IMMEDIATELY'
        : 'MONTHLY';
    case 'YEARLY':
      return daysOfMonth?.[0] === 31 ? 'EVERY_YEAR_LAST_DAY_OF_MONTH' : 'EVERY_YEAR_INTERVAL';
    default:
      return '';
  }
};

const getFormattedAmountWithoutTax = (
  amount: number,
  currency: string,
  taxAmount: number | null
) => {
  try {
    if (isNaN(amount) || amount < 0) {
      throw new Error('Invalid amount. Amount must be a non-negative number.');
    }

    if (typeof currency !== 'string' || currency.trim() === '') {
      throw new Error('Invalid currency. Currency must be a non-empty string.');
    }

    if (typeof taxAmount === 'number' && (isNaN(taxAmount) || taxAmount < 0)) {
      throw new Error('Invalid tax amount. Tax amount must be a non-negative number or null.');
    }
    return taxAmount !== null ? amount - taxAmount : amount;
  } catch (error) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'getFormattedAmountWithoutTax',
      error: {
        message: error,
      },
    });
    return 0;
  }
};

export {
  getWeekly,
  getMonthly,
  getIntervalSentence,
  getFormattedMessageId,
  getFormattedAmountWithoutTax,
};
