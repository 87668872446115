import React from 'react';

import Switch, { SwitchProps } from './Switch';

import { colors, fontSize } from 'styles/cp';

export interface Props {
  checked: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
  label: string;
  hint?: string;
  onChange: () => void;
}

const LabeledSwitch: React.FC<Props> = ({ checked, disabled, id, name, onChange, label, hint }) => {
  let propsToPass: SwitchProps = {
    checked,
    disabled,
    onChange,
  };
  if (id) {
    propsToPass.id = id;
  }
  if (name) {
    propsToPass.name = name;
  }

  return (
    <div className="switch-root">
      <div className="switch-row">
        <Switch {...propsToPass} />
        <span className="switch-label">{label}</span>
      </div>
      {!!hint && <div className="switch-hint">{hint}</div>}

      <style jsx>{`
        .switch-root {
          height: 45px;
        }
        .switch-row {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
        }
        .switch-label {
          margin-left: 10px;
          font-size: ${fontSize.xs};
          color: ${colors.gray02};
          font-family: AvenirNextforINTUIT-Regular;
        }
        .switch-hint {
          color: ${colors.green};
          font-size: ${fontSize.xxs};
          font-family: AvenirNextforINTUIT-Regular;
        }
      `}</style>
    </div>
  );
};

export default LabeledSwitch;
