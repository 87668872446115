import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const accountNumberValidator: WalletValidatorFunction = (accountNumber: any) => {
  const errors: ValidationError[] = [];

  if (typeof accountNumber !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    const accountNumberRegEx = /^\d{4,17}$/;
    const maskedValueRegEx = /^[x]*\d{4}$/;
    const result = accountNumberRegEx.test(accountNumber) || maskedValueRegEx.test(accountNumber);
    if (!result) {
      errors.push(ValidationErrorEnum.INVALID_ACCOUNT_NUMBER);
    }
  }

  return [errors.length === 0, errors];
};
