import React from 'react';

import { colors } from 'styles/cp';

export interface Props {
  width?: number;
  height?: number;
  color?: string;
  fontSize?: number;
  radius?: number;
  label?: string;
}

const NewLabel: React.FC<Props> = ({
  width = 38,
  height = 16,
  color = '#c9007a',
  fontSize = 12,
  radius = 4,
  label = 'NEW',
}) => {
  return (
    <div className="new-alert" aria-label="New alert">
      {label}
      <style jsx>{`
        .new-alert {
          background-color: ${color};
          border-radius: ${radius}px;
          height: ${height}px;
          width: ${width}px;
          color: ${colors.white};
          text-align: center;
          font-size: ${fontSize}px;
        }
      `}</style>
    </div>
  );
};

export default NewLabel;
