import cn from 'classnames';
import React, { Fragment } from 'react';

import PayButton from 'components/Core/Payment/Buttons/PayButton/PayButton';
import PaymentDisclaimer from 'components/Shared/PaymentDisclaimer/PaymentDisclaimer';
import SubscriptionConsent from 'components/Subscription/Consent/SubscriptionConsent';
import { IxpExperiment } from 'reporting/SegmentIO';
import { colors, breakpoints } from 'styles/cp';
import { Auth } from 'types/Auth';
import { Payment } from 'types/Payment';
import { Wallet } from 'types/Wallet';
interface Props {
  currency: string;
  config: Record<string, any>;
  wallet: Pick<Wallet, 'fetchWalletStatus'>;
  auth: Pick<Auth, 'isUserSignedIn'>;
  ixp: { [key: string]: IxpExperiment };
  payment: Pick<
    Payment,
    | 'paymentError'
    | 'payPalProcessor'
    | 'paymentMethodType'
    | 'paymentStatus'
    | 'amount'
    | 'isAmountValid'
    | 'isDateScheduled'
    | 'isAutoPayOn'
    | 'isSubscriptionUpdatePaymentMethodRequired'
    | 'isPayPalCommerceInvoice'
  >;
  featureFlags: Record<string, any>;
  onPayButtonClicked: () => void;
  recurringInfo: Record<string, any>;
  dueDate: string;
  payMessageType?: any;
  isSubscription: boolean;
  subscriptionInfo?: Record<string, any>;
  isPPCheckoutExperiment: boolean;
  className?: string;
}

const PayButtonWithConsent: React.FC<Props> = ({
  auth,
  config,
  ixp,
  currency,
  dueDate,
  featureFlags,
  onPayButtonClicked,
  payMessageType,
  payment,
  recurringInfo,
  wallet,
  isSubscription,
  subscriptionInfo,
  isPPCheckoutExperiment,
  className,
}) => {
  const { paymentMethodType } = payment;

  return (
    <Fragment>
      <div
        className={cn(className, 'w', {
          paypalcheckout: isPPCheckoutExperiment,
        })}
      >
        {!isSubscription ? (
          <PaymentDisclaimer payment={payment as any} currency={currency} dueDate={dueDate} />
        ) : (
          <SubscriptionConsent featureFlags={featureFlags} />
        )}
        <PayButton
          // @ts-ignore
          currency={currency}
          config={config}
          wallet={wallet}
          auth={auth}
          payment={payment}
          ixp={ixp}
          featureFlags={featureFlags}
          onPayButtonClicked={onPayButtonClicked}
          recurringInfo={recurringInfo}
          payMessageType={payMessageType}
          isSubscription={isSubscription}
          subscriptionInfo={subscriptionInfo}
        />
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .w {
          :global(.main-footer) {
            display: none;
          }
          @media screen and (max-width: ${breakpoints.md}) {
            position: ${paymentMethodType ? 'fixed' : 'inherit'};
            padding: 20px;
            z-index: 1098;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
          }
          @media screen and (max-width: ${breakpoints.md}) {
            position: fixed;
          }
          .ApplePay {
            margin-bottom: 12px;
            .or-payment {
              display: flex;
              color: ${colors.gray02};
              justify-content: space-around;
              align-items: center;
              margin: 12px 0;
            }
          }
        }

        .paypalcheckout {
          margin-top: 20px;
          @media screen and (max-width: ${breakpoints.md}) {
            position: inherit;
            padding: 0px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default PayButtonWithConsent;
