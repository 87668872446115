import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import type { FUNDING_SOURCE, PayPalButtonsComponentOptions } from '@paypal/paypal-js';

import { concatPaymentMethodWithSubType } from 'businessLogic/Payment/helpers';
import SegmentIO from 'reporting/SegmentIO';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'sections/PaypalPayBtn';
import { isOneToManyPaymentRequest } from 'shared/utils';
import {
  createPaypalOrder,
  confirmPaypalOrder,
  onFailedPayment,
  paymentMethodTypeChange,
  paymentActions,
} from 'store/payment/slice';
import { breakpoints, colors } from 'styles/cp';
import { Sale } from 'types/Sale';
import { PaymentMethodSubType } from 'types/constants';

export interface Props {
  paymentMethodType: string;
  paymentMethodSubType?: PaymentMethodSubType;
  expectedPaymentMethodType: string;
  createPaypalOrder: Function;
  confirmPaypalOrder: Function;
  onFailedPayment: Function;
  paymentMethodTypeChange: Function;
  className?: string;
  isIconOnlyButton?: boolean;
  style?: PayPalButtonsComponentOptions['style'];
  isMobileFixedPositionDisabled?: boolean;
  isAmountValid: boolean;
  isContactDetailsNameValid?: boolean;
  isContactDetailsEmailValid?: boolean;
  contactDetailsName?: string;
  contactDetailsEmail?: string;
  sale?: Sale;
  isPPCheckoutExperiment: boolean;
  setPaypalExpressCheckoutPaymentMethod: (...args: any[]) => any;
}

const PayPalCustomMessageDict = {
  paypal_ppaam_paylater: {
    id: 'PAYPAL_PAY_LATER_INFO_MESSAGE',
    defaultMessage:
      'Pay in installments with PayPal (Available for personal PayPal account holders). <link1>Learn more</link1>"',
    values: {
      link1: (chunks: string) => (
        <a
          style={{ color: '#007ec8', textDecoration: 'none' }}
          href={'https://www.paypal.com/us/digital-wallet/ways-to-pay/buy-now-pay-later'}
          target="_blank"
          rel="noreferrer"
        >
          {chunks}
        </a>
      ),
    },
  },
} as Record<string, { id: string; defaultMessage: string; values: {} | undefined }>;

const PaypalPayBtn: React.FC<Props> = ({
  createPaypalOrder,
  confirmPaypalOrder,
  onFailedPayment,
  paymentMethodType,
  expectedPaymentMethodType = paymentMethodType,
  paymentMethodTypeChange,
  className,
  isIconOnlyButton,
  style = {},
  isMobileFixedPositionDisabled = false,
  paymentMethodSubType,
  isAmountValid,
  isContactDetailsNameValid,
  isContactDetailsEmailValid,
  contactDetailsName,
  contactDetailsEmail,
  sale,
  isPPCheckoutExperiment,
  setPaypalExpressCheckoutPaymentMethod,
}) => {
  const containerRef = useRef<HTMLInputElement>();

  // Paypal button is disabled when email & name fields are empty - payment request
  const isBtnClickable =
    isContactDetailsNameValid &&
    isContactDetailsEmailValid &&
    typeof contactDetailsName !== 'undefined' &&
    typeof contactDetailsEmail !== 'undefined';

  const isOneToManyReqAndDisabled =
    !isBtnClickable && isOneToManyPaymentRequest(sale?.type, sale?.subType);

  const [paypalCustomMessage, setPaypalCustomMessage] = useState<{
    id: string;
    defaultMessage: string;
    values: {} | undefined;
  }>();
  useEffect(() => {
    setPaypalExpressCheckoutPaymentMethod('');
    paymentMethodSubType = isPPCheckoutExperiment ? undefined : paymentMethodSubType;
    if (containerRef.current && containerRef.current.children.length > 0) {
      // Paypal button is already rendered, delete the prev button (to fix issues caused when rendering paypal-paylater vs paypal)
      containerRef.current.removeChild(containerRef.current.children[0]);
    }
    if (expectedPaymentMethodType === 'paypal_ppaam' || expectedPaymentMethodType === 'venmo') {
      const color = isPPCheckoutExperiment
        ? expectedPaymentMethodType === 'venmo'
          ? 'blue'
          : 'gold'
        : 'white';
      const method = expectedPaymentMethodType === 'venmo' ? 'Venmo' : 'PayPal';
      let PayPalButton;

      if (window && window.paypal && window.paypal.Buttons) {
        const fullPaymentMethodName = concatPaymentMethodWithSubType(
          expectedPaymentMethodType,
          paymentMethodSubType
        ) as string;
        setPaypalCustomMessage(PayPalCustomMessageDict[fullPaymentMethodName]);
        const fundingSource = {
          venmo: 'venmo',
          paypal_ppaam: 'paypal',
          paypal_ppaam_paylater: 'paylater',
        }[fullPaymentMethodName] as FUNDING_SOURCE;

        PayPalButton = window.paypal.Buttons({
          fundingSource,
          style: {
            layout: 'vertical',
            color,
            shape: 'rect',
            label: isIconOnlyButton ? undefined : isPPCheckoutExperiment ? 'paypal' : 'pay',
            height: isPPCheckoutExperiment ? 48 : undefined,
            ...style,
          },
          onInit: (_, actions) => {
            if (isOneToManyReqAndDisabled || !isAmountValid) {
              actions.disable();
            } else {
              actions.enable();
            }
          },
          createOrder: async () => {
            if (paymentMethodType !== expectedPaymentMethodType && !isPPCheckoutExperiment) {
              await paymentMethodTypeChange({ paymentMethodType: expectedPaymentMethodType });
            }
            if (isPPCheckoutExperiment) {
              setPaypalExpressCheckoutPaymentMethod(expectedPaymentMethodType);
            }
            SegmentIO.transactionSubmitted({
              payment_method: expectedPaymentMethodType,
            });
            return await createPaypalOrder(method);
          },
          // @ts-ignore
          onApprove: async (data: { paymentSource: string }) => {
            const { paymentSource } = data;
            await confirmPaypalOrder(paymentSource);
          },
          onError: (e: any) => {
            const { message, stack } = e || {};
            splunkReporter.contextual({
              logInfo: { logLevel: 'error', logger },
              event: 'pay',
              action: 'createOrder',
              activityInfo: {
                status: 'error',
                paymentMethodType: method,
                paymentMethodSubType: paymentMethodSubType,
              },
              error: {
                message,
                stack,
              },
            });
            SegmentIO.transactionFailed({
              ui_object_detail:
                typeof message === 'string'
                  ? message.replace(' ', '_')
                  : `error_inside_${fullPaymentMethodName}_flow`,
              ui_object: 'modal',
              ui_action: 'loaded',
              activity_type: `pm_${fullPaymentMethodName}`,
              payment_method: expectedPaymentMethodType,
            });
            // We don't want to show error in case the pop up was closed
            if (message !== 'Detected popup close') {
              const error = { data: { message: 'PAYPAL_CREATE_ORDER' } };
              return onFailedPayment(error, {});
            }
          },
        });
        // @ts-ignore
        PayPalButton.render(containerRef.current).catch((e: any) => {
          const knownErrors = ['Detected container element removed from DOM', 'Component closed'];
          if (!knownErrors.includes(e.message)) {
            splunkReporter.contextual({
              logInfo: { logLevel: 'error', logger },
              event: 'pay',
              // @ts-ignore
              action: 'renderPaypalButton',
              activityInfo: {
                status: 'error',
                paymentMethodType: method,
                paymentMethodSubType: paymentMethodSubType,
              },
              error: {
                message: e.message,
                stack: e.stack,
              },
            });
          }
        });
      }
    }
  });

  return (
    <>
      <div className="paypal-custom-message">
        {paypalCustomMessage && (
          <FormattedMessage
            id={paypalCustomMessage.id}
            defaultMessage={paypalCustomMessage.defaultMessage}
            values={{ ...paypalCustomMessage.values }}
          />
        )}
      </div>
      <div
        // @ts-ignore
        ref={containerRef}
        data-testid="paypal-container"
        className={cn(className, 'paypal-button-container', {
          'paypal-button-mobile-fixed': !isMobileFixedPositionDisabled,
          'paypal-button-disabled': !isAmountValid,
          paypalcheckout: isPPCheckoutExperiment,
        })}
      />
      {/*language=SCSS*/}
      <style jsx>{`
        .paypal-button-container {
          margin: 24px 0;
        }
        .paypal-button-disabled {
          position: relative;
          :before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: white;
            opacity: 0.5;
            z-index: 400;
          }
        }
        .paypal-custom-message {
          color: #393a3d;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 200% */
        }
        .paypal-button-mobile-fixed {
          @media screen and (max-width: ${breakpoints.md}) {
            margin: 0;
            z-index: 1098;
            position: fixed;
            padding: 20px 25px 40px 25px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${colors.white};
          }
        }
        .paypalcheckout {
          margin: 0px;
          max-height: 48px;
          @media screen and (max-width: ${breakpoints.md}) {
            position: relative;
            padding: 0px;
          }
        }
      `}</style>
    </>
  );
};

export default connect(
  ({
    payment: {
      paymentMethodType,
      paymentMethodSubType,
      isContactDetailsNameValid,
      isContactDetailsEmailValid,
      contactDetailsName,
      contactDetailsEmail,
      isAmountValid,
    },
    sale,
  }) => {
    return {
      paymentMethodType,
      paymentMethodSubType,
      isContactDetailsNameValid,
      isContactDetailsEmailValid,
      contactDetailsName,
      contactDetailsEmail,
      sale,
      isAmountValid,
    };
  },
  {
    createPaypalOrder,
    confirmPaypalOrder,
    onFailedPayment,
    paymentMethodTypeChange,
    setPaypalExpressCheckoutPaymentMethod: paymentActions.setPaypalExpressCheckoutPaymentMethod,
  },
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  })
)(PaypalPayBtn);
