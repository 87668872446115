import { FormikBag, withFormik } from 'formik';

import { genericWalletField, WalletField } from './Field';
import { Errors, Form, genericForm, IWalletFormProps } from './Form';

import {
  BankAccountTypeEnumInput,
  IBankAccountUpdateData,
  ParentTypeEnum,
} from 'components/Core/WalletForms/network/src/types';
import {
  BankDataQuery_node_Payments_WalletBankAccount_bankAccount,
  Payments_Definitions_Payments_BankAccountTypeInput as BankAccountTypeInput,
} from 'components/Core/WalletForms/queries/transformed/lib/schema';
import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';
import { accountTypeValidator } from 'components/Core/WalletForms/validations/src/accountType';
import { nameValidator } from 'components/Core/WalletForms/validations/src/name';
import { phoneValidator } from 'components/Core/WalletForms/validations/src/phone';

export type InitialBankValues = BankDataQuery_node_Payments_WalletBankAccount_bankAccount & {
  default?: boolean;
};

interface IUpdateBankFormProps extends IWalletFormProps<IBankAccountUpdateData> {
  initialValues: InitialBankValues;
  parentId: string;
  parentType: ParentTypeEnum;
  id: string;
}

export const BankField: WalletField<IBankAccountUpdateData> = genericWalletField;
export type UpdateBankErrors = Errors<IBankAccountUpdateData>;
export type UpdateBankFormikBag = FormikBag<IUpdateBankFormProps, IBankAccountUpdateData>;
const _UpdateBankForm: Form<IUpdateBankFormProps, IBankAccountUpdateData> = genericForm;
export const UpdateBankForm = withFormik<IUpdateBankFormProps, IBankAccountUpdateData>({
  mapPropsToValues: ({ initialValues }: IUpdateBankFormProps) => {
    if (initialValues === null) {
      throw Error('Initial values cannot be null');
    }

    const values = {
      bankCode: initialValues.bankCode || '',
      accountNumber: initialValues.accountNumber || '',
      accountType: initialValues.accountType
        ? BankAccountTypeEnumInput[initialValues.accountType]
        : BankAccountTypeEnumInput.PERSONAL_CHECKING,
      name: initialValues.name || '',
      phone: initialValues.phone || '',
      default: initialValues.default || false,
    };

    return values;
  },

  handleSubmit: async (
    values: BankAccountTypeInput,
    formikBag: FormikBag<IUpdateBankFormProps, IBankAccountUpdateData>
  ) => {
    if (!values.phone) {
      values.phone = '0000000000';
    }
    formikBag.props.handleSubmit(values, formikBag);
  },

  validate: (values: IBankAccountUpdateData, _props: IUpdateBankFormProps): UpdateBankErrors => {
    const errors: UpdateBankErrors = {};
    if (!values.accountType) {
      errors.accountType = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = accountTypeValidator(values.accountType);
      if (!valid && messages) {
        errors.accountType = messages;
      }
    }

    if (!values.name) {
      errors.name = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = nameValidator(values.name, values.accountType);
      if (!valid && messages) {
        errors.name = messages;
      }
    }

    if (values.phone) {
      const [valid, messages] = phoneValidator(values.phone);
      if (!valid && messages) {
        errors.phone = messages;
      }
    }

    return errors;
  },
})(_UpdateBankForm);
