const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'shared/dates';

/**
 * Formats a date string in the format YYYY-MM-DD to MM/DD/YY, removing leading zeros from month and day.
 * @param date The date string in YYYY-MM-DD format.
 * @returns The formatted date string in MM/DD/YY format, or null if the input is invalid.
 */
const formatDateMMDDYYWithoutZeros = (timestamp: string) => {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const formattedDate = date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
      .replace(/0(\d)/g, '$1');

    const shortYear = date.getFullYear() % 100;

    return `${formattedDate.slice(0, -4)}/${shortYear}`;
  } catch (error) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'formatDateMMDDYYWithoutZeros',
      error: {
        message: error,
      },
    });
    return '';
  }
};

/**
 * Returns the name of the month corresponding to the given number.
 * @param number The month number (1-12).
 * @returns The month name, or an error message if the input is invalid.
 */
const getMonthName = (number: number) => {
  switch (number) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return 'Invalid input. Please enter a number between 1 and 12.';
  }
};

/**
 * Returns the ordinal representation (e.g., "first", "second") of a number between 1 and 5.
 * @param number The number to convert to an ordinal.
 * @returns The ordinal representation, or an error message if the input is invalid.
 */
const getOrdinal = (number: number) => {
  switch (number) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'last';
    default:
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'viewSale',
        action: 'getOrdinal',
        error: {
          message: `invalid number: ${number}`,
        },
      });
      return '';
  }
};

/**
 * Returns the day of the month with its corresponding ordinal suffix (e.g., "1st", "2nd", "3rd", "4th").
 * @param number The day of the month (1-31).
 * @returns The day with ordinal suffix, or an empty string if the input is invalid.
 */
const getDayWithOrdinal = (number: number) => {
  try {
    if (number < 1 || number > 31) {
      return '';
    }

    const ordinals: string[] = [
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
      'sixth',
      'seventh',
      'eighth',
      'ninth',
    ];

    if (number <= 9) {
      return ordinals[number - 1];
    } else if (number === 31) {
      return 'last';
    } else {
      const suffix =
        number % 10 === 1 && number !== 11
          ? 'st'
          : number % 10 === 2 && number !== 12
          ? 'nd'
          : number % 10 === 3 && number !== 13
          ? 'rd'
          : 'th';
      return number + suffix;
    }
  } catch (error) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'getDayWithOrdinal',
      error: {
        message: error,
      },
    });
    return '';
  }
};

const getMonthNumber = (dateStr: string) => {
  const [, month] = dateStr.split('-').map(Number);
  if (isNaN(month)) return 0;
  return month;
};

const getDayNumber = (dateStr: string) => {
  const [, , day] = dateStr.split('-').map(Number);
  if (isNaN(day)) return 0;
  return day;
};

export {
  formatDateMMDDYYWithoutZeros,
  getOrdinal,
  getDayWithOrdinal,
  getMonthName,
  getMonthNumber,
  getDayNumber,
};
