import React from 'react';
import { useIntl } from 'react-intl';

import Spinner from 'components/Shared/Spinner/Spinner';
import { colors } from 'styles/cp';
import { PaymentMethod } from 'types/constants';

export type Props = {
  isIntuitThemeButton: boolean;
  paymentMethodType: PaymentMethod;
};

const PaySpinner: React.FC<Props> = ({ isIntuitThemeButton, paymentMethodType }) => {
  const intl = useIntl();
  let spinnerColor = colors.green;
  let spinnerBGColor = colors.gray08;

  switch (paymentMethodType) {
    case 'pp':
      spinnerColor = colors.green;
      spinnerBGColor = colors.gray08;
      break;
    case 'ap':
      spinnerColor = colors.ghostGray;
      spinnerBGColor = colors.gray07;
      break;
  }
  return (
    <Spinner
      aria-label={intl.formatMessage({
        id: 'SUBMITTING_PAYMENT',
        defaultMessage: 'Submitting payment',
      })}
      color={isIntuitThemeButton ? colors.blue : spinnerColor}
      bgStrokeWidth={2.5}
      width={25}
      strokeWidth={5}
      bgColor={spinnerBGColor}
    />
  );
};

export default PaySpinner;
