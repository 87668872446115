import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const DebitCard: React.FC<IconBasePropsWithColor> = ({ width = 40, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.15131 24.8948C1.68233 24.9213 1.28019 24.5676 1.25 24.1028V1.97023C1.28019 1.50435 1.68125 1.1507 2.15131 1.17718H34.508C34.9759 1.1507 35.3791 1.50541 35.4082 1.96918V23.9927C35.3759 24.5348 34.908 24.9478 34.3625 24.916L2.15131 24.8948Z"
        fill="white"
        stroke="#008481"
        strokeWidth="1.556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g>
        <path d="M33.9141 2.87109V23.2005H13.4189L33.9141 2.87109Z" fill="#D9F6F6" />
      </g>
      <path d="M5.51953 5.37012H31.1379V9.14376H5.51953V5.37012Z" fill="#00C1BF" />
      <path
        d="M12.2448 17.5086C12.2448 18.7623 11.2206 19.7788 9.95593 19.7788L8.17595 19.694C7.33393 19.73 6.53936 19.3055 6.10811 18.5876C5.67686 17.8697 5.67686 16.975 6.10811 16.2571C6.53936 15.5392 7.33393 15.1136 8.17595 15.1496"
        stroke="#53B700"
        strokeWidth="0.792"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        d="M9.86621 17.4238C9.86514 16.8213 10.1056 16.2432 10.5347 15.8165C10.9648 15.3898 11.5481 15.1494 12.1551 15.1494L13.9394 15.2384C15.204 15.2384 16.2282 16.2548 16.2282 17.5085C16.2282 18.7621 15.204 19.7786 13.9394 19.7786"
        stroke="#53B700"
        strokeWidth="0.792"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        d="M35.4082 32.4422C40.1247 32.4422 43.9481 28.6497 43.9481 23.9716C43.9481 19.2934 40.1247 15.501 35.4082 15.501C30.6918 15.501 26.8684 19.2934 26.8684 23.9716C26.8684 28.6497 30.6918 32.4422 35.4082 32.4422Z"
        fill="#53B700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1304 21.6336V20.4996C40.1304 20.398 40.07 20.3059 39.9773 20.2625L35.515 18.3142C35.447 18.2846 35.3694 18.2846 35.3015 18.3142L30.8445 20.2625C30.7475 20.3038 30.686 20.3969 30.686 20.4996V21.6347C30.686 21.7776 30.8025 21.8931 30.9469 21.8931H39.8738C40.0172 21.8909 40.1304 21.7766 40.1304 21.6347V21.6336Z"
        stroke="white"
        strokeWidth="1.188"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.715 22.0234V25.6107M34.106 22.0234V25.6107V22.0234Z"
        stroke="white"
        strokeWidth="0.792"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7361 22.0234V27.7284M39.0846 22.0234V27.7284V22.0234Z"
        stroke="white"
        strokeWidth="1.188"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1853 27.7285H39.6313"
        stroke="white"
        strokeWidth="1.584"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DebitCard;
