import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { BankAccountTypeEnumInput } from 'components/Core/WalletForms/validations/src/types';
import { accountTypeTranslator } from 'store/wallet/helpers';
import { breakpoints, colors, fontSize } from 'styles/cp';
import CpPopover from 'components/Shared/Popover/CpPopover';
import PopoverMenuWrapper from 'components/Shared/PopoverMenuWrapper/PopoverMenuWrapper';
import { DotsMenu, getPaymentMethodBug } from 'components/Shared/Icons/Icons';
import Radio from 'components/Shared/Inputs/Radio';
import CvvInputControl from 'components/Shared/Inputs/CvvInputControl';
import SegmentIO from 'reporting/SegmentIO';
import { isWalletTypeOfBank } from 'businessLogic/Wallet/helpers';

class PaymentItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      hovered: false,
    };

    const methodsToBind = [
      'editPaymentMethod',
      'deletePaymentMethod',
      'handleMenuIconClick',
      'handleMouseEnter',
      'handleMouseLeave',
      'hideMenu',
      'showMenu',
      'updateDimensions',
    ];
    methodsToBind.forEach((methodName) => (this[methodName] = this[methodName].bind(this)));
  }

  updateDimensions() {
    this.forceUpdate();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  editPaymentMethod() {
    const { id, walletType } = this.props.item;
    this.props.onUpdatePaymentMethodClicked(id, walletType);
    this.hideMenu();
  }

  deletePaymentMethod() {
    this.props.onDeletePaymentMethodClicked(this.props.item.id, this.props.itemType);
    this.hideMenu();
  }

  handleMenuIconClick() {
    if (this.state.isMenuOpen) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  handleMouseEnter() {
    this.setState({ hovered: true });
  }

  handleMouseLeave() {
    this.setState({ hovered: false });
  }

  hideMenu() {
    this.setState({
      isMenuOpen: false,
    });
    window.removeEventListener('click', this.hideMenu);
  }

  showMenu() {
    SegmentIO.transactionEngaged({
      activity_type: 'wallet',
      ui_action: 'clicked',
      ui_object: 'dropdown',
      ui_object_detail: 'expand_dropdown',
      ui_access_point: 'transaction_flow',
    });
    this.setState({
      isMenuOpen: true,
    });
    setTimeout(() => window.addEventListener('click', this.hideMenu), 0);
  }

  render() {
    const { intl } = this.props;
    const { item, checked, onClick, itemType, cdn } = this.props;
    const { id, accountNumber, cardNumber, cardType } = item;

    const { isMenuOpen, hovered } = this.state;

    const getPaymentMethodIcon = () => {
      const paymentType = isWalletTypeOfBank(itemType) ? itemType : cardType;
      return getPaymentMethodBug(paymentType, cdn, this.props.intl.formatMessage);
    };

    const getFormattedPaymentMethodNumber = () => {
      if (accountNumber) {
        return `...${accountNumber.slice(-4)}`;
      } else if (cardNumber) {
        return `...${cardNumber.slice(-4)}`;
      } else {
        return `...****`;
      }
    };

    const PaymentItemMenu = () => {
      return (
        <div className="payment-item-menu">
          <PopoverMenuWrapper>
            <ul>
              <li onClick={this.editPaymentMethod} data-cy="payment-item-target-edit">
                <FormattedMessage id="PAYFLOW_EDIT" defaultMessage="Edit" />
              </li>
              <li onClick={this.deletePaymentMethod} data-cy="payment-item-target-delete">
                <FormattedMessage id="PAYFLOW_DELETE" defaultMessage="Delete" />
              </li>
            </ul>
          </PopoverMenuWrapper>
        </div>
      );
    };

    const getPaymentMethodLabel = () => {
      if (itemType === 'bank') {
        const { accountType } = item;

        let accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_PERSONAL_CHECKING';
        switch (accountTypeTranslator.fromCpServerToWallet(accountType)) {
          case BankAccountTypeEnumInput.PERSONAL_SAVINGS:
            accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_PERSONAL_SAVINGS';
            break;

          case BankAccountTypeEnumInput.BUSINESS_CHECKING:
            accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_BUSINESS_CHECKING';
            break;

          case BankAccountTypeEnumInput.BUSINESS_SAVINGS:
            accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_BUSINESS_SAVINGS';
            break;

          default:
            break;
        }
        return <FormattedMessage id={accountTypeLabelId} />;
      } else if (cardType) {
        // Credit card types do not need to be translated
        return cardType.charAt(0).toUpperCase() + cardType.substring(1);
      }

      // Defensively return an empty string if no type is found
      else return '';
    };

    return (
      <Fragment>
        <div
          className="payment-item"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          data-testid="payment-item"
        >
          <div
            className="payment-item-radio"
            data-testid="payment-item-radio"
            tabIndex="0"
            onFocus={this.handleMouseEnter}
            onBlur={this.handleMouseLeave}
            onClick={() => onClick(id)}
            onKeyPress={() => onClick(id)}
          >
            <Radio
              value={id}
              checked={checked}
              hovered={hovered}
              ariaLabel={intl.formatMessage({
                id: 'RADIO',
                defaultMessage: 'Radio',
              })}
            />
          </div>
          <div className="payment-item-middle-col" onClick={() => onClick(id)}>
            <span className="payment-icon-content">
              <span className="payment-icon">{getPaymentMethodIcon()}</span>
              <span
                className={'payment-item-content-item method-name' + (checked ? ' checked' : '')}
              >
                {getPaymentMethodLabel()}
                &nbsp;
              </span>
              <span className={'payment-item-content-item number' + (checked ? ' checked' : '')}>
                {getFormattedPaymentMethodNumber()}
              </span>
            </span>
            <span className="payment-cvv">
              {itemType === 'card' && checked && (
                <span className="cvv-on">
                  <CvvInputControl
                    cardType={item.cardType}
                    createPayment={this.props.createPayment}
                    bindFormSubmission={this.props.bindFormSubmission}
                    onCvvInputValueChange={this.props.onCvvInputValueChange}
                    isPaymentInProgress={this.props.isPaymentInProgress}
                    cvvInputValue={this.props.selectedCardCvv}
                  />
                </span>
              )}
            </span>
          </div>
          <div className="menu-wrapper" onClick={this.handleMenuIconClick}>
            <label
              id={`payment-item-menu-${id}`}
              className={'menu-icon-wrapper ' + (checked ? ' checked' : '')}
            >
              <DotsMenu />
            </label>
            <CpPopover
              className="cp-menu-wrapper"
              innerClassName="cp-menu"
              placement="bottom-end"
              isOpen={isMenuOpen}
              target={`payment-item-menu-${id}`}
            >
              <PaymentItemMenu />
            </CpPopover>
          </div>
        </div>

        <style jsx>{`
          :global(.payment-item-menu li) {
            &:hover {
              font-family: AvenirNextforINTUIT-Demi;
            }
          }

          .payment-item {
            cursor: pointer;
            border-bottom: 1px solid ${colors.gray05};
            align-items: flex-start;
            min-height: 51px;
            display: flex;
            justify-content: space-between;
          }

          .payment-item-radio {
            padding-top: 11px;
            min-height: 50px;
            &:focus {
              outline: none;
            }
          }

          .payment-item-middle-col {
            width: 100%;
            justify-content: space-between;
            display: flex;
            flex-wrap: wrap;
            .payment-icon-content {
              display: flex;
              padding-top: 15px;
            }
          }

          .payment-icon {
            min-width: 37px;
            margin-left: 20px;

            @media screen and (max-width: ${breakpoints.md}) {
              margin-left: 10px;
            }
          }

          .payment-item-content-item {
            margin-left: 8px;
            font-size: ${fontSize.xs};
            color: ${colors.darkGray};
            white-space: nowrap;

            &.method-name {
              @media screen and (max-width: 370px) {
                max-width: 72px;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            &.number {
              margin-left: 0;
              overflow: auto;
            }

            &.checked {
              font-family: AvenirNextforINTUIT-Medium;
            }
          }

          .payment-cvv {
            display: flex;
            .cvv-on {
              padding: 7px 0px;
              min-width: 170px;
            }
          }

          .menu-wrapper {
            cursor: pointer;
            padding-top: 13px;

            .menu-icon-wrapper {
              cursor: pointer;
              width: 100%;
              text-align: center;
              display: inline-block;
              opacity: 0.5;
              padding: 0 24px;

              &.checked {
                opacity: 1;
              }
            }

            &:hover {
              :global(svg g) {
                fill: ${colors.lightGray};
              }
            }
          }
        `}</style>
      </Fragment>
    );
  }
}

const _requiredIfCard = (_type) => (props, propName, componentName) => {
  if (props.itemType === 'card') {
    if (_type === 'array' && !Array.isArray(props[propName]) && typeof props[propName] !== _type) {
      throw Error(
        `Expected prop ${propName} of type ${_type} in component: ${componentName}, but received ${typeof props[
          propName
        ]}`
      );
    }
  }
};

PaymentItem.propTypes = {
  cdn: PropTypes.string.isRequired,
  itemType: PropTypes.oneOf(['bank', 'card']).isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDeletePaymentMethodClicked: PropTypes.func.isRequired,
  onUpdatePaymentMethodClicked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  createPayment: _requiredIfCard('function'),
  bindFormSubmission: _requiredIfCard('function'),
  isPaymentInProgress: _requiredIfCard('boolean'),
  onCvvInputValueChange: _requiredIfCard('function'),
  selectedCardCvv: _requiredIfCard('string'),
};

export default injectIntl(PaymentItem);
