import React, { Fragment } from 'react';

import Radio from 'components/Shared/Inputs/Radio';
import { colors } from 'styles/cp';

export interface Props {
  label: string | React.ReactNode;
  onClick: () => void;
}

export const RadioItem: React.FC<Props> = ({ label, onClick }) => {
  return (
    <Fragment>
      <div className="payment-item" onClick={onClick}>
        <div className="payment-item-radio" tabIndex={0}>
          <Radio checked={false} />
        </div>
        {label}
      </div>
      <style jsx>{`
        .payment-item {
          cursor: pointer;
          border-bottom: 1px solid ${colors.gray05};
          display: flex;
          align-items: center;
          min-height: 51px;
          font-family: AvenirNextforINTUIT-Regular;
          font-size: 14px;
        }

        .payment-item-radio {
          padding-top: 11px;
          padding-right: 12px;
          min-height: 50px;
        }
      `}</style>
    </Fragment>
  );
};

export default RadioItem;
