import { Field, FieldProps } from 'formik';
import React from 'react';

import { EnhancedFormikProps, Errors } from './Form';

export type EnhancedFieldProps<T> = FieldProps<T> & {
  form: EnhancedFormikProps<T>;
};

export interface IWalletFormFieldProps<T> {
  name: keyof T;
  children: (props: EnhancedFieldProps<T>) => React.ReactNode;
}

export type WalletField<T> = React.FunctionComponent<IWalletFormFieldProps<T>>;

export const genericWalletField: WalletField<any> = <T extends object>({
  name,
  children,
}: IWalletFormFieldProps<T>) => (
  <Field name={name}>
    {(fieldProps: FieldProps<T>) => {
      const { field, form } = fieldProps;
      const enumErrors = form.errors as any as Errors<T>;
      const newProps = {
        field,
        form: {
          ...form,
          enumErrors,
        },
      };
      return children(newProps);
    }}
  </Field>
);
