import React from 'react';

import Input from './Input';

import { fontSize, colors } from 'styles/cp';

export interface Props {
  label: string | React.ReactNode;
  className?: string;
  [propName: string]: any;
}

const LabeledInput: React.FC<Props> = (props: any) => {
  const { className, label, ...rest } = props;

  return (
    <div className={className}>
      <style jsx>{`
        label {
          font-family: AvenirNextforINTUIT-Demi;
          line-height: 20px;
          font-size: ${fontSize.xxs};
          color: ${colors.darkGray};
          display: block;
        }
      `}</style>
      <label>
        {label}
        <Input {...rest} />
      </label>
    </div>
  );
};

export default LabeledInput;
