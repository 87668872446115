import React from 'react';
import { FormattedMessage } from 'react-intl';

import { colors, fontSize } from 'styles/cp';

export interface Props {
  signIn: () => void;
  signUp: () => void;
}

const SavePaymentMethodGuest: React.FC<Props> = ({ signIn, signUp }) => {
  const onSignInClick = (e: any) => {
    e.preventDefault();
    signIn();
  };

  const onSignUpClick = (e: any) => {
    e.preventDefault();
    signUp();
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <style jsx>{`
        .cp-link {
          color: ${colors.blue};
          text-decoration: none;
        }

        .cp-link-wrapper {
          font-size: ${fontSize.xxs};
          color: ${colors.darkGray};
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      `}</style>
      <span className="cp-link-wrapper">
        <FormattedMessage
          id="SAVE_PM_DESCRIPTION"
          defaultMessage="Save a payment method for faster future payments."
        />
        <span>
          <a href="#" className="cp-link" onClick={onSignInClick}>
            <FormattedMessage id="SIGN_IN_CAPITAL" defaultMessage="Sign in" />
          </a>
          <a href="#" className="cp-link" onClick={onSignUpClick} style={{ paddingLeft: '4px' }}>
            <FormattedMessage id="OR_CREATE_ACCOUNT" defaultMessage="or create account" />
          </a>
        </span>
      </span>
    </div>
  );
};

export default SavePaymentMethodGuest;
