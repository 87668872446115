import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import ActionModal from 'components/Shared/ActionModal/ActionModal';
import EftInfoModalIcon from 'components/Shared/Icons/EftInfoModalIcon';

export interface Props {
  hide: () => void;
  onContinue: () => void;
}

const EftInfoModal: React.FC<Props> = ({ hide, onContinue }) => {
  return (
    <Fragment>
      <ActionModal
        hide={hide}
        withHeader={false}
        withCancelBtn={false}
        onContinue={onContinue}
        continueIntl={{
          id: 'EFT_INFO_MODAL_CONTINUE_BTN',
          defaultMessage: 'Got it',
        }}
        hideOnContinueFinish={false}
        removeDefaultBackground={true}
      >
        <div className="modal-info-body">
          <div className="modal-info-svg">
            <EftInfoModalIcon />
          </div>
          <div className="modal-info-text">
            <div className="modal-title-text">
              <FormattedMessage
                id="EFT_INFO_MODAL_HEADER"
                defaultMessage="Find transit, institution, and account numbers"
              />
            </div>
            <div className="modal-body-text">
              <FormattedMessage
                id="EFT_INFO_MODAL_BODY"
                defaultMessage="All physical cheques have these three numbers. If you don’t have physical cheques, you can find this info in most banking apps."
              />
            </div>
          </div>
        </div>
      </ActionModal>
      <style jsx>{`
        .modal-info-svg {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 16px;
        }
        .modal-info-text {
          padding-left: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .modal-title-text {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
        }
        .modal-body-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      `}</style>
    </Fragment>
  );
};

export default EftInfoModal;
