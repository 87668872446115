import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import SavePaymentMethodGuest from 'components/Core/Payment/PaymentMethod/SavePaymentMethod/SavePaymentMethodGuest/SavePaymentMethodGuest';
import LabeledCheckbox from 'components/Shared/Inputs/LabeledCheckbox';
import CpPopover from 'components/Shared/Popover/CpPopover';
import SegmentIO from 'reporting/SegmentIO';
import { signIn, signUp } from 'store/auth/slice';
import { setIsSavePaymentMethodChecked } from 'store/payment/slice';
import { colors, fontSize } from 'styles/cp';

export interface Props {
  isUserSignedIn?: boolean;
  payment: Record<string, any>;
  setIsSavePaymentMethodChecked: (isSavePaymentMethodChecked: boolean) => void;
  signIn: () => void;
  signUp: () => void;
}

const SavePaymentMethodCTA: React.FC<Props> = ({
  isUserSignedIn = false,
  payment,
  setIsSavePaymentMethodChecked,
  signIn,
  signUp,
}) => {
  const { isDateScheduled, isAutoPayOn, isSavePaymentMethodChecked } = payment;
  const isScheduled = isDateScheduled || isAutoPayOn;

  const sharedStyle = { fontSize: fontSize.xxs, color: colors.darkGray };
  if (isUserSignedIn) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const onChange = () => {
      const isChecked = !isSavePaymentMethodChecked;
      setIsSavePaymentMethodChecked(isChecked);
      SegmentIO.clickSavePaymentMethod(isChecked);
    };

    if (isScheduled && !isSavePaymentMethodChecked) {
      setIsSavePaymentMethodChecked(true);
    }

    return (
      <div
        onTouchStart={() => isScheduled && setIsTooltipOpen(true)}
        onMouseEnter={() => isScheduled && setIsTooltipOpen(true)}
        onMouseLeave={() => isScheduled && setIsTooltipOpen(false)}
      >
        <CpPopover
          className="cp-tooltip-wrapper"
          innerClassName="cp-tooltip"
          placement="top-start"
          isOpen={isTooltipOpen}
          target="cta-description"
        >
          <FormattedMessage
            id="SCHEDULE_SAVE_PAYMENT_METHOD_DISABLE_DESCRIPTION"
            defaultMessage="We need to store your payment method in order to schedule the payment for a future date."
          />
        </CpPopover>
        <LabeledCheckbox
          id="cta"
          checked={isSavePaymentMethodChecked}
          disabled={isScheduled}
          onChange={onChange}
          label={
            <span style={sharedStyle} id="cta-description">
              <FormattedMessage
                id="PAYFLOW_SAVE_PAYMENT_METHOD_LABEL"
                defaultMessage="Save payment method for next time"
              />
            </span>
          }
        />
      </div>
    );
  }
  return <SavePaymentMethodGuest signUp={signUp} signIn={signIn} />;
};

function mapStateToProps(store: any) {
  const { payment, auth } = store;
  return {
    isUserSignedIn: auth.isUserSignedIn,
    payment,
  };
}

export default connect(mapStateToProps, {
  setIsSavePaymentMethodChecked,
  signIn,
  signUp,
  // @ts-ignore
})(SavePaymentMethodCTA);

export { SavePaymentMethodCTA };
