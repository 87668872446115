import debugCreator from 'debug';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import { isWalletTypeOfCreditCard } from 'businessLogic/Wallet/helpers';
import CardForm from 'components/Core/Payment/PaymentForm/Card/CardForm';
import PaymentItem from 'components/Core/Payment/PaymentItem/PaymentItem';
import Radio from 'components/Shared/Inputs/Radio';
import FormDraftProvider from 'contexts/FormDraftProvider';
import SegmentIO from 'reporting/SegmentIO';
import { getDefaultWalletFromList } from 'store/wallet/helpers';
import { REMOTE_BUTTON_ACCESS_FUNCTIONALITY } from 'store/wallet/slice';
import { colors, fontSize } from 'styles/cp';
import { Auth } from 'types/Auth';
import { Config } from 'types/Config';
import { FeatureFlags } from 'types/FeatureFlags';
import { Payment } from 'types/Payment';
import { Wallet } from 'types/Wallet';
import { TXN_MAP } from 'types/constants';

const debug = debugCreator('CardPayment');

const WALLET_WIDGET_NAME_ADAPTER = {
  amex: 'american-express',
  diners: 'diners-club',
  masterCard: 'mastercard',
};

type CardPaymentProps = {
  auth: Pick<Auth, 'isUserSignedIn' | 'countryCode'>;
  payment: Pick<Payment, 'paymentMethodType' | 'paymentStatus' | 'country'>;
  wallet: Pick<
    Wallet,
    | 'userWallets'
    | 'enabledCCTypes'
    | 'selectedWalletId'
    | 'cardCreatePaymentTrigger'
    | 'selectedCardCvv'
  >;
  config: Pick<Config, 'endpoints'>;
  ixp?: object;
  createPayment: (...args: any[]) => any;
  stopPayment: (...args: any[]) => any;
  bindFormSubmission: (...args: any[]) => any;
  changeSelectedCard: (...args: any[]) => any;
  onDeletePaymentMethodClicked: (...args: any[]) => any;
  onUpdatePaymentMethodClicked: (...args: any[]) => any;
  onCvvInputValueChange: (...args: any[]) => any;
  updateCountry: (...args: any[]) => any;
  featureFlags: FeatureFlags;
  companyLocale?: string;
  intl: IntlShape;
};

type CardPaymentState = {
  isAddCardHovered: boolean;
};

class CardPayment extends Component<CardPaymentProps, CardPaymentState> {
  constructor(props: CardPaymentProps) {
    super(props);
    debug('constructor');
    this.state = {
      isAddCardHovered: false,
    };
    const methodsToBind = ['handleAddCardMouseEnter', 'handleAddCardMouseLeave'];
    // @ts-ignore
    methodsToBind.forEach((methodName) => (this[methodName] = this[methodName].bind(this)));
  }
  componentDidMount() {
    const { changeSelectedCard, wallet } = this.props;
    const { userWallets = [] } = wallet;
    const cardAccounts =
      userWallets && userWallets.filter((wallet) => isWalletTypeOfCreditCard(wallet.walletType));
    if (cardAccounts && Array.isArray(cardAccounts) && cardAccounts.length > 0) {
      const defaultCard = getDefaultWalletFromList(cardAccounts);
      changeSelectedCard(defaultCard);
    } else {
      changeSelectedCard({
        id: 'AddCard',
        walletType: 'card',
      });
    }
  }
  handleAddCardMouseEnter() {
    this.setState({ isAddCardHovered: true });
  }
  handleAddCardMouseLeave() {
    this.setState({ isAddCardHovered: false });
  }
  getAllowedCardTypes() {
    const { enabledCCTypes } = this.props.wallet;
    return enabledCCTypes.map(
      // @ts-ignore
      (allowedCardType) => WALLET_WIDGET_NAME_ADAPTER[allowedCardType] || allowedCardType
    );
  }
  render() {
    debug('render');
    const { isAddCardHovered } = this.state;
    const {
      auth,
      wallet,
      payment,
      config,
      changeSelectedCard,
      bindFormSubmission,
      createPayment,
      stopPayment,
      ixp,
      featureFlags,
      onDeletePaymentMethodClicked,
      onUpdatePaymentMethodClicked,
      onCvvInputValueChange,
      companyLocale,
      intl,
    } = this.props;
    const {
      userWallets = [],
      selectedWalletId = '',
      [REMOTE_BUTTON_ACCESS_FUNCTIONALITY.CARD_CREATE_PAYMENT_TRIGGER]: boundCreateCardFn,
    } = wallet;
    const creditCards =
      userWallets && userWallets.filter((wallet) => isWalletTypeOfCreditCard(wallet.walletType));
    const id = 'ADD_CARD';
    const text = intl.formatMessage({
      id,
      defaultMessage: 'Add card',
    });
    const _cardFormProps = {
      isUserSignedIn: auth.isUserSignedIn,
      paymentMethodType: payment.paymentMethodType,
      paymentStatus: payment.paymentStatus,
      createPayment,
      stopPayment,
      boundCreateCardFn,
      config,
      ixp,
      bindFormSubmission,
      endpoints: config.endpoints,
      companyLocale,
      enabledCCTypes: wallet.enabledCCTypes,
    };
    const addCard = {
      id: 'AddCard',
      walletType: 'card',
    };
    const showCardList = creditCards && Array.isArray(creditCards) && creditCards.length > 0;
    const showCardForm = !showCardList || selectedWalletId === addCard.id;
    const onAddCardClicked = () => {
      SegmentIO.transactionEngaged({
        activity_type: 'wallet',
        ui_action: 'enabled',
        ui_object: 'radio_button',
        ui_object_detail: 'add_card',
        ui_access_point: 'transaction_flow',
      });
      changeSelectedCard(addCard);
    };
    // @ts-ignore
    const onChangeSelectedCard = (item) => {
      SegmentIO.transactionEngaged({
        activity_type: 'wallet',
        ui_action: 'enabled',
        ui_object: 'radio_button',
        ui_object_detail: 'saved_wallet_selected',
        ui_access_point: 'transaction_flow',
      });
      changeSelectedCard(item, 'card');
    };
    return (
      <Fragment>
        {showCardList && (
          <Fragment>
            <span className="payment-items-header">
              <FormattedMessage
                id="PAYFORM_SELECT_PAYMENT_METHOD"
                defaultMessage="Select payment method"
              />
            </span>
            <div className="payment-items-container">
              {creditCards.map((item) => (
                <PaymentItem
                  key={item.id}
                  itemType="card"
                  item={item}
                  cdn={config.endpoints.cdn}
                  onClick={() => onChangeSelectedCard(item)}
                  onDeletePaymentMethodClicked={() => onDeletePaymentMethodClicked(item.id, 'card')}
                  onUpdatePaymentMethodClicked={() => onUpdatePaymentMethodClicked(item.id, 'card')}
                  checked={item.id === selectedWalletId}
                  createPayment={createPayment}
                  bindFormSubmission={bindFormSubmission}
                  isPaymentInProgress={payment.paymentStatus === TXN_MAP.STATUS.IN_PROGRESS}
                  onCvvInputValueChange={onCvvInputValueChange}
                  selectedCardCvv={wallet.selectedCardCvv}
                />
              ))}
              <div
                className="payment-item-add"
                onClick={onAddCardClicked}
                onMouseEnter={this.handleAddCardMouseEnter}
                onMouseLeave={this.handleAddCardMouseLeave}
              >
                <div
                  className="payment-item-radio"
                  tabIndex={0}
                  onClick={() => changeSelectedCard(addCard)}
                  onKeyPress={() => changeSelectedCard(addCard)}
                  onFocus={this.handleAddCardMouseEnter}
                  onBlur={this.handleAddCardMouseLeave}
                >
                  <Radio
                    checked={addCard.id === selectedWalletId}
                    value={addCard.id}
                    hovered={isAddCardHovered}
                    ariaLabel={text}
                  />
                </div>
                <div>
                  <span className="add-card">
                    <FormattedMessage
                      id="PAYFORM_ADD_PAYMENT_METHOD_CARD"
                      defaultMessage="Add card"
                    />
                  </span>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {showCardForm && (
          <FormDraftProvider.Consumer>
            {(ctx) => (
              <CardForm
                // @ts-ignore
                cardDraft={[payment.paymentMethodType]}
                // @ts-ignore
                updateFormDraft={(draft) => ctx?.updateDraft(payment.paymentMethodType, draft)}
                allowedCardTypes={this.getAllowedCardTypes()}
                // @ts-ignore
                ixp={ixp}
                featureFlags={featureFlags}
                countryCode={auth.countryCode}
                country={payment.country}
                updateCountry={this.props.updateCountry}
                {..._cardFormProps}
              />
            )}
          </FormDraftProvider.Consumer>
        )}

        <style jsx>{`
          .payment-items-header {
            display: block;
            font-size: ${fontSize.xxs};
            color: ${colors.lightGray};
            height: 25px;
            vertical-align: middle;
            font-family: AvenirNextforINTUIT-Medium;
          }

          .payment-items-container {
            width: 100%;
            margin-bottom: 16px;
          }

          .payment-item-add {
            height: 51px;
            cursor: pointer;
            display: flex;
            align-items: center;
            .payment-item-radio {
              &:focus {
                outline: none;
              }
            }
          }

          .add-card {
            font-size: ${fontSize.xs};
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: ${colors.darkGray};
            margin-left: 20px;
          }
        `}</style>
      </Fragment>
    );
  }
}

export default injectIntl(CardPayment);
