// @ts-nocheck

import debugCreator from 'debug';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import UpdateBase from 'components/Core/Payment/UpdateBase/UpdateBase';
import { BankField, UpdateBankForm } from 'components/Core/WalletForms/src/UpdateBankForm';
import { BankAccountTypeEnumInput } from 'components/Core/WalletForms/validations/src/types';
import { PaymentMethodBugs } from 'components/Shared/Icons/Icons';
import LabeledSelect from 'components/Shared/Inputs/LabeledSelect';
import LabeledSwitch from 'components/Shared/Inputs/LabeledSwitch';
import ValidatedLabeledInput from 'components/Shared/Inputs/ValidatedLabeledInput';
import MessageCard from 'components/Shared/MessageCard/MessageCard';
import { formErrorTranslator } from 'store/wallet/errorTranslators';
import { accountTypeTranslator } from 'store/wallet/helpers';
import { updateWallet, updateWalletCancel } from 'store/wallet/slice';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { Config } from 'types/Config';
import { UserWallet, Wallet } from 'types/Wallet';
import { TXN_MAP } from 'types/constants';

const debug = debugCreator('CPV2:BankUpdate');

type Props = {
  updateWallet: Function;
  cancelUpdateWallet: () => void;
  currentlyUpdatingWalletId: string;
  cdn: string;
  updateWalletStatus: string;
  intl: IntlShape;
};

type formValuesProps = {
  accountType: BankAccountTypeEnumInput;
  name: string;
  default: boolean;
};

export class BankUpdate extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    debug('constructor');
    const methodsToBind = ['handleFormSubmit', 'handleFormCancel'];
    methodsToBind.forEach((methodName) => (this[methodName] = this[methodName].bind(this)));
    this.errorTranslator = formErrorTranslator();
  }
  getAccountInWalletSchema() {
    const bankAccount = this.getBankAccount();
    return {
      id: bankAccount.id,
      bankCode: bankAccount.bankCode,
      accountNumber: bankAccount.accountNumber,
      accountType: accountTypeTranslator.fromCpServerToWallet(bankAccount.accountType),
      phone: bankAccount.phone,
      name: `${bankAccount.payorFirstName}${
        bankAccount.payorLastName ? ' ' + bankAccount.payorLastName : ''
      }`,
      default: bankAccount.default,
    };
  }
  getAccountValuesInCpServerSchema(formValues: formValuesProps) {
    const initialValues = this.getBankAccount();
    return {
      ...initialValues,
      accountType: accountTypeTranslator.fromWalletToCpServer(formValues.accountType),
      payorFirstName: formValues.name,
      payorLastName: '',
      default: formValues.default,
    };
  }
  handleFormSubmit(
    values: formValuesProps,
    walletFormikBag: {
      setSubmitting: Function;
    }
  ) {
    const { setSubmitting } = walletFormikBag;
    setSubmitting(true);
    const bankDetails = this.getAccountValuesInCpServerSchema(values);
    this.props.updateWallet({
      bankDetails,
    });
  }
  handleFormCancel() {
    this.props.cancelUpdateWallet();
  }
  getBankAccount() {
    return this.props.userWallets.find(
      (bank: UserWallet) => bank.id === this.props.currentlyUpdatingWalletId
    );
  }
  render() {
    const { cdn, updateWalletStatus, intl } = this.props;
    const bankAccount = this.getBankAccount();
    let accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_PERSONAL_CHECKING';
    switch (accountTypeTranslator.fromCpServerToWallet(bankAccount.accountType)) {
      case BankAccountTypeEnumInput.PERSONAL_SAVINGS:
        accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_PERSONAL_SAVINGS';
        break;
      case BankAccountTypeEnumInput.BUSINESS_CHECKING:
        accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_BUSINESS_CHECKING';
        break;
      case BankAccountTypeEnumInput.BUSINESS_SAVINGS:
        accountTypeLabelId = 'PAYFLOW_ACCOUNT_TYPE_BUSINESS_SAVINGS';
        break;
      default:
        break;
    }
    const { accountNumber } = bankAccount;
    const formattedAccountNumber = `...${accountNumber.slice(-4)}`;

    const initialValues = this.getAccountInWalletSchema();
    return (
      <div className="bank-update">
        <div className="update-cont">
          {
            // Warning is the design we use to show to the user
            updateWalletStatus === TXN_MAP.STATUS.ERROR && (
              <MessageCard status={'warn'} text="WALLET_UPDATE_ERROR" />
            )
          }
          {/* @ts-ignore */}
          <UpdateBankForm
            handleSubmit={this.handleFormSubmit}
            initialValues={initialValues}
            id={this.props.currentlyUpdatingWalletId}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({
              validateForm,
              isValidating,
              isSubmitting,
              setSubmitting,
              setFieldError,
              enumErrors,
              touched,
              values,
              setFieldValue,
              setFieldTouched,
            }) => {
              // Tell Redux to stop that BankUpdateForm found errors and thus stopped the payment in progress.
              if (
                isSubmitting &&
                (updateWalletStatus === TXN_MAP.STATUS.ERROR ||
                  (!isValidating && Object.keys(enumErrors).length > 0))
              ) {
                setSubmitting(false);
              }
              const onBankFieldFocus = (_field: { name: string; onFocus?: Function }) => {
                return (e: React.FocusEvent<HTMLInputElement>) => {
                  // For tests this is necessary
                  if (!e.target.name) {
                    e.target.name = _field.name;
                  }
                  _field.onFocus && _field.onFocus(e);
                };
              };
              const onBankFieldChange = (_field: { name: string; onChange: Function }) => {
                return (e: React.ChangeEvent<HTMLInputElement>) => {
                  // For tests this is necessary
                  if (!e.target.name) {
                    e.target.name = _field.name;
                  }
                  // Respect the rest of the event propagation
                  _field.onChange && _field.onChange(e);
                  // Only clear the errors from the field if the value has changed.
                  if (values[_field.name] !== e.target.value) {
                    setFieldError(_field.name, null);
                  }
                  if (_field.name === 'accountType') {
                    setTimeout(() => validateForm(), 0);
                  }
                };
              };
              const onBankFieldBlur = (_field: { name: string; onBlur: Function }) => {
                return (e: React.FocusEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  // For tests this is necessary
                  if (!e.target.name) {
                    e.target.name = _field.name;
                  }
                  _field.onBlur && _field.onBlur(e);
                  // If the value is empty then consider this field untouched.
                  setFieldTouched(_field.name, value !== null && value !== '');
                };
              };
              return (
                <UpdateBase
                  headerId="SETTINGS_EDIT_PAYMENT_METHOD"
                  isSubmitting={isSubmitting}
                  handleFormCancel={this.handleFormCancel}
                >
                  <div className="r routing">
                    <label>
                      <FormattedMessage
                        id="PAYFLOW_BANK_ROUTING_NUMBER"
                        defaultMessage="Routing number"
                      />
                    </label>
                    <div className="r-number">
                      <span>{bankAccount.bankCode}</span>
                    </div>
                  </div>

                  <div className="r account">
                    <label>
                      <FormattedMessage
                        id="PAYFLOW_BANK_ACCOUNT_NUMBER"
                        defaultMessage="Account number"
                      />
                    </label>
                    <div className="a-number">
                      <span className="payment-icon">{PaymentMethodBugs.Bank({ cdn })}</span>
                      <span className="bank-edit-data">
                        {<FormattedMessage id={accountTypeLabelId} />}
                      </span>
                      <span className="bank-edit-data">{formattedAccountNumber}</span>
                    </div>
                  </div>

                  <div className="r">
                    <BankField name="name">
                      {({ field }) => (
                        <div className="bank-field">
                          <ValidatedLabeledInput
                            {...field}
                            translator={this.errorTranslator}
                            disabled={isSubmitting}
                            onBlur={onBankFieldBlur(field)}
                            onChange={onBankFieldChange(field)}
                            onFocus={onBankFieldFocus(field)}
                            maxLength="64"
                            label={
                              <FormattedMessage
                                id="PAYFLOW_BANK_ACCOUNT_NAME"
                                defaultMessage="Account holder's name"
                              />
                            }
                            validationError={
                              touched[field.name] &&
                              enumErrors[field.name] &&
                              enumErrors[field.name][0]
                            }
                          />
                        </div>
                      )}
                    </BankField>
                  </div>

                  <div className="r">
                    <BankField name="accountType">
                      {({ field }) => (
                        <div className="bank-field">
                          <LabeledSelect
                            {...field}
                            onChange={onBankFieldChange(field)}
                            disabled={isSubmitting}
                            label={
                              <FormattedMessage
                                id="PAYFLOW_BANK_ACCOUNT_TYPE"
                                defaultMessage="Account type"
                              />
                            }
                          >
                            <FormattedMessage
                              id="PAYFLOW_ACCOUNT_TYPE_PERSONAL_CHECKING"
                              defaultMessage="Personal checking"
                            >
                              {(message) => (
                                <option value={BankAccountTypeEnumInput.PERSONAL_CHECKING}>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="PAYFLOW_ACCOUNT_TYPE_PERSONAL_SAVINGS"
                              defaultMessage="Personal savings"
                            >
                              {(message) => (
                                <option value={BankAccountTypeEnumInput.PERSONAL_SAVINGS}>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="PAYFLOW_ACCOUNT_TYPE_BUSINESS_CHECKING"
                              defaultMessage="Business Checking"
                            >
                              {(message) => (
                                <option value={BankAccountTypeEnumInput.BUSINESS_CHECKING}>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="PAYFLOW_ACCOUNT_TYPE_BUSINESS_SAVINGS"
                              defaultMessage="Business savings"
                            >
                              {(message) => (
                                <option value={BankAccountTypeEnumInput.BUSINESS_SAVINGS}>
                                  {message}
                                </option>
                              )}
                            </FormattedMessage>
                          </LabeledSelect>
                        </div>
                      )}
                    </BankField>
                  </div>

                  <BankField name="default">
                    {({ field }) => (
                      <LabeledSwitch
                        {...field}
                        checked={values.default}
                        disabled={isSubmitting}
                        label={intl.formatMessage({
                          id: 'PAYFLOW_SAVE_AS_DEFAULT',
                          defaultMessage: 'Save as default',
                        })}
                        hint={
                          values.default &&
                          intl.formatMessage({
                            id: 'PAYFORM_DEFAULT_PAYMENT_METHOD',
                            defaultMessage: 'This is your default payment method',
                          })
                        }
                        onChange={() => setFieldValue('default', !values.default)}
                      />
                    )}
                  </BankField>
                </UpdateBase>
              );
            }}
          </UpdateBankForm>
        </div>

        <style jsx>{`
          .bank-update {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-direction: column;
          }

          .update-cont {
            border-radius: 4px;
            max-width: 100%;
            width: 410px;
            background-color: ${colors.white};
            padding: 30px;

            @media screen and (max-width: ${breakpoints.md}) {
              width: 330px;
              padding: 15px;
            }

            .r {
              margin-bottom: 15px;

              &.routing .r-number,
              &.account .a-number {
                span {
                  font-family: AvenirNextforINTUIT-Regular;
                  color: ${colors.gray01};
                  font-size: ${fontSize.xs};
                }
              }

              :global(label),
              label {
                font-size: ${fontSize.xs};
                margin-bottom: 5px;
                font-family: AvenirNextforINTUIT-Demi;
              }

              .a-number {
                .payment-icon {
                  margin-right: 3px;

                  :global(img) {
                    display: block;
                  }
                }

                span {
                  font-size: ${fontSize.xs};
                  color: ${colors.gray};
                  font-family: AvenirNextforINTUIT-Medium;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        `}</style>
      </div>
    );
  }
}

const mapStateToProps = ({
  wallet,
  config,
}: {
  wallet: Pick<
    Wallet,
    'userWallets' | 'currentlyUpdatingWalletId' | 'updateWalletStatus' | 'walletError'
  >;
  config: Pick<Config, 'endpoints'>;
}) => {
  const { userWallets, currentlyUpdatingWalletId, updateWalletStatus, walletError } = wallet;
  return {
    userWallets,
    currentlyUpdatingWalletId,
    updateWalletStatus,
    walletError,
    cdn: config.endpoints.cdn,
  };
};

export default connect(mapStateToProps, {
  updateWallet,
  cancelUpdateWallet: updateWalletCancel,
})(injectIntl(BankUpdate));
