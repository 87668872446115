import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

const bankCodeRegEx: RegExp = /^\d{9}$/;

export const routingNumberValidator: WalletValidatorFunction = (routingNumber: any) => {
  const errors: ValidationError[] = [];
  if (typeof routingNumber !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    if (!bankCodeRegEx.test(routingNumber)) {
      errors.push(ValidationErrorEnum.INVALID_ROUTING_NUMBER);
    }
  }

  if (errors.length === 0) {
    let sum = 0;
    (routingNumber as string).split('').map((value: string, index: number) => {
      switch (index) {
        case 0:
        case 3:
        case 6:
          sum += 3 * parseInt(value);
          break;
        case 1:
        case 4:
        case 7:
          sum += 7 * parseInt(value);
          break;
        default:
          sum += parseInt(value);
      }
    });

    if (sum % 10 !== 0) {
      errors.push(ValidationErrorEnum.INVALID_ROUTING_NUMBER);
    }
  }

  return [errors.length === 0, errors];
};
