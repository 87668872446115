import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const accountNumberConfirmValidator: WalletValidatorFunction = (
  accountNumberConfirm: any,
  accountNumber: any
) => {
  const errors: ValidationError[] = [];

  if (typeof accountNumberConfirm !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    if (accountNumberConfirm !== accountNumber) {
      errors.push(ValidationErrorEnum.ACCOUNT_CONFIRMATION_MISMATCH);
    }
  }

  return [errors.length === 0, errors];
};
