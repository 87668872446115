import cardValidator from 'card-validator';

import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction, CardType } from './types';

export const cardNumberValidator: WalletValidatorFunction = (
  number: any,
  allowedCardTypes?: CardType[]
) => {
  const errors: ValidationError[] = [];

  if (typeof number !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  const validation = cardValidator.number(number);

  if (!validation.isValid) {
    errors.push(ValidationErrorEnum.INVALID_CARD_NUMBER);
  }

  // If it's valid, then we check if this card type is allowed.
  if (validation.isPotentiallyValid && Array.isArray(allowedCardTypes)) {
    const cardType: string | null = validation.card && validation.card.type;
    if (cardType !== null) {
      // convert libraries enum to our CardType enum.
      const currentCardAllowed = allowedCardTypes.find(
        (allowedCardType) => allowedCardType === cardType
      );
      if (!currentCardAllowed) {
        errors.push(ValidationErrorEnum.INVALID_CARD_TYPE);
      }
    }
  }

  return [errors.length === 0, errors];
};

export const getCardType = (number: string): string | null => {
  const validation = cardValidator.number(number);
  return validation.card ? validation.card.type : null;
};
