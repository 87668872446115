import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import PaypalPayBtn from 'components/Core/Payment/Buttons/PayPalPayBtn/PaypalPayBtn';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { colors, fontSize, breakpoints } from 'styles/cp';
import { PaymentMethod } from 'types/constants';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'sections/PayPalCheckoutTabs';

export type PayPalCheckoutTabsProps = {
  allowedPaymentMethods: PaymentMethod[];
  payment: {
    balanceAmount: number;
  };
  isPPCheckoutExperiment: boolean;
};

const PayPalCheckoutTabs: React.FC<PayPalCheckoutTabsProps> = (props) => {
  const {
    allowedPaymentMethods,
    payment: { balanceAmount },
    isPPCheckoutExperiment,
  } = props;

  useEffect(() => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'view',
      action: 'render',
      activityInfo: {
        activityObject: 'PayPalCheckoutTabs',
        numberOfTabs: allowedPaymentMethods?.length,
        allowedPaymentMethods,
        balanceAmount,
      },
    });
  }, [allowedPaymentMethods, balanceAmount]);

  if (!Array.isArray(allowedPaymentMethods)) {
    return <></>;
  }

  const getPaymentMethodTab = ({
    allowedPaymentMethod,
  }: {
    allowedPaymentMethod: PaymentMethod;
  }) => (
    <>
      <li data-testid={`paypalcheckout-btn-${allowedPaymentMethod}`}>
        <PaypalPayBtn
          expectedPaymentMethodType={allowedPaymentMethod}
          isPPCheckoutExperiment={isPPCheckoutExperiment}
        />
      </li>
      <style jsx>{`
        li {
          text-align: center;
          min-height: 76px;
          flex: 1;
          margin-right: ${allowedPaymentMethods.includes('paypal_ppaam') &&
          allowedPaymentMethods.includes('venmo') &&
          allowedPaymentMethod === 'paypal_ppaam'
            ? '8px'
            : 'none'};
          @media screen and (max-width: ${breakpoints.xsl}) {
            margin-left: -1px;
            margin-right: 0px;
            margin-bottom: -20px;
          }
        }
      `}</style>
    </>
  );

  const paymentMethodsTabs = allowedPaymentMethods.map((allowedPaymentMethod) => {
    switch (allowedPaymentMethod) {
      case 'paypal_ppaam':
        return getPaymentMethodTab({ allowedPaymentMethod });
      case 'venmo':
        return getPaymentMethodTab({ allowedPaymentMethod });
    }
    return null;
  });

  return (
    <Fragment>
      {paymentMethodsTabs && Array.isArray(paymentMethodsTabs) && paymentMethodsTabs.length > 0 && (
        <>
          <span className="payment-amount-label">
            <FormattedMessage id="EXPRESS_CHECKOUT" defaultMessage="EXPRESS CHECKOUT" />
          </span>
          <div className="desktop-tabs paypalcheckout">
            <ul className="paypalcheckout-container">{paymentMethodsTabs}</ul>
          </div>
        </>
      )}

      <style jsx>{`
        :global(.desktop-tabs) {
          flex: 1;
          display: flex;
          overflow: auto;
          margin-bottom: 25px;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            justify-content: flex-start;
            display: flex;
            flex: 1;
            @media screen and (max-width: ${breakpoints.xsl}) {
              display: block;
              margin-bottom: 28px;
            }
          }
        }
        .payment-amount-label {
          font-family: AvenirNextforINTUIT-Demi;
          font-size: ${fontSize.xxs};
          color: ${colors.whiteGray};
          text-transform: uppercase;
          display: block;
        }
        .paypalcheckout {
          margin: 12px 0px 0px 0px;
          @media screen and (max-width: ${breakpoints.xsl}) {
            overflow: visible;
            justify-content: space-between;
          }
          .paypalcheckout-container {
            z-index: 0;
          }
        }
      `}</style>
    </Fragment>
  );
};
export default PayPalCheckoutTabs;
