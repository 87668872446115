import { FormikBag, withFormik, InjectedFormikProps } from 'formik';

import { cardWalletField } from './CardField';
import { WalletField } from './Field';
import { Errors, Form, genericForm, IWalletFormProps } from './Form';
import CardUtils from './util/card';

import { ICardCreateData } from 'components/Core/WalletForms/network/src/types';
import { getCardType } from 'components/Core/WalletForms/validations/src/cardNumber';
import { CardType } from 'components/Core/WalletForms/validations/src/types';

export interface ICardTokenCreateFormData extends ICardCreateData {
  expDate: string;
  zipCode: string;
  savePaymentMethod?: boolean;
}

export interface ICreateCardTokenFormProps
  extends IWalletFormProps<ICardTokenCreateFormData, ICardCreateData> {
  initialValues?: ICardTokenCreateFormData;
  allowedCardTypes?: CardType[];
  zipCodeLength?: number;
}
//@ts-ignore
export const CardField: WalletField<ICardTokenCreateFormData> = cardWalletField;
export type CreateCardTokenErrors = Errors<ICardTokenCreateFormData>;
export type CreateCardTokenFormikBag = FormikBag<
  ICreateCardTokenFormProps,
  ICardTokenCreateFormData
>;

const _CardTokenForm: Form<ICreateCardTokenFormProps, ICardTokenCreateFormData> = (
  props: InjectedFormikProps<
    ICreateCardTokenFormProps & IWalletFormProps<ICardTokenCreateFormData>,
    ICardTokenCreateFormData
  >
) => {
  const {
    values: { number },
  } = props;
  props.values.cardType = getCardType(number);
  return genericForm(props);
};

export const CardTokenForm = withFormik<ICreateCardTokenFormProps, ICardTokenCreateFormData>({
  mapPropsToValues: CardUtils.getInitialFormValues,
  handleSubmit: CardUtils.handleSubmit,
  validate: CardUtils.validate,
  //@ts-ignore
})(_CardTokenForm);
