import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const institutionNumberValidator: WalletValidatorFunction = (institutionNumber: any) => {
  const errors: ValidationError[] = [];

  if (typeof institutionNumber !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    const institutionNumberRegEx = /^\d{3}$/;
    const result = institutionNumberRegEx.test(institutionNumber);
    if (!result) {
      errors.push(ValidationErrorEnum.INVALID_INSTITUTION_NUMBER);
    }
  }

  return [errors.length === 0, errors];
};
