/* eslint-disable */
import { ICardCreateFormData } from './CreateCardForm';
import {
  EnhancedFieldProps,
  genericWalletField,
  IWalletFormFieldProps,
  WalletField,
} from './Field';
import { ICardUpdateFormData } from './UpdateCardForm';
import CardUtils from './util/card';

type CardFieldType = ICardCreateFormData | ICardUpdateFormData;

export const cardWalletField: WalletField<CardFieldType> = ({
  name,
  children,
}: IWalletFormFieldProps<CardFieldType>) => {
  return genericWalletField({
    name,
    children: (props: EnhancedFieldProps<CardFieldType>) => {
      if (name === 'expDate') {
        // We need to interfere with the expiration date.
        // If the user is updating the expiration date, then we need to
        // reflect that change in the expMonth and expYear field.

        const { onChange } = props.field;
        props.field.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const { initialValues } = props.form;
          if (e.target.value !== initialValues.expDate) {
            // Yes the user is updating the expDate field.
            const { expMonth, expYear } = CardUtils.getExpMonthAndYearFromExpDate(e.target.value);

            props.form.setValues({
              ...props.form.values,
              expMonth,
              expYear,
            });
          }
          onChange(e);
        };
      }

      return children(props);
    },
  });
};
