import classNames from 'classnames';
import React from 'react';

import { colors, fontSize } from 'styles/cp';

export type Props = {
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  hovered?: boolean;
  value?: string;
  ariaLabel?: string;
};

const Radio: React.FC<Props> = ({
  name,
  disabled,
  checked,
  className,
  hovered,
  ariaLabel,
  ...restProps
}) => {
  const prefixCls = 'cp-radio';

  const rootClassName = classNames(prefixCls, className, {
    [`${prefixCls}--hover`]: !!hovered,
  });

  return (
    <div {...restProps} aria-label={ariaLabel}>
      <label className="cp-radio-container">
        <input
          className="cp-radio-input"
          type="radio"
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={() => {}}
        />
        <span className={rootClassName}></span>
      </label>

      <style jsx>{`
        /* The container */
        .cp-radio-container {
          position: relative;
          cursor: pointer;
          user-select: none;

          /* Hide the browser's default radio button */
          input {
            display: none;
          }
        }

        /* Create a custom radio button */
        .cp-radio {
          position: relative;
          height: 15px;
          width: 15px;
          display: inline-block;
          background-color: ${colors.white};
          border: 1px solid ${colors.gray03};
          border-radius: 50%;
          vertical-align: middle;
        }

        /* On mouse-over, add a grey background color */
        /* On focus, change border color */
        .cp-radio-container {
          &:hover .cp-radio {
            border-color: ${colors.green};
          }
          &:focus .cp-radio {
            box-shadow: 0 0 2px 1px ${colors.green01};
          }
        }

        /* On disabled, change border color and not allowed cursor */
        .cp-radio-input:disabled ~ .cp-radio {
          border-color: ${colors.gray04};
          cursor: not-allowed;
          opacity: 0.4;
        }

        /* When the radio button is checked, change border color */
        .cp-radio-container input:checked ~ .cp-radio {
          border-color: ${colors.green};
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .cp-radio::after {
          content: ' ';
          visibility: hidden;
        }

        /* Show the indicator (dot/circle) when checked */
        .cp-radio-container {
          input:checked ~ .cp-radio::after {
            visibility: visible;
          }

          /* Style the indicator (dot/circle) */
          .cp-radio::after {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: ${colors.green};
          }
        }

        .cp-radio-label {
          display: inline-block;
          vertical-align: bottom;
          font-size: ${fontSize.xs};
          line-height: 20px;
          padding-left: 12px;
        }

        .cp-radio--hover {
          border-color: ${colors.green};
        }
      `}</style>
    </div>
  );
};

export default Radio;
