import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Button from 'components/Shared/Button/Button';
import { Delete } from 'components/Shared/Icons/Icons';
import MessageCard from 'components/Shared/MessageCard/MessageCard';
import SegmentIO from 'reporting/SegmentIO';
import { colors, fontSize, breakpoints } from 'styles/cp';
import { UserWallet } from 'types/Wallet';
import { TXN_MAP } from 'types/constants';

type DeleteWalletModalProps = {
  hide: () => void;
  onCancel?: () => void;
  onOk?: () => void;
  deleteWalletStatus: string;
  currentlyDeletingWalletId: string;
  userWallets: UserWallet[];
};

class DeleteWalletModal extends Component<DeleteWalletModalProps, {}> {
  constructor(props: DeleteWalletModalProps) {
    super(props);
  }
  onCancel = () => {
    SegmentIO.transactionEngaged({
      activity_type: 'wallet',
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: 'cancel',
      ui_access_point: 'modal',
    });
    this.props.hide();
    this.props.onCancel && this.props.onCancel();
  };
  onOk = () => {
    SegmentIO.transactionEngaged({
      activity_type: 'wallet',
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: 'delete',
      ui_access_point: 'modal',
    });
    this.props.onOk && this.props.onOk();
  };
  render() {
    const { deleteWalletStatus } = this.props;
    const { currentlyDeletingWalletId, userWallets } = this.props;
    const wallet = userWallets.find((account) => account.id === currentlyDeletingWalletId);
    if (!wallet) {
      return null;
    }
    return (
      <div className="w">
        <div className="confirmation-container">
          {
            // Warning is the design we use to show to the user
            deleteWalletStatus === TXN_MAP.STATUS.ERROR && (
              <MessageCard status={'warn'} text="WALLET_DELETE_ERROR" />
            )
          }
          <div className="confirmation-data">
            <Delete width={46} height={62} />

            <span className="confirmation-message">
              <FormattedMessage
                id="SETTINGS_CONFIRAMTION_MESSAGE"
                defaultMessage="Are you sure you want to delete this payment method?"
              />
            </span>

            <div className="cta">
              <Button onClick={this.onCancel}>
                <FormattedMessage id="SETTINGS_CONFIRAMTION_CANCEL" defaultMessage="Cancel" />
              </Button>
              <Button buttonType="dark" onClick={this.onOk}>
                <FormattedMessage id="SETTINGS_CONFIRAMTION_DELETE" defaultMessage="Delete" />
              </Button>
            </div>
          </div>
        </div>

        <style jsx>{`
          .w {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-direction: column;
          }

          :global(.cta button) {
            width: 155px;
            height: 40px;
            @media screen and (max-width: ${breakpoints.sm}) {
              width: 140px;
            }
          }

          .confirmation-container {
            max-width: 100%;
            width: 410px;
            background-color: ${colors.white};
            padding: 25px 35px;
            border-radius: 4px;
            border: solid 1px ${colors.whiteGray};

            .confirmation-data {
              text-align: center;
            }
            @media screen and (max-width: ${breakpoints.sm}) {
              padding: 25px 23px;
            }
          }

          .confirmation-header {
            font-size: ${fontSize.md};
            color: ${colors.gray};
          }

          .confirmation-message {
            font-size: ${fontSize.sm};
            text-align: center;
            display: block;
            font-family: AvenirNextforINTUIT-Regular;
            padding: 15px 15px 0;
          }

          .cta {
            margin-top: 25px;
            display: flex;
            justify-content: space-between;
          }
        `}</style>
      </div>
    );
  }
}

const mapStateToProps = ({ wallet }: { wallet: DeleteWalletModalProps }) => {
  const { deleteWalletStatus, currentlyDeletingWalletId, userWallets } = wallet;
  return {
    deleteWalletStatus,
    currentlyDeletingWalletId,
    userWallets,
  };
};
export default connect(mapStateToProps, {})(DeleteWalletModal);
