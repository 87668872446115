import React from 'react';

import { IconBaseProps } from './IconBase';

const Calendar: React.FC<IconBaseProps> = ({ width = 24, height = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <rect width="24" height="24" fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0" transform="scale(0.0416667)" />
        </pattern>
        <image
          id="image0"
          width="24"
          height="24"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGOfPtRkwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADB/VeXAAAA+klEQVRIDWNgGNYgO6doKwjj8iQheZA+RmTNubkNfP//f570n4EhgIHhPz+yHGE240egYRsYGXnzJk9u+ARTzwJjgGiI4f/jkcWIZ//nBzosnuH/Z5CWBJg+VAvALgd6i5VVb0p/52VY8Eyd0ucN04BMI8vnFJbr/v/9+xLE98iqkNhADf9BGEmIJCY2/UwkmUCGYpQggumnxBcwM2D0wPgAGKkoyRfmGkI0Np/T3AejFhCKFobRICI1iBg/gnSACi6COtEUIPRAzIBJoxQVoPIcVOSCSkVgpoGpIYoG6gGrA5mBrAHVAmBlASrPIUUu+RUOsgWjbIIhAADSi1dhVl8tuwAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

export default Calendar;
