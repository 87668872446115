import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

// Currently we're only allowing US and Canada. Please change this as we move forward.
const US_AND_CA_ZIPCODE_LENGTH = 5;

export const zipCodeValidator: WalletValidatorFunction = (
  zipCode: any,
  minLength: number = US_AND_CA_ZIPCODE_LENGTH
) => {
  const errors: ValidationError[] = [];

  if (typeof zipCode !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    const zipRegex = /^[ A-Za-z0-9\-\s]*$/;
    const isValid = zipRegex.test(zipCode);

    if (!isValid) {
      errors.push(ValidationErrorEnum.INVALID_ZIP_CODE);
    }

    if (errors.length === 0) {
      if (zipCode.length < minLength) {
        errors.push(ValidationErrorEnum.INVALID_ZIP_CODE);
      }
    }
  }

  return [errors.length === 0, errors];
};
