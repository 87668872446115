import { formatPaymentMethod } from 'shared/clientUtils';
import { RootState } from 'store/store';
export type PaymentSelectorState = Pick<RootState, 'payment'>;

export const paymentMethodType = (store: PaymentSelectorState) => {
  return store.payment.paymentMethodType;
};

export const isPaymentInProgress = (store: PaymentSelectorState): boolean =>
  store.payment.paymentStatus === 'inProgress';

export const isSDPaymentMethodSelected = (store: PaymentSelectorState): boolean => {
  return store?.payment.paymentMethodType === 'credit_karma';
};
export const getUsedPaymentMethod = (store: PaymentSelectorState) => {
  const { paymentMethodType, isPayPalCommerceInvoice, payPalProcessor = '' } = store.payment;
  const formattedPaymentMethod = formatPaymentMethod(paymentMethodType);

  const paymentMethod = isPayPalCommerceInvoice // Format Paypal cases
    ? 'paypal_appconnect_commerce'
    : formattedPaymentMethod === 'pp'
    ? payPalProcessor.toLowerCase() === 'appconnect'
      ? 'paypal_appconnect_non_commerce'
      : 'paypal_ppaam'
    : formattedPaymentMethod;

  return paymentMethod;
};
export const getUsedPaymentType = (store: PaymentSelectorState) => {
  const { isDateScheduled, isAutoPayOn } = store.payment;
  const paymentType = isDateScheduled ? 'schedule' : isAutoPayOn ? 'autopay' : 'payment';

  return paymentType;
};
