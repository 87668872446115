import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/Core/Modal/Modal';
import Button from 'components/Shared/Button/Button';
import Close from 'components/Shared/Icons/Close';
import ScheduledLogin from 'components/Shared/Icons/ScheduledLogin';
import { breakpoints, colors } from 'styles/cp';

export interface Props {
  closeCallback: () => void;
  // eslint-disable-next-line no-unused-vars
  loginCallback: (event: any) => void;
  isAutoPay: boolean;
}

const PreLoginSplashScreen: React.FC<Props> = ({ closeCallback, loginCallback, isAutoPay }) => {
  const getText = () => {
    const bodyPart1 = (
      <Fragment>
        {!isAutoPay ? (
          <FormattedMessage
            id="SCHEDULE_PAY_SPLASH_SCREEN_BODY_1"
            defaultMessage="Schedule payments so you control when money leaves your bank."
          />
        ) : (
          <FormattedMessage
            id="AUTO_PAY_SPLASH_SCREEN_BODY"
            defaultMessage="Take this recurring payment off your to-do list with autopay. Create an account or sign in with a Mint or TurboTax ID."
          />
        )}
      </Fragment>
    );
    const bodyPart2 = (
      <Fragment>
        {!isAutoPay ? (
          <FormattedMessage
            id="SCHEDULE_PAY_SPLASH_SCREEN_BODY_2"
            defaultMessage="Create an account or sign in."
          />
        ) : (
          <FormattedMessage
            id="AUTO_PAY_SPLASH_SCREEN_BODY_2"
            defaultMessage="Create an account or sign in with a Mint or TurboTax ID."
          />
        )}
      </Fragment>
    );
    return (
      <Fragment>
        <div className="text">
          {bodyPart1}
          <Fragment>
            <br />
            {bodyPart2}
          </Fragment>
        </div>
        <style jsx>{`
          .text {
            text-align: center;
            font-family: AvenirNextforINTUIT-Regular;
            color: #000000;
            font-size: 14px;
            padding: 0 40px 24px;

            @media (max-width: ${breakpoints.md}) {
              font-size: 13px;
              padding: 0 40px 40px;
            }
          }
        `}</style>
      </Fragment>
    );
  };

  const getButtonContent = () => {
    return (
      <span className="btn-text">
        {!isAutoPay ? (
          <FormattedMessage
            id="SCHEDULE_PAY_SPLASH_SCREEN_ACTION"
            defaultMessage="Sign in to schedule"
          />
        ) : (
          <FormattedMessage id="AUTO_PAY_SPLASH_SCREEN_ACTION" defaultMessage="Sign in to set up" />
        )}
      </span>
    );
  };
  return (
    <Fragment>
      <Modal data-cy="pre-login-splashscreen">
        <div className="pre-login dialog" data-testid="Pre login splashscreen">
          <div
            className="close"
            onClick={closeCallback}
            aria-label="Close"
            data-cy="pre-login-splash-screen-btn-close"
          >
            <Close width={20} height={17} />
          </div>
          <div className="header">
            <span className="header-text">
              {!isAutoPay ? (
                <FormattedMessage
                  id="SCHEDULE_PAY_SPLASH_SCREEN_HEADER"
                  defaultMessage="Set a payment date"
                />
              ) : (
                <FormattedMessage
                  id="AUTO_PAY_SPLASH_SCREEN_HEADER"
                  defaultMessage="Set up autopay for this merchant"
                />
              )}
            </span>
          </div>
          <div className="icon">
            <ScheduledLogin />
          </div>
          {getText()}
          <div className="login-btn">
            <Button
              data-cy="pre-login-splash-screen-btn-login"
              buttonType="primary"
              aria-label="Login"
              width="100%"
              size={window.innerWidth <= 768 ? 'standard' : 'jumbo'}
              onClick={loginCallback}
            >
              {getButtonContent()}
            </Button>
          </div>
        </div>
      </Modal>

      <style jsx>{`
        .pre-login {
          position: fixed;
          background: white;
          max-width: 480px;
          //height: 500px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 0 0 1px ${colors.gray05};
          z-index: 999;
          padding-bottom: 10px;

          @media (max-width: ${breakpoints.sm}) {
            width: 90%;
            height: auto;
          }
        }
        .dialog {
          border-radius: 4px;
          display: flex;
          justify-items: center;
          align-items: center;
          flex-direction: column;
          overflow: hidden;
          background-color: white;
          cursor: default;

          .close {
            margin: 24px 16px 0 0;
            align-self: flex-end;
            cursor: pointer;
          }

          .header {
            padding-bottom: 24px;
            font-size: 20px;
            font-family: AvenirNextforINTUIT-Demi;

            @media (max-width: ${breakpoints.md}) {
              padding-bottom: 43px;
            }
          }

          .icon {
            padding-bottom: 8px;

            @media (max-width: ${breakpoints.md}) {
              padding-bottom: 31px;
            }
          }

          .login-btn {
            width: 63%;
            margin-bottom: 15px;

            @media (max-width: ${breakpoints.md}) {
              width: 80%;
            }

            .btn-text {
              font-family: AvenirNextforINTUIT-Regular;
              color: white;

              @media (max-width: ${breakpoints.md}) {
                font-size: 14px;
              }
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default PreLoginSplashScreen;
