import React from 'react';

import { IconBaseProps } from './IconBase';

const ScheduledLogin: React.FC<IconBaseProps> = ({ width = 230, height = 195 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 230 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0859 63.6762C23.1136 53.2912 37.6852 48.3131 50.7326 47.6333C62.9265 46.9954 75.9434 50.2165 85.3734 58.0915C92.334 63.9272 98.6545 71.6348 104.619 78.4745C111.031 85.7952 116.732 92.844 124.15 99.2549C144.879 117.149 155.295 127.304 182.782 119.125C199.975 114.001 212.789 100.949 213.47 82.846"
        stroke="#ECEEF0"
        strokeWidth="3.1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.1 15.51 18.61 12.4 1.55 15.51"
      />
      <path
        d="M100.411 176.027L103.114 183.348C103.385 184.082 103.361 184.896 103.049 185.612C102.736 186.328 102.16 186.886 101.447 187.165C96.9966 188.88 80.7076 196.379 79.3866 194.57C78.0656 192.76 92.4239 177.701 92.4239 177.701L100.411 176.027Z"
        fill="#393A3D"
      />
      <path
        d="M120.301 143.074L127.414 145.616C128.318 146.073 129.009 146.878 129.34 147.857C129.671 148.835 129.615 149.909 129.183 150.845C129.183 150.845 121.328 165.402 119.925 166.846C118.523 168.289 114.672 169.261 114.672 169.261L117.548 151.692L120.301 143.074Z"
        fill="#393A3D"
      />
      <path
        d="M101.058 84.3514C93.8835 94.1298 85.4291 113.3 86.4147 120.401C87.7357 129.813 104.187 142.603 118.474 154.432L121.167 145.019C115.436 132.041 105.407 121.739 104.726 119.637C104.045 117.535 112.855 106.753 115.192 93.2827C116.534 85.324 107.388 75.6921 101.058 84.3514Z"
        fill="#393A3D"
      />
      <path
        d="M109.803 90.8846C98.9508 124.602 101.725 141.272 92.9453 177.938L100.79 176.893C106.064 165.922 112.903 151.385 115.23 139.055C116.246 133.449 125.391 99.9309 126.905 90.0897C128.612 80.5623 115.179 74.1933 109.803 90.8846Z"
        fill="#6B6D73"
      />
      <path
        d="M68.1981 43.5931C74.0511 48.6339 79.3758 57.5861 81.6621 57.4501C83.9485 57.3142 90.4417 32.7584 96.3862 34.4422C102.331 36.1259 102.483 76.5677 86.7531 77.739C71.023 78.9103 61.4102 44.5761 61.4102 44.5761C61.4102 44.5761 64.1538 40.1105 68.1981 43.5931Z"
        fill="#00A6A4"
      />
      <path
        d="M90.1679 41.8555C88.0136 53.3595 94.6694 59.2161 97.535 68.848C99.4657 75.3112 97.6874 88.0911 97.6874 88.0911C97.6874 88.0911 113.062 100.965 127.562 93.6862C127.562 93.6862 138.496 50.1384 133.111 36.8774C126.688 21.0646 93.2163 25.5826 90.1679 41.8555Z"
        fill="#22BCBC"
      />
      <path
        d="M102.142 22.6997C105.74 22.1913 108.175 18.1748 107.582 13.7287C106.988 9.28252 103.591 6.09042 99.9938 6.59888C96.3966 7.10734 93.9614 11.1238 94.5547 15.57C95.148 20.0161 98.5452 23.2082 102.142 22.6997Z"
        stroke="#50BAEA"
        strokeWidth="0.86"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.0961 16.9353C94.4475 23.2102 98.0041 22.8128 98.4512 24.6011C99.4673 28.5543 101.947 37.6529 103.857 37.632C105.767 37.6111 112.647 30.876 111.844 29.4328C107.779 22.1748 108.521 12.637 102.831 8.38048C98.6849 5.28486 91.2568 8.4014 93.0961 16.9353Z"
        fill="#DCA174"
      />
      <path
        d="M106.233 8.89273C106.233 8.89273 105.786 1.92758 97.9513 1.823C95.1975 -1.67003 90.0761 0.29611 90.8382 4.01922C91.9966 9.1019 95.1873 10.6079 95.1873 10.6079C95.1873 10.6079 100.339 6.66514 103.703 9.76076C105.003 11.0053 106.02 10.9844 106.233 8.89273Z"
        fill="#393A3D"
      />
      <path
        d="M107.072 9.05051C106.771 8.77529 106.394 8.60419 105.994 8.56138C105.594 8.51856 105.191 8.60619 104.842 8.8119C104.492 9.0176 104.215 9.33098 104.048 9.70781C103.881 10.0846 103.833 10.5059 103.912 10.9121C104.105 13.2129 104.928 16.4235 106.351 15.9529C107.773 15.4823 109.175 10.6088 107.072 9.05051Z"
        fill="#393A3D"
      />
      <path
        d="M107.335 19.1489C109.126 19.1489 110.577 17.6552 110.577 15.8127C110.577 13.9702 109.126 12.4766 107.335 12.4766C105.545 12.4766 104.094 13.9702 104.094 15.8127C104.094 17.6552 105.545 19.1489 107.335 19.1489Z"
        fill="#DCA174"
      />
      <path
        d="M119.505 29.5154C114.668 37.0453 137.907 58.7983 139.716 65.6589C141.525 72.5194 137.369 92.6409 137.369 100.359C137.369 108.077 143.13 104.908 145.498 101.938C147.865 98.9681 161.563 77.0374 156.676 58.3068C153.373 46.0498 123.183 23.7844 119.505 29.5154Z"
        fill="#49C2C9"
      />
      <path
        d="M144.791 102.766C143.531 100.12 138.084 98.9066 137.18 102.713C132.8 121.329 146.102 123.797 148.134 126.276C151.843 123.839 143.267 117.669 144.588 115.148C146.172 116.906 147.665 118.749 149.059 120.67C149.272 116.895 145.949 115.587 145.441 111.362C145.147 108.988 146.051 105.401 144.791 102.766Z"
        fill="#DCA174"
      />
      <path
        d="M8.05366 84.3778C6.11281 89.6069 11.5391 94.8987 15.939 97.2727C49.4721 115.365 83.8283 90.7468 111.325 73.9719C139.188 56.9774 149.218 36.7513 187.476 45.6617C193.654 47.1049 199.172 49.5626 204.751 52.0098"
        stroke="#ECEEF0"
        strokeWidth="3.38"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3.38 16.91 20.29 13.53 1.69 16.91"
      />
      <path
        d="M191.258 32.571L172.113 35.6352C171.794 35.6849 171.468 35.6587 171.161 35.5587C170.853 35.4588 170.572 35.2877 170.338 35.0585C170.105 34.8294 169.926 34.5482 169.814 34.2367C169.702 33.9252 169.662 33.5915 169.695 33.2612L170.924 21.6736C170.976 21.201 171.177 20.759 171.497 20.4153C171.816 20.0715 172.236 19.8449 172.692 19.7702L191.837 16.7373C192.156 16.6855 192.483 16.7102 192.791 16.8094C193.1 16.9086 193.382 17.0796 193.616 17.3092C193.85 17.5389 194.029 17.8209 194.14 18.1334C194.251 18.446 194.29 18.7806 194.255 19.1113L193.026 30.6885C192.971 31.158 192.769 31.5961 192.449 31.936C192.13 32.276 191.712 32.499 191.258 32.571Z"
        fill="#D4D7DC"
      />
      <path
        d="M180.141 27.6291L173.079 21.5739C172.905 21.4505 172.774 21.2749 172.702 21.0712C172.63 20.8675 172.621 20.6458 172.677 20.4368C172.733 20.2277 172.85 20.0417 173.013 19.9045C173.176 19.7672 173.377 19.6854 173.587 19.6705L191.136 16.8049C191.365 16.7316 191.612 16.7442 191.834 16.8406C192.055 16.9371 192.236 17.111 192.344 17.3317C192.453 17.5523 192.481 17.8054 192.426 18.0459C192.37 18.2865 192.233 18.4989 192.04 18.6456L183.911 27.0121C183.431 27.5195 182.805 27.8537 182.125 27.9649C181.446 28.076 180.75 27.9583 180.141 27.6291Z"
        fill="#ECEEF0"
      />
      <path
        d="M47.8298 102.162L29.7829 107.988C29.5649 108.056 29.3344 108.073 29.1092 108.036C28.884 107.999 28.6699 107.909 28.4836 107.774C28.2972 107.639 28.1434 107.462 28.0339 107.256C27.9245 107.05 27.8623 106.821 27.8522 106.586L27.4661 97.4039C27.453 97.0707 27.5449 96.742 27.728 96.4669C27.9111 96.1918 28.1756 95.9849 28.4822 95.877L46.5291 90.0518C46.7472 89.9807 46.9783 89.9624 47.2043 89.9985C47.4304 90.0345 47.6453 90.1239 47.8322 90.2596C48.0192 90.3953 48.1731 90.5736 48.2819 90.7807C48.3908 90.9877 48.4517 91.2179 48.4598 91.4532L48.8358 100.688C48.8402 101.011 48.7453 101.328 48.5647 101.593C48.3841 101.857 48.1269 102.057 47.8298 102.162Z"
        fill="#AACF3C"
      />
      <path
        d="M40.3423 100.091C40.9472 98.0409 40.2796 96.0169 38.8512 95.5705C37.4228 95.1241 35.7744 96.4245 35.1695 98.4749C34.5646 100.525 35.2322 102.549 36.6606 102.996C38.089 103.442 39.7373 102.142 40.3423 100.091Z"
        fill="#2D9F48"
      />
      <path
        d="M158.192 122.507L176.94 125.038C177.167 125.067 177.397 125.042 177.612 124.966C177.828 124.889 178.024 124.763 178.185 124.596C178.346 124.43 178.468 124.227 178.541 124.005C178.614 123.782 178.636 123.545 178.607 123.312L177.499 114.14C177.456 113.805 177.306 113.493 177.073 113.254C176.839 113.016 176.535 112.864 176.209 112.823L157.46 110.281C157.234 110.252 157.004 110.277 156.788 110.353C156.572 110.43 156.376 110.556 156.216 110.723C156.055 110.89 155.933 111.092 155.86 111.315C155.787 111.537 155.764 111.774 155.794 112.007L156.973 121.168C157.008 121.497 157.145 121.806 157.365 122.047C157.585 122.289 157.875 122.45 158.192 122.507Z"
        fill="#AACF3C"
      />
      <path
        d="M168.439 121.135C169.771 120.442 170.09 118.329 169.15 116.417C168.211 114.505 166.369 113.516 165.037 114.21C163.705 114.903 163.387 117.015 164.326 118.927C165.265 120.84 167.107 121.828 168.439 121.135Z"
        fill="#2D9F48"
      />
      <path
        d="M201.136 87.3213L207.985 61.27C208.006 61.0738 208.1 60.8937 208.249 60.7685C208.397 60.6434 208.587 60.5831 208.778 60.6007L224.508 62.3053C224.601 62.3149 224.692 62.3439 224.774 62.3907C224.856 62.4374 224.928 62.5009 224.986 62.5772L228.837 67.4612C228.956 67.6147 229.014 67.8091 228.999 68.005L222.638 89.643C222.618 89.8384 222.523 90.0175 222.374 90.141C222.226 90.2645 222.036 90.3223 221.846 90.3018L201.817 88.137C201.72 88.1318 201.624 88.1063 201.536 88.062C201.449 88.0177 201.371 87.9556 201.307 87.8793C201.243 87.803 201.195 87.7142 201.166 87.6183C201.137 87.5224 201.127 87.4213 201.136 87.3213Z"
        fill="#E4E4E4"
      />
      <path
        d="M228.992 67.701L224.765 62.3359H224.673L224.246 66.5192C224.236 66.6113 224.244 66.7045 224.27 66.7934C224.295 66.8823 224.337 66.9651 224.394 67.0371C224.451 67.1091 224.521 67.1688 224.6 67.2127C224.679 67.2567 224.766 67.284 224.856 67.2931L228.87 67.7219C228.91 67.7325 228.952 67.7325 228.992 67.7219V67.701Z"
        fill="#8D8F96"
      />
      <path
        d="M211.783 68.1944C212.776 68.1944 213.582 67.3656 213.582 66.3433C213.582 65.321 212.776 64.4922 211.783 64.4922C210.79 64.4922 209.984 65.321 209.984 66.3433C209.984 67.3656 210.79 68.1944 211.783 68.1944Z"
        fill="#52B448"
      />
      <path
        d="M214.051 79.9609L219.934 80.5989"
        stroke="#23BCBC"
        strokeWidth="1.26"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.959 73.5357L209.349 72.1762C209.045 72.141 208.739 72.22 208.487 72.3987C208.234 72.5774 208.054 72.8438 207.977 73.1488L207.713 74.1946C207.667 74.3783 207.659 74.5702 207.692 74.757C207.724 74.9439 207.795 75.1214 207.901 75.2773C208.006 75.4332 208.142 75.5639 208.301 75.6604C208.46 75.7569 208.637 75.817 208.821 75.8365L221.431 77.2066C221.736 77.2369 222.041 77.1546 222.293 76.9745C222.544 76.7944 222.725 76.5282 222.803 76.2235L223.067 75.1777C223.113 74.9939 223.121 74.8021 223.088 74.6152C223.056 74.4284 222.985 74.2509 222.88 74.095C222.774 73.9391 222.638 73.8084 222.479 73.7118C222.32 73.6153 222.143 73.5552 221.959 73.5357Z"
        fill="#AACF3D"
      />
      <path
        d="M0.963292 69.2929L16.3885 75.5678C16.9148 75.7492 17.4829 75.758 18.0143 75.5932C18.5457 75.4284 19.0144 75.098 19.3557 74.6475L30.1574 59.6505L11.196 51.9219L0.36376 66.9294C0.206727 67.1095 0.0955214 67.3268 0.0400922 67.562C-0.015337 67.7972 -0.0132614 68.0429 0.046133 68.277C0.105527 68.5112 0.220388 68.7265 0.380442 68.9038C0.540495 69.0811 0.74075 69.2148 0.963292 69.2929Z"
        fill="#D4D7DC"
      />
      <path
        d="M16.6953 67.7385L21.3798 69.6418L24.4791 65.3331L19.7946 63.4297L16.6953 67.7385Z"
        fill="#ECEEF0"
      />
      <path
        d="M10.7812 65.3322L15.4657 67.2356L18.565 62.9268L13.8805 61.0234L10.7812 65.3322Z"
        fill="#ECEEF0"
      />
      <path
        d="M4.85547 62.9247L9.53993 64.828L12.6494 60.5193L7.96489 58.6055L4.85547 62.9247Z"
        fill="#ECEEF0"
      />
      <path
        d="M12.9805 72.8883L16.7809 74.4047C17.0415 74.4957 17.3232 74.5014 17.587 74.4208C17.8509 74.3403 18.084 74.1776 18.2543 73.955L20.7744 70.462L16.0899 68.5586L12.9805 72.8883Z"
        fill="#ECEEF0"
      />
      <path
        d="M7.0625 70.4767L11.747 72.3906L14.8564 68.0818L10.1719 66.168L7.0625 70.4767Z"
        fill="#ECEEF0"
      />
      <path
        d="M2.03262 68.4365L5.83304 69.9739L8.94247 65.6651L4.258 63.7617L1.70745 67.2548C1.62931 67.3466 1.57472 67.4571 1.54865 67.5761C1.52258 67.6952 1.52585 67.8191 1.55816 67.9365C1.59048 68.054 1.65081 68.1612 1.73368 68.2486C1.81654 68.3359 1.9193 68.4005 2.03262 68.4365Z"
        fill="#ECEEF0"
      />
      <path
        d="M27.0672 57.2422L25.5938 59.292"
        stroke="#22BCBC"
        strokeWidth="1.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7078 52.6211L14.2344 54.6604"
        stroke="#22BCBC"
        strokeWidth="1.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 61.3047L14.0091 64.5676L18.1245 62.5283"
        stroke="#52B748"
        strokeWidth="1.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5641 44.7194C69.4541 41.8852 63.9771 34.5541 61.9752 32.8912C60.1868 31.4166 57.8192 30.7996 56.1933 29.1367C56.0003 29.2727 56.9046 31.5316 56.9148 32.8703C56.9148 35.7672 55.5227 38.3713 56.0816 41.3728C56.3478 42.5749 56.8821 43.6967 57.6422 44.6496C58.4023 45.6026 59.3674 46.3604 60.4612 46.8633C63.154 48.0974 67.7572 47.8987 67.5641 44.7194Z"
        fill="#DCA174"
      />
      <path
        d="M61.3541 36.4297C61.4389 36.3457 61.5064 36.2451 61.5525 36.1336C61.5986 36.0222 61.6224 35.9024 61.6224 35.7813C61.6224 35.6602 61.5986 35.5403 61.5525 35.4289C61.5064 35.3175 61.4389 35.2168 61.3541 35.1329L39.2019 14.457L38.5312 15.1264L60.1449 36.4401C60.3076 36.6016 60.5254 36.691 60.7515 36.689C60.9776 36.6871 61.1939 36.594 61.3541 36.4297Z"
        fill="#49C2C9"
      />
      <path
        d="M44.1211 12.4803L46.4278 8.58984"
        stroke="#FFDC00"
        strokeWidth="1.88"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M38.1523 5.90234L39.0364 10.5353"
        stroke="#FFDC00"
        strokeWidth="1.88"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.6211 12.4766L35.149 13.6688"
        stroke="#FFDC00"
        strokeWidth="1.88"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M31.4961 21.5056L35.1441 19.4453"
        stroke="#FFDC00"
        strokeWidth="1.88"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ScheduledLogin;
