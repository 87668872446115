import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';
import { WalletValidatorFunction } from 'components/Core/WalletForms/validations/src/types';

export const validateField = (
  values: any,
  errors: any,
  field: string,
  validator: WalletValidatorFunction
) => {
  if (!values[field]) {
    errors[field] = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
  } else {
    const [valid, messages] = validator(values[field]);
    if (!valid && messages) {
      errors[field] = messages;
    }
  }
};
