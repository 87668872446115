import React, { ChangeEvent, useRef, useState } from 'react';
import { connect } from 'react-redux';

import ErrorAlertIcon from 'components/Shared/Icons/ErrorAlertIcon';
import { QuestionMark } from 'components/Shared/Icons/Icons';
import LabeledInput from 'components/Shared/Inputs/LabeledInput';
import CpPopover from 'components/Shared/Popover/CpPopover';
import {
  setContactDetailsEmail,
  setContactDetailsName,
  validateContactDetailsEmail,
  validateContactDetailsName,
} from 'store/payment/slice';
import { colors } from 'styles/cp';
import { EmailAddress } from 'types/utils';

export interface Props {
  setContactDetailsName: (value: string) => void;
  setContactDetailsEmail: (value: EmailAddress) => void;
  validateContactDetailsName: () => void;
  validateContactDetailsEmail: () => void;
  payment: { [key: string]: any };
  isContactDetailsNameValid: boolean;
  isContactDetailsEmailValid: boolean;
  shouldShowContactName?: boolean;
}

const ContactDetails: React.FC<Props> = ({
  setContactDetailsName,
  setContactDetailsEmail,
  isContactDetailsNameValid,
  isContactDetailsEmailValid,
  validateContactDetailsName,
  validateContactDetailsEmail,
  shouldShowContactName = true,
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const nameRef = useRef<string>('');
  const emailRef = useRef<string | null>(null);

  const onNameValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    nameRef.current = e.target.value;
  };

  const onEmailValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    emailRef.current = e.target.value;
  };

  const onNameBlur = () => {
    setContactDetailsName(nameRef.current);
    validateContactDetailsName();
  };

  const onEmailBlur = () => {
    if (emailRef.current) {
      setContactDetailsEmail(emailRef.current as EmailAddress);
      validateContactDetailsEmail();
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="header">
          <span className="contact-details">CONTACT DETAILS</span>
          <span
            id="description-tooltip"
            className="icon"
            onTouchStart={() => setIsTooltipOpen(true)}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          >
            <QuestionMark />
          </span>
          {isTooltipOpen && (
            <CpPopover
              innerClassName="cp-tooltip"
              placement="bottom"
              target="description-tooltip"
              isOpen={isTooltipOpen}
            >
              <span>
                Wondering why we need this? This info will help us send a payment confirmation once
                you’ve paid.
              </span>
            </CpPopover>
          )}
        </div>
        {shouldShowContactName && (
          <div>
            <LabeledInput
              label="Full name"
              onChange={onNameValueChange}
              onBlur={onNameBlur}
              isInError={!isContactDetailsNameValid}
            ></LabeledInput>
            {!isContactDetailsNameValid && (
              <div className="error-notice">
                <span className="error-icon">
                  <ErrorAlertIcon height={16} width={16} />
                </span>
                <span>Name must consist of at least 2 words.</span>
              </div>
            )}
          </div>
        )}
        <div>
          <LabeledInput
            label="Email"
            onChange={onEmailValueChange}
            onBlur={onEmailBlur}
            isInError={!isContactDetailsEmailValid}
          />
          {!isContactDetailsEmailValid && (
            <div className="error-notice">
              <span className="error-icon">
                <ErrorAlertIcon height={16} width={16} />
              </span>
              <span>Recheck the email formatting and try again.</span>
            </div>
          )}
        </div>
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .container {
          display: grid;
          grid-template-rows: 30px auto auto;
          grid-row-gap: 15px;

          .header {
            font-family: AvenirNextforINTUIT-Demi;
            text-transform: uppercase;
            font-size: 14px;
            display: flex;

            .contact-details {
              padding-right: 8px;
            }
          }

          .error-notice {
            padding-top: 8px;
            color: ${colors.darkError};
            font-size: 12px;
            display: flex;
            align-content: center;

            .error-icon {
              padding-right: 4px;
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

function mapStateToProps(state: any) {
  const {
    payment: { isContactDetailsNameValid, isContactDetailsEmailValid },
  } = state;

  return {
    isContactDetailsNameValid,
    isContactDetailsEmailValid,
  };
}

export { ContactDetails };

export default connect(mapStateToProps, {
  setContactDetailsName,
  setContactDetailsEmail,
  validateContactDetailsName,
  validateContactDetailsEmail,
})(ContactDetails);
