// @ts-nocheck

import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { isPaypalAppconnectDeclinedOrBlocked } from 'businessLogic/Payment/Common';
import { finalizeOrderOnPayPalCommerce } from 'businessLogic/Payment/Paypal';
import Spinner from 'components/Shared/Spinner/Spinner';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { isPayPalAppConnectBlock } from 'shared/clientUtils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { createOrderOnPayPalCommerce } from 'store/payment/payPal';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'sections/PayPalAppConnectButton';
import {
  onFailedPayment,
  onSuccessfulPayment,
  walletTokenizationPaymentCreation,
  paymentActions,
} from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { PayPalOrderIds } from 'types/BusinessLogic';
import { Config } from 'types/Config';
import { Payment } from 'types/Payment';
import { TXN_MAP } from 'types/constants';

type PayPalButtonProps = {
  invoiceDocument: object;
  auth: object;
  customerName: string;
  domainId: string;
  token: string;
  companyLocale: string;
  invoiceId: string;
  currency: string;
  isFullyPaid: boolean;
  isPartiallyPaid: boolean;
  openInvoices: any[];
  balanceAmount: number;
  config: Config;
  payment: Pick<Payment, 'amount' | 'paymentStatus' | 'payPalProcessor' | 'paymentMethodType'>;
  wallet: object;
  featureFlags: Record<string, any>;
  finalizeOrderOnPayPalCommerce?: (...args: any[]) => any;
  createOrderOnPayPalCommerce?: (...args: any[]) => any;
  stopPayment: () => void;
  onFailedPayment: Function;
  createPayment: () => void;
  onSuccessfulPayment: Function;
};
class PayPalAppConnectButton extends React.Component<PayPalButtonProps, {}> {
  componentDidMount() {
    const {
      domainId,
      customerName,
      token,
      companyLocale,
      invoiceId,
      currency,
      isFullyPaid,
      isPartiallyPaid,
      openInvoices,
      balanceAmount,
      payment,
      auth,
      config,
      wallet,
    } = this.props;
    this.payPalButtonRender({
      domainId,
      token,
      companyLocale,
      customerName,
      invoiceId,
      currency,
      isFullyPaid,
      isPartiallyPaid,
      openInvoices,
      balanceAmount,
      payment,
      auth,
      config,
      wallet,
    });
  }
  componentDidUpdate(prevProps: { payment: Pick<Payment, 'amount'> }) {
    if (
      prevProps.payment.amount !== this.props.payment.amount ||
      this.props.payment.paymentStatus === TXN_MAP.STATUS.ERROR
    ) {
      if (this.paypalbutton) {
        this.paypalbutton.innerHTML = null;
      }
      const {
        domainId,
        token,
        companyLocale,
        invoiceId,
        currency,
        isFullyPaid,
        isPartiallyPaid,
        customerName,
        featureFlags,
        openInvoices,
        balanceAmount,
        payment,
        auth,
        config,
        wallet,
      } = this.props;
      this.payPalButtonRender({
        domainId,
        token,
        companyLocale,
        invoiceId,
        currency,
        isFullyPaid,
        isPartiallyPaid,
        customerName,
        featureFlags,
        openInvoices,
        balanceAmount,
        payment,
        auth,
        config,
        wallet,
      });
    }
  }
  payPalButtonRender(paymentValues) {
    const {
      featureFlags,
      payment: { isAmountValid },
    } = this.props;
    const context = this;
    window.paypal &&
      window.paypal
        .Buttons({
          enableStandardCardFields: true,
          style: {
            layout: 'vertical',
            color: 'gold',
            shape: 'pill',
            label: 'pay',
          },
          onInit: async (_, actions) => {
            if (!isAmountValid) {
              actions.disable();
            }
          },
          createOrder: () => {
            return createOrderOnPayPalCommerce(paymentValues)
              .then((res) => {
                return res;
              })
              .catch((error) => {
                context.props.stopPayment();
                context.props.onFailedPayment(error, featureFlags);
                const { invoiceInfo } = error;
                splunkReporter.contextual({
                  logInfo: { logLevel: 'error', logger },
                  event: 'pay',
                  action: 'createOrder',
                  activityInfo: {
                    status: 'error',
                    paymentMethodType: 'paypalCommerce',
                    invoiceInfo,
                  },
                  error: {
                    message: error?.message,
                    stack: error?.stack,
                  },
                });
              });
          },
          // Finalize the transaction
          onApprove: (data: PayPalOrderIds) => {
            // showing the spinner while payment is created on paypal side
            context.props.createPayment();
            return finalizeOrderOnPayPalCommerce(data, paymentValues, featureFlags)
              .then((res) => {
                context.props.onSuccessfulPayment({ result: res });
                return res;
              })
              .catch((error) => {
                context.props.stopPayment();
                context.props.onFailedPayment(error, featureFlags);
                const { invoiceInfo } = error;
                splunkReporter.contextual({
                  logInfo: { logLevel: 'error', logger },
                  event: 'pay',
                  action: 'onApprove',
                  activityInfo: {
                    status: 'error',
                    paymentMethodType: 'paypalCommerce',
                    invoiceInfo,
                  },
                  error: {
                    message: error?.message,
                    stack: error?.stack,
                  },
                });
              });
          },
          onError: (err) => {
            context.props.stopPayment();
            context.props.onFailedPayment(err, featureFlags);
            splunkReporter.contextual({
              logInfo: { logLevel: 'error', logger },
              event: 'pay',
              action: 'general',
              activityInfo: {
                status: 'error',
                paymentMethodType: 'paypalCommerce',
              },
              error: {
                message: err?.message,
                stack: err?.stack,
              },
            });
          },
          onCancel: () => {
            try {
              context.props.stopPayment();
            } catch (error) {
              context.props.onFailedPayment(error, featureFlags);
            }
          },
        })
        .render('#paypal-button-container')
        .catch((e) => {
          const knownErrors = ['Detected container element removed from DOM', 'Component closed'];
          if (!knownErrors.includes(e.message)) {
            splunkReporter.contextual({
              logInfo: { logLevel: 'error', logger },
              event: 'pay',
              action: 'general',
              activityInfo: {
                status: 'error',
                paymentMethodType: 'paypalCommerce',
              },
              error: {
                message: e?.message,
                stack: e?.stack,
              },
            });
          }
        });
  }
  render() {
    if (this.props.payment.paymentStatus === TXN_MAP.STATUS.IN_PROGRESS) {
      return (
        <Fragment>
          <style jsx>{`
            .spinner-wrapper {
              width: 100px;
              margin: 50px auto;
            }
          `}</style>
          <div className="spinner-wrapper">
            <Spinner width={100} />
          </div>
        </Fragment>
      );
    }
    const {
      payment: { paymentStatus, payPalProcessor, paymentMethodType },
      featureFlags,
    } = this.props;
    const isBlockPaypalAppconnectWithError = isPaypalAppconnectDeclinedOrBlocked(
      this.props.featureFlags,
      paymentStatus,
      payPalProcessor
    );
    const isBlockPayments =
      featureFlags &&
      (featureFlags['block-payments'] ||
        isPayPalAppConnectBlock(paymentMethodType, payPalProcessor, featureFlags) ||
        isBlockPaypalAppconnectWithError);
    return (
      <div
        id="paypal-button-container"
        ref={(ref) => (this.paypalbutton = ref)}
        hidden={isBlockPayments}
      />
    );
  }
}
function mapStateToProps(store) {
  const {
    invoiceDocument,
    sale,
    insight,
    companyInfo,
    config,
    payment,
    wallet,
    auth,
    featureFlags,
  } = store;
  return {
    domainId: saleSelectors.domainIdSelector(sale, auth),
    token: insightSelectors.tokenSelector(insight),
    companyLocale: companyInfoSelectors.localeSelector(companyInfo),
    invoiceId: saleSelectors.localIdSelector(sale),
    currency: saleSelectors.currencySelector(sale),
    isFullyPaid: insightSelectors.isFullyPaidSelector(insight),
    isPartiallyPaid: insightSelectors.isPartiallyPaidSelector(insight),
    openInvoices: insightSelectors.openInvoicesSelector(insight),
    customerName: saleSelectors.customerNameSelector(sale),
    balanceAmount: saleSelectors.balanceSelector(sale),
    auth,
    invoiceDocument,
    config,
    payment,
    wallet,
    featureFlags,
  };
}
export default injectIntl(
  connect(mapStateToProps, {
    onFailedPayment,
    stopPayment: paymentActions.cancelPayment,
    onSuccessfulPayment,
    createPayment: walletTokenizationPaymentCreation,
  })(PayPalAppConnectButton)
);
