// @ts-nocheck

import debugCreator from 'debug';
import React, { Component } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import UpdateBase from 'components/Core/Payment/UpdateBase/UpdateBase';
import { CardField, UpdateCardForm } from 'components/Core/WalletForms/src/UpdateCardForm';
import { getPaymentMethodBug } from 'components/Shared/Icons/Icons';
import LabeledSwitch from 'components/Shared/Inputs/LabeledSwitch';
import ValidatedLabeledInput from 'components/Shared/Inputs/ValidatedLabeledInput';
import MessageCard from 'components/Shared/MessageCard/MessageCard';
import { formErrorTranslator } from 'store/wallet/errorTranslators';
import { cardHelpers } from 'store/wallet/helpers';
import { updateWallet, updateWalletCancel } from 'store/wallet/slice';
import { colors, fontSize, breakpoints } from 'styles/cp';
import { Config } from 'types/Config';
import { UserWallets, Wallet } from 'types/Wallet';
import { TXN_MAP } from 'types/constants';
import { AddressComponents, Nullable } from 'types/utils';

const debug = debugCreator('CardUpdate');

type Props = {
  hasFixedExpDateFormat: boolean;
};
type formValuesProps = {
  expMonth?: string | number;
  expYear?: string | number;
  name?: string;
  address?: AddressComponents;
  default?: boolean;
  updateWallet: Function;
  cancelUpdateWallet: Function;
  userWallets: UserWallets;
  cdn?: string;
  intl: IntlShape;
  currentlyUpdatingWalletId: Nullable<number>;
  updateWalletStatus: string;
};

export class CardUpdate extends Component<formValuesProps, Props> {
  constructor(props: formValuesProps) {
    super(props);
    debug('constructor');
    const methodsToBind = ['handleFormSubmit', 'handleFormCancel'];
    methodsToBind.forEach((methodName) => (this[methodName] = this[methodName].bind(this)));
    this.state = {
      hasFixedExpDateFormat: false,
    };
    this.errorTranslator = formErrorTranslator();
  }
  getCardInWalletSchema() {
    const cardValues = this.getCard();
    const { cardNumber, payorFirstName, postalCode, expMonth, expYear } = cardValues;
    let convertedExpMonth = expMonth.toString();
    if (typeof convertedExpMonth === 'string' && convertedExpMonth.length === 1) {
      convertedExpMonth = `0${convertedExpMonth}`;
    }
    return {
      name: payorFirstName,
      number: cardNumber,
      expMonth: convertedExpMonth,
      expYear: expYear.toString(),
      default: cardValues?.default,
      address: {
        addressComponents: [
          {
            name: 'postalCode',
            value: postalCode,
          },
        ],
      },
    };
  }
  getCardValuesInCpServerSchema(formValues: formValuesProps) {
    const initialValues = this.getCard();
    const newValues = {
      ...initialValues,
    };
    if (formValues.expMonth) {
      newValues.expMonth = formValues.expMonth.toString();
    }
    if (formValues.expMonth) {
      newValues.expYear = formValues.expYear?.toString();
    }
    if (formValues.name) {
      newValues.payorFirstName = formValues.name;
    }
    if (formValues.address) {
      if (formValues.address.addressComponents) {
        const components = formValues.address.addressComponents;
        // This is an array of { name: string, value: any }. Iterate and take what you need
        for (let comp of components) {
          const { name, value } = comp || {};
          if (name === 'postalCode') {
            newValues.postalCode = value;
          }
        }
      }
    }
    if (formValues.hasOwnProperty('default')) {
      newValues.default = formValues.default;
    }
    return newValues;
  }
  handleFormSubmit(
    values: formValuesProps,
    walletFormikBag: {
      setSubmitting: Function;
    }
  ) {
    const { setSubmitting } = walletFormikBag;
    setSubmitting(true);
    const translatedCard = this.getCardValuesInCpServerSchema(values);
    this.props.updateWallet({
      ccDetails: {
        ...translatedCard,
        id: translatedCard.id,
        paymentProcessor: '',
        depositDisabled: true,
        invoiceBalance: 0,
      },
    });
  }
  handleFormCancel() {
    this.props.cancelUpdateWallet();
  }
  getCard() {
    return this.props.userWallets.find((card) => card.id === this.props.currentlyUpdatingWalletId);
  }
  render() {
    const { cdn, intl, updateWalletStatus } = this.props;
    const card = this.getCard();
    const initialValues = this.getCardInWalletSchema();
    const placeHolderName = intl.formatMessage({
      id: 'PAYFLOW_PLACEHOLDERS_FIRST_LAST_NAME',
      defaultMessage: 'First name Last name',
    });
    return (
      <div className="w">
        <div className="update-cont">
          {
            // Warning is the design we use to show to the user
            updateWalletStatus === TXN_MAP.STATUS.ERROR && (
              <MessageCard status={'warn'} text="WALLET_UPDATE_ERROR" />
            )
          }
          <UpdateCardForm
            handleSubmit={this.handleFormSubmit}
            initialValues={initialValues}
            id={this.props.currentlyUpdatingWalletId}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({
              isValidating,
              isSubmitting,
              setSubmitting,
              enumErrors,
              touched,
              values,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            }) => {
              // Tell Redux to stop that BankUpdateForm found errors and thus stopped the payment in progress.
              if (
                isSubmitting &&
                (updateWalletStatus === TXN_MAP.STATUS.ERROR ||
                  (!isValidating && Object.keys(enumErrors).length > 0))
              ) {
                setSubmitting(false);
              }
              const onCardFieldFocus = (_field: { name: string; onFocus?: Function }) => {
                return (e: React.FocusEvent<HTMLInputElement>) => {
                  // For tests this is necessary
                  if (!e.target.name) {
                    e.target.name = _field.name;
                  }
                  _field.onFocus && _field.onFocus(e);
                };
              };
              const onCardFieldChange = (_field: { name: string; onChange: Function }) => {
                return (e: React.ChangeEvent<HTMLInputElement>) => {
                  // For tests this is necessary
                  if (!e.target.name) {
                    e.target.name = _field.name;
                  }
                  // Respect the rest of the event propagation
                  _field.onChange && _field.onChange(e);
                  // Only clear the errors from the field if the value has changed.
                  if (values[_field.name] !== e.target.value) {
                    setFieldError(_field.name, null);
                  }
                };
              };
              const onCardFieldBlur = (_field: { name: string; onBlur: Function }) => {
                return (e: React.FocusEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  // For tests this is necessary
                  if (!e.target.name) {
                    e.target.name = _field.name;
                  }
                  _field.onBlur && _field.onBlur(e);
                  // If the value is empty then consider this field untouched.
                  setFieldTouched(_field.name, value !== null && value !== '');
                };
              };
              return (
                <UpdateBase
                  headerId="SETTINGS_EDIT_PAYMENT_METHOD"
                  isSubmitting={isSubmitting}
                  handleFormCancel={this.handleFormCancel}
                >
                  <div className="r">
                    <label>
                      <FormattedMessage id="PAYFORM_CARD_NUMBER" defaultMessage="Card number" />
                    </label>
                    <div className="card-rep">
                      <span>
                        {getPaymentMethodBug(card.cardType, cdn, this.props.intl.formatMessage)}
                      </span>
                      <span className="c-number">{`...${card.cardNumber.slice(-4)}`}</span>
                    </div>
                  </div>

                  <div className="r">
                    <CardField name="name">
                      {({ field }) => (
                        <>
                          <ValidatedLabeledInput
                            {...field}
                            translator={this.errorTranslator}
                            validationError={
                              touched[field.name] &&
                              enumErrors[field.name] &&
                              enumErrors[field.name][0]
                            }
                            onBlur={onCardFieldBlur(field)}
                            onChange={onCardFieldChange(field)}
                            onFocus={onCardFieldFocus(field)}
                            disabled={isSubmitting}
                            label={
                              <FormattedMessage
                                id="PAYFORM_NAME_ON_CARD"
                                defaultMessage="Name on card"
                              />
                            }
                            type="text"
                            maxLength="50"
                            placeholder={placeHolderName}
                          />
                        </>
                      )}
                    </CardField>
                  </div>

                  <div className="r">
                    <div className="r-left">
                      <CardField name="expDate">
                        {({ field }) => (
                          <>
                            <ValidatedLabeledInput
                              {...field}
                              value={cardHelpers.formatExpirationDate(values['expDate'])}
                              translator={this.errorTranslator}
                              validationError={
                                touched[field.name] &&
                                enumErrors[field.name] &&
                                enumErrors[field.name][0]
                              }
                              onFocus={onCardFieldFocus(field)}
                              onChange={onCardFieldChange(field)}
                              onBlur={onCardFieldBlur(field)}
                              disabled={isSubmitting}
                              label={
                                <FormattedMessage
                                  id="PAYFORM_CARD_EXP_DATE"
                                  defaultMessage="Exp date"
                                />
                              }
                              type="text"
                              inputMode="decimal"
                              pattern="[0-9\/]*"
                              maxLength="5"
                              dir="ltr"
                              placeholder="MM/YY"
                            />
                          </>
                        )}
                      </CardField>
                    </div>

                    <div className="r-right">
                      <CardField name="zipCode">
                        {({ field }) => (
                          <>
                            <ValidatedLabeledInput
                              {...field}
                              translator={this.errorTranslator}
                              validationError={
                                touched[field.name] &&
                                enumErrors[field.name] &&
                                enumErrors[field.name][0]
                              }
                              onFocus={onCardFieldFocus(field)}
                              onChange={onCardFieldChange(field)}
                              onBlur={onCardFieldBlur(field)}
                              disabled={isSubmitting}
                              label={
                                <FormattedMessage
                                  id="PAYFLOW_CARD_POSTAL_CODE"
                                  defaultMessage="Postal code"
                                />
                              }
                              inputMode="numeric"
                              maxLength="9"
                              dir="ltr"
                              placeholder="12345"
                            />
                          </>
                        )}
                      </CardField>
                    </div>
                  </div>

                  <CardField name="default">
                    {({ field }) => (
                      <LabeledSwitch
                        {...field}
                        checked={values.default}
                        disabled={isSubmitting}
                        label={intl.formatMessage({
                          id: 'PAYFLOW_SAVE_AS_DEFAULT',
                          defaultMessage: 'Save as default',
                        })}
                        hint={
                          values.default &&
                          intl.formatMessage({
                            id: 'PAYFORM_DEFAULT_PAYMENT_METHOD',
                            defaultMessage: 'This is your default payment method',
                          })
                        }
                        onChange={() => setFieldValue('default', !values.default)}
                      />
                    )}
                  </CardField>
                </UpdateBase>
              );
            }}
          </UpdateCardForm>
        </div>

        <style jsx>{`
          .w {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-direction: column;
          }

          .update-cont {
            border-radius: 4px;
            max-width: 100%;
            width: 410px;
            background-color: ${colors.white};
            padding: 30px;

            @media screen and (max-width: ${breakpoints.md}) {
              width: 330px;
              padding: 15px;
            }

            .r {
              margin-bottom: 15px;

              :global(label),
              label {
                font-size: ${fontSize.xs};
                margin-bottom: 5px;
                font-family: AvenirNextforINTUIT-Demi;
              }

              .card-rep {
                span {
                  font-size: ${fontSize.xs};
                  color: ${colors.gray};
                  font-family: AvenirNextforINTUIT-Medium;
                  display: inline-block;
                  vertical-align: middle;
                }

                .c-number {
                  margin-left: 2px;
                }
              }

              .r-left {
                width: 50%;
                display: inline-block;
                padding-right: 10px;
              }

              .r-right {
                float: right;
                width: 50%;
                display: inline-block;
                padding-left: 10px;
              }
            }
          }
        `}</style>
      </div>
    );
  }
}

const mapStateToProps = ({ wallet, config }: { wallet: Wallet; config: Config }) => {
  const { userWallets, currentlyUpdatingWalletId, updateWalletStatus, walletError } = wallet;
  return {
    userWallets,
    currentlyUpdatingWalletId,
    updateWalletStatus,
    walletError,
    cdn: config.endpoints.cdn,
  };
};

export default connect(mapStateToProps, {
  updateWallet,
  cancelUpdateWallet: updateWalletCancel,
})(injectIntl(CardUpdate));
