import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const Recurring: React.FC<IconBasePropsWithColor> = ({
  width = 43,
  height = 44,
  color = '#6B6C72',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.013 11.013C20.565 11.014 21.012 11.462 21.011 12.015C21.003 16.972 16.967 21 12.012 21H11.997C9.232 20.997 6.691 19.726 5.002 17.617L5 18.99C4.999 19.542 4.552 19.989 4 19.989C3.447 19.988 2.999 19.539 3 18.988L3.006 14.988C3.007 14.435 3.454 13.989 4.006 13.989H4.007L8.007 13.994C8.56 13.995 9.007 14.444 9.006 14.995C9.005 15.548 8.558 15.994 8.006 15.994H8.004L6.265 15.992C7.568 17.867 9.689 18.997 12 19H12.012C15.867 19 19.005 15.867 19.011 12.011C19.012 11.459 19.459 11.013 20.011 11.013H20.013ZM12.0237 3C14.8047 3.004 17.3377 4.275 19.0197 6.385L19.0217 5.012C19.0227 4.459 19.4697 4.013 20.0217 4.013C20.5757 4.014 21.0227 4.462 21.0217 5.014L21.0157 9.014C21.0147 9.567 20.5677 10.013 20.0157 10.013H20.0147L16.0147 10.007C15.4617 10.006 15.0147 9.558 15.0157 9.006C15.0167 8.453 15.4637 8.007 16.0157 8.007H16.0177L17.7577 8.009C16.4637 6.136 14.3467 5.003 12.0207 5C8.1567 5 5.0167 8.134 5.0107 11.991C5.0097 12.542 4.5627 12.989 4.0107 12.989C3.4577 12.988 3.0097 12.539 3.0107 11.987C3.0187 7.03 7.0557 3 12.0117 3H12.0237Z"
        fill={color}
      />
    </svg>
  );
};

export default Recurring;
