import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Shared/Button/Button';
import Hr from 'components/Shared/Hr/Hr';
import Spinner from 'components/Shared/Spinner/Spinner';
import { colors, fontSize } from 'styles/cp';

export interface Props {
  headerId: string;
  isSubmitting: boolean;
  handleFormCancel: () => void;
  children: any;
}

const UpdateBase: React.FC<Props> = ({ headerId, isSubmitting, handleFormCancel, children }) => (
  <Fragment>
    <span className="h">
      <FormattedMessage id={headerId} defaultMessage="Edit payment method" />
    </span>

    <Hr marginTop={10} />

    {children}

    <div className="a-w">
      <div className="btn">
        <Button
          data-cy="UpdateBase_Cancel"
          size="responsive"
          width="100%"
          onClick={handleFormCancel}
          aria-label="Cancel"
        >
          Cancel
        </Button>
      </div>
      <div className="btn">
        <Button
          data-cy="UpdateBase_Save"
          size="responsive"
          width="100%"
          buttonType="primary"
          disabled={isSubmitting}
          aria-label="save"
          onClick={() => {}}
        >
          {isSubmitting && (
            <Spinner
              color={colors.green}
              bgStrokeWidth={2.5}
              width={25}
              strokeWidth={5}
              bgColor={colors.gray08}
              aria-label="spinner"
            />
          )}
          {!isSubmitting && <span>Save</span>}
        </Button>
      </div>
    </div>

    <style jsx>{`
      .h {
        font-size: ${fontSize.md};
        color: ${colors.gray};
        font-family: AvenirNextforINTUIT-Bold;
      }

      .a-w {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 15px;
      }

      .btn {
        margin-top: 10px;
        flex: 1;
        :first-child {
          margin-right: 10px;
        }
      }
    `}</style>
  </Fragment>
);

export default UpdateBase;
