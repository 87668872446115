import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

const Close: React.FC<IconBasePropsWithColor> = ({
  width = 12,
  height = 12,
  color = '#8D9096',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Path</title>
      <desc>Created with Sketch.</desc>
      <g id="Toast-message" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-302.000000, -207.000000)"
          fill={color}
          fillRule="nonzero"
          id="Path"
        >
          <path d="M308,211.761273 L303.495276,207.256549 C303.153211,206.914484 302.598614,206.914484 302.256549,207.256549 C301.914484,207.598614 301.914484,208.153211 302.256549,208.495276 L306.761273,213 L302.256549,217.504724 C301.914484,217.846789 301.914484,218.401386 302.256549,218.743451 C302.598614,219.085516 303.153211,219.085516 303.495276,218.743451 L308,214.238727 L312.504724,218.743451 C312.846789,219.085516 313.401386,219.085516 313.743451,218.743451 C314.085516,218.401386 314.085516,217.846789 313.743451,217.504724 L309.238727,213 L313.743451,208.495276 C314.085516,208.153211 314.085516,207.598614 313.743451,207.256549 C313.401386,206.914484 312.846789,206.914484 312.504724,207.256549 L308,211.761273 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default Close;
