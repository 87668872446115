/* eslint-disable */
import { ValidationErrorEnum } from './ValidationErrorEnum';
import { BankAccountTypeEnumInput, ValidationError, WalletValidatorFunction } from './types';

export const nameValidator: WalletValidatorFunction = (
  name: any,
  accountType: any = BankAccountTypeEnumInput.PERSONAL_CHECKING
) => {
  const errors: ValidationError[] = [];
  if (typeof name !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    const isPersonalAccount =
      accountType === BankAccountTypeEnumInput.PERSONAL_CHECKING ||
      accountType === BankAccountTypeEnumInput.PERSONAL_SAVINGS;

    const nameCommaRegex = /^[\w]+\,[ ]*[\w]+.*$/,
      nameSpaceRegex = /^[\w]+[ ]+[\w]+.*$/,
      regexCheck =
        (isPersonalAccount && !nameCommaRegex.test(name) && !nameSpaceRegex.test(name)) ||
        (!isPersonalAccount && !/.+/.test(name));

    if (regexCheck) {
      errors.push(ValidationErrorEnum.INVALID_FIRST_AND_LAST_NAME);
    }
  }

  return [errors.length === 0, errors];
};