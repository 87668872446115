import { ValidationErrorEnum } from './ValidationErrorEnum';
import { BankAccountTypeEnumInput, ValidationError, WalletValidatorFunction } from './types';
import Utils from './utils';

export const accountTypeValidator: WalletValidatorFunction = (accountType: any) => {
  const errors: ValidationError[] = [];

  if (!Utils.objectValues(BankAccountTypeEnumInput).includes(accountType)) {
    errors.push(ValidationErrorEnum.INVALID_ACCOUNT_TYPE);
  }

  return [errors.length === 0, errors];
};
