import { ICardCreateData } from 'components/Core/WalletForms/network/src/types';
import { CCDataQuery_node_Payments_WalletCard_card_address } from 'components/Core/WalletForms/queries/transformed/lib/schema';
import {
  CreateCardErrors,
  CreateCardFormikBag,
  ICardCreateFormData,
  ICreateCardFormProps,
} from 'components/Core/WalletForms/src/CreateCardForm';
import {
  CreateCardTokenErrors,
  CreateCardTokenFormikBag,
  ICardTokenCreateFormData,
  ICreateCardTokenFormProps,
} from 'components/Core/WalletForms/src/CreateCardTokenForm';
import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';
import { cardNumberValidator } from 'components/Core/WalletForms/validations/src/cardNumber';
import { cvcValidator } from 'components/Core/WalletForms/validations/src/cvc';
import {
  expDateValidator,
  expMonthValidator,
  expYearValidator,
  getMonthAndYearFromExpDate,
} from 'components/Core/WalletForms/validations/src/expDate';
import { nameValidator } from 'components/Core/WalletForms/validations/src/name';
import { zipCodeValidator } from 'components/Core/WalletForms/validations/src/zipCode';

type CardResponseAddress = CCDataQuery_node_Payments_WalletCard_card_address | null;

const CardUtils = {
  getInitialFormValues({
    initialValues,
  }: ICreateCardFormProps | ICreateCardTokenFormProps):
    | ICardCreateFormData
    | ICardTokenCreateFormData {
    return {
      number: '',
      cvc: '',
      expDate: '',
      expMonth: '',
      expYear: '',
      name: '',
      zipCode: '',
      cardType: null,
      default: false,
      ...initialValues,
    };
  },

  validate(
    values: ICardCreateFormData | ICardTokenCreateFormData,
    _props: ICreateCardFormProps | ICreateCardTokenFormProps
  ): CreateCardErrors | CreateCardTokenErrors {
    const errors: CreateCardTokenErrors | CreateCardErrors = {};

    if (!values.number) {
      errors.number = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = cardNumberValidator(values.number, _props.allowedCardTypes);
      if (!valid && messages) {
        errors.number = messages;
      }
    }

    if (!values.cvc) {
      errors.cvc = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = cvcValidator(values.cvc, { cardType: values.cardType });
      if (!valid && messages) {
        errors.cvc = messages;
      }
    }

    // Expiration Date, Month, Year - complicated.
    // The form may be showing expiration date (expDate).
    // Or it maybe showing expiration month (expMonth) and year (expYear).
    // Determine which one it is by process of elimination
    if (!values.expDate && !values.expMonth && !values.expYear) {
      errors.expDate = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
      errors.expYear = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
      errors.expMonth = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else if (values.expDate) {
      // User is interacting with the expDate field
      const [valid, messages] = expDateValidator(values.expDate);
      if (!valid && messages) {
        errors.expDate = messages;
      }
    } else {
      // User is interacting with the expMonth and expYear fields.
      const [expYearValid, expYearMessages] = expYearValidator(values.expYear);

      if (!expYearValid && expYearMessages) {
        errors.expYear = expYearMessages;
      }

      const [expMonthValid, expMonthMessages] = expMonthValidator(values.expMonth);

      if (!expMonthValid && expMonthMessages) {
        errors.expMonth = expMonthMessages;
      }

      if (
        expYearMessages.indexOf(ValidationErrorEnum.WARNING_EXPIRATION_IN_CURRENT_YEAR) > -1 &&
        expMonthMessages.indexOf(ValidationErrorEnum.WARNING_INVALID_MONTH_IN_CURRENT_YEAR) > -1
      ) {
        errors.expMonth = [ValidationErrorEnum.INVALID_EXP_DATE];
        errors.expYear = [ValidationErrorEnum.INVALID_EXP_DATE];
      }
    }

    if (!values.zipCode) {
      errors.zipCode = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = zipCodeValidator(values.zipCode, _props.zipCodeLength);
      if (!valid && messages) {
        errors.zipCode = messages;
      }
    }

    if (!values.name) {
      errors.name = [ValidationErrorEnum.REQUIRED_ERROR_MESSAGE];
    } else {
      const [valid, messages] = nameValidator(values.name);
      if (!valid && messages) {
        errors.name = messages;
      }
    }

    return errors;
  },

  handleSubmit(
    values: ICardCreateFormData | ICardTokenCreateFormData,
    formikBag: CreateCardFormikBag | CreateCardTokenFormikBag
  ): void {
    const newValues: ICardCreateData = {
      number: values.number,
      cardType: values.cardType,
      name: values.name,
      expYear: CardUtils.ensure4DigitYear(values.expYear as string),
      expMonth: values.expMonth,
      address: {
        addressComponents: [
          {
            name: 'postalCode',
            value: values.zipCode,
          },
        ],
      },
      cvc: values.cvc,
      default: values.default || false,
    };

    formikBag.props.handleSubmit(newValues, formikBag);
  },

  getExpMonthAndYearFromExpDate(expDate: string): { expMonth: string; expYear: string } {
    return getMonthAndYearFromExpDate(expDate);
  },

  getExpDateFromExpMonthAndYear(expMonth: string | null, expYear: string | null): string {
    let expDate: string;

    if (typeof expMonth === 'string') {
      expDate = expMonth;
    } else {
      expDate = new Date().getMonth().toString();
    }

    if (typeof expYear === 'string') {
      if (expYear.length === 4) {
        expDate = `${expDate}${expYear.slice(-2)}`;
      } else {
        expDate += expYear;
      }
    }

    return expDate;
  },

  getZipCodeFromAddress(address: CardResponseAddress | null): string | null {
    if (address !== null) {
      if (address.addressComponents !== null) {
        const zipObj = address.addressComponents.find((comp) => {
          if (comp !== null) {
            return comp.name === 'postalCode';
          }
          return false;
        });

        if (zipObj) {
          return zipObj.value;
        }
      }
    }

    return null;
  },

  ensure4DigitYear(year: string) {
    if (year.length !== 4) {
      if (year.length !== 2) {
        throw Error('Invalid year ' + year);
      }
      year = `${new Date().getFullYear().toString().slice(0, 2)}${year}`;
    }
    return year;
  },
};

export default CardUtils;
