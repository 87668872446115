// @ts-nocheck

import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { isWalletTypeOfBank } from 'businessLogic/Wallet/helpers';
import BankForm from 'components/Core/Payment/PaymentForm/Bank/BankForm';
import PaymentItem from 'components/Core/Payment/PaymentItem/PaymentItem';
import Radio from 'components/Shared/Inputs/Radio';
import FormDraftProvider from 'contexts/FormDraftProvider';
import SegmentIO from 'reporting/SegmentIO';
import { getDefaultWalletFromList } from 'store/wallet/helpers';
import { REMOTE_BUTTON_ACCESS_FUNCTIONALITY } from 'store/wallet/slice';
import { colors, fontSize } from 'styles/cp';
import { Config } from 'types/Config';
import { HideModalFunc, ShowModalFunc } from 'types/Modal';
import { Payment } from 'types/Payment';
import { Wallet } from 'types/Wallet';

type BankTransferProps = {
  payment: Pick<Payment, 'paymentStatus' | 'paymentMethodType'>;
  wallet: Pick<Wallet, 'userWallets' | 'selectedWalletId' | 'bankCreatePaymentTrigger'>;
  config: Pick<Config, 'endpoints'>;
  createPayment: (...args: any[]) => any;
  stopPayment: (...args: any[]) => any;
  bindFormSubmission: (...args: any[]) => any;
  changeSelectedBank: (...args: any[]) => any;
  onDeletePaymentMethodClicked: (...args: any[]) => any;
  onUpdatePaymentMethodClicked: (...args: any[]) => any;
  showModal: ShowModalFunc;
  hideModal: HideModalFunc;
};

type BankTransferState = {
  isAddBankHovered: boolean;
};

class BankTransfer extends Component<BankTransferProps, BankTransferState> {
  constructor(props: BankTransferProps) {
    super(props);
    this.state = {
      isAddBankHovered: false,
    };
    const methodsToBind = ['handleAddBankMouseEnter', 'handleAddBankMouseLeave'];
    methodsToBind.forEach((methodName) => (this[methodName] = this[methodName].bind(this)));
  }
  componentDidMount() {
    const { changeSelectedBank, wallet } = this.props;
    const { userWallets = [] } = wallet;
    const bankAccounts =
      userWallets && userWallets.filter((wallet) => isWalletTypeOfBank(wallet.walletType));
    if (bankAccounts && Array.isArray(bankAccounts) && bankAccounts.length > 0) {
      const defaultBank = getDefaultWalletFromList(bankAccounts);
      changeSelectedBank(defaultBank);
    } else {
      changeSelectedBank({
        id: 'AddBank',
        walletType: 'bank',
      });
    }
  }
  handleAddBankMouseEnter() {
    this.setState({ isAddBankHovered: true });
  }
  handleAddBankMouseLeave() {
    this.setState({ isAddBankHovered: false });
  }
  render() {
    const { isAddBankHovered } = this.state;
    const {
      wallet,
      payment,
      config,
      changeSelectedBank,
      bindFormSubmission,
      createPayment,
      stopPayment,
      onDeletePaymentMethodClicked,
      onUpdatePaymentMethodClicked,
      showModal,
      hideModal,
    } = this.props;
    const {
      userWallets = [],
      selectedWalletId = '',
      [REMOTE_BUTTON_ACCESS_FUNCTIONALITY.BANK_CREATE_PAYMENT_TRIGGER]: boundCreateBankFn,
    } = wallet;
    const bankAccounts =
      userWallets && userWallets.filter((wallet) => isWalletTypeOfBank(wallet.walletType));
    const eftFormAdditionalProps = {
      showModal,
      hideModal,
    };
    const bankFormProps = {
      paymentStatus: payment.paymentStatus,
      createPayment,
      stopPayment,
      boundCreateBankFn,
      bindFormSubmission,
      endpoints: config.endpoints,
      ...(payment.paymentMethodType === 'eft' && eftFormAdditionalProps),
    };
    const addBankAccount = {
      id: 'AddBank',
      walletType: 'bank',
    };
    const showBankList = bankAccounts && Array.isArray(bankAccounts) && bankAccounts.length > 0;
    const showBankForm = !showBankList || selectedWalletId === addBankAccount.id;
    const onAddBankClicked = () => {
      SegmentIO.transactionEngaged({
        activity_type: 'wallet',
        ui_action: 'enabled',
        ui_object: 'radio_button',
        ui_object_detail: 'add_bank',
        ui_access_point: 'transaction_flow',
      });
      changeSelectedBank(addBankAccount);
    };
    // @ts-ignore
    const onChangeSelectedBank = (item) => {
      SegmentIO.transactionEngaged({
        activity_type: 'wallet',
        ui_action: 'enabled',
        ui_object: 'radio_button',
        ui_object_detail: 'saved_wallet_selected',
        ui_access_point: 'transaction_flow',
      });
      changeSelectedBank(item);
    };
    return (
      <Fragment>
        {showBankList && (
          <Fragment>
            <span className="payment-items-header">
              <FormattedMessage
                id="PAYFORM_SELECT_PAYMENT_METHOD"
                defaultMessage="Select payment method"
              />
            </span>
            <div className="payment-items-container">
              {bankAccounts.map((item) => (
                <PaymentItem
                  itemType="bank"
                  item={item}
                  cdn={config.endpoints.cdn}
                  key={item.id}
                  onClick={() => onChangeSelectedBank(item)}
                  onDeletePaymentMethodClicked={() => onDeletePaymentMethodClicked(item.id, 'bank')}
                  onUpdatePaymentMethodClicked={() => onUpdatePaymentMethodClicked(item.id, 'bank')}
                  checked={item.id === selectedWalletId}
                />
              ))}
              <div
                className="payment-item-add"
                onClick={onAddBankClicked}
                onMouseEnter={this.handleAddBankMouseEnter}
                onMouseLeave={this.handleAddBankMouseLeave}
              >
                <div
                  className="payment-item-radio"
                  tabIndex={0}
                  onClick={() => changeSelectedBank(addBankAccount)}
                  onKeyPress={() => changeSelectedBank(addBankAccount)}
                  onFocus={this.handleAddBankMouseEnter}
                  onBlur={this.handleAddBankMouseLeave}
                >
                  <Radio
                    checked={addBankAccount.id === selectedWalletId}
                    value={addBankAccount.id}
                    hovered={isAddBankHovered}
                  />
                </div>
                <div>
                  <span
                    className={
                      'add-bank' + (addBankAccount.id === selectedWalletId ? ' checked' : '')
                    }
                  >
                    <FormattedMessage id="ADD_BANK_ACCOUNT" defaultMessage="Add bank account" />
                  </span>
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {showBankForm && (
          <FormDraftProvider.Consumer>
            {({ updateDraft, bank, eft }) => (
              <BankForm
                bankDraft={bank || eft}
                paymentMethodType={payment.paymentMethodType}
                updateFormDraft={(draft) => updateDraft(payment.paymentMethodType, draft)}
                {...bankFormProps}
              />
            )}
          </FormDraftProvider.Consumer>
        )}

        <style jsx>{`
          .payment-items-header {
            display: block;
            font-size: ${fontSize.xxs};
            color: ${colors.lightGray};
            height: 25px;
            vertical-align: middle;
            font-family: AvenirNextforINTUIT-Medium;
          }

          .payment-items-container {
            width: 100%;
            margin-bottom: 16px;
          }

          .payment-item-add {
            height: 51px;
            cursor: pointer;
            display: flex;
            border-top: 1px solid ${colors.gray05};
            align-items: center;
            .payment-item-radio {
              &:focus {
                outline: none;
              }
            }
          }

          .add-bank {
            font-size: ${fontSize.xs};
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: ${colors.darkGray};
            margin-left: 20px;

            &.checked {
              font-family: AvenirNextforINTUIT-Medium;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}
export default BankTransfer;
