import React from 'react';

interface FormDraftProviderState {
  dc: null;
  bank: null;
  cc: null;
  'dc,cc': null;
  eft: null;
}

type updateDraftType = { updateDraft: (paymentMethodType: string, draft: any) => void };

const FormDraftContext = React.createContext<
  (Partial<FormDraftProviderState> & updateDraftType) | undefined
>(undefined);

class FormDraftProvider extends React.Component<{}, Partial<FormDraftProviderState>> {
  constructor(props: {}) {
    super(props);
    this.state = {
      dc: null,
      bank: null,
      cc: null,
      eft: null,
      'dc,cc': null,
    };
  }

  updateDraft = (paymentMethodType: string, draft: any) => {
    if (Object.keys(this.state).includes(paymentMethodType)) {
      this.setState({
        [paymentMethodType]: draft,
      });
    } else {
      throw Error('Unknown payment method type ' + paymentMethodType);
    }
  };

  render() {
    return (
      <FormDraftContext.Provider
        value={{
          ...this.state,
          updateDraft: this.updateDraft,
        }}
      >
        {this.props.children}
      </FormDraftContext.Provider>
    );
  }
}

export default {
  Consumer: FormDraftContext.Consumer,
  Provider: FormDraftProvider,
};
