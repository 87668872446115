import React, { Fragment, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { isWalletTypeOfBank } from 'businessLogic/Wallet/helpers';
import PaymentItem from 'components/Core/Payment/PaymentItem/PaymentItem';
import PaymentMethodBugs from 'components/Core/Payment/PaymentMethod/PaymentMethodBugs/PaymentMethodBugs';
import RadioItem from 'components/Core/Payment/PaymentMethod/SavePaymentMethod/RadioItem/RadioItem';
import SpinnerCentered from 'components/Shared/Spinner/SpinnerCentered/SpinnerCentered';
import SegmentIO from 'reporting/SegmentIO';
import {
  walletTokenizationPaymentCreation as createPayment,
  paymentMethodTypeChange,
} from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { getDefaultWalletFromList } from 'store/wallet/helpers';
import { changeSelectedWallet, walletActions } from 'store/wallet/slice';
import { breakpoints, colors } from 'styles/cp';
import { TXN_MAP, WALLET_MAP } from 'types/constants';

const WalletFetchStatus = WALLET_MAP.FETCH_STATUS;

export interface Props {
  wallet: any;
  payment: any;
  cdn: string;
  createPayment: () => void;
  paymentMethodTypeChange: typeof paymentMethodTypeChange;
  changeSelectedWallet: (card: any) => void;
  onCvvInputValueChange: () => void;
  onDeletePaymentMethodClicked: (...args: any[]) => void;
  onUpdatePaymentMethodClicked: (...args: any[]) => void;
  onAddPaymentMethodClicked: () => void;
  bindFormSubmission: () => void;
  isActiveSubscription: boolean;
  sale: any;
}

export const MINIMUM_VISIBLE_WALLETS_AMOUNT = 6;

export const SavedPaymentMethods: React.FC<Props> = ({
  wallet,
  payment,
  cdn,
  changeSelectedWallet,
  onCvvInputValueChange,
  onDeletePaymentMethodClicked,
  onUpdatePaymentMethodClicked,
  onAddPaymentMethodClicked,
  createPayment,
  paymentMethodTypeChange,
  bindFormSubmission,
  isActiveSubscription,
  sale = {},
}) => {
  const {
    userWallets,
    selectedWalletId,
    fetchWalletStatus,
    enabledCCTypes,
    enabledPaymentMethods,
  } = wallet;

  if (fetchWalletStatus === WalletFetchStatus.FETCHING) {
    return <SpinnerCentered />;
  }

  if (!userWallets || !userWallets.length) {
    return <Fragment />;
  }

  const [shouldShowAllPaymentMethods, setShouldShowAllPaymentMethods] = useState(false);
  const [walletsToShow, setWalletsToShow] = useState<Array<any>>([]);

  useEffect(() => {
    let wallets = [];
    if (userWallets.length < MINIMUM_VISIBLE_WALLETS_AMOUNT + 1) {
      wallets = [...userWallets];
      const selectedWallet = getDefaultWalletFromList(wallets);
      updatePaymentMethod(selectedWallet);
      setShouldShowAllPaymentMethods(true);
    } else {
      wallets = userWallets.filter((wallet: any) => wallet.default);
      const restWallets = userWallets.filter((wallet: any) => !wallet.default);
      let i = 0;
      while (wallets.length < MINIMUM_VISIBLE_WALLETS_AMOUNT) {
        wallets.push(restWallets[i]);
        i++;
      }
      setShouldShowAllPaymentMethods(false);
    }
    if (isActiveSubscription) {
      const subscriptionWallet = userWallets.find((wallet: any) =>
        saleSelectors.isWalletUsedForActiveSubscription(sale, wallet)
      );
      if (subscriptionWallet) {
        updatePaymentMethod(subscriptionWallet);

        // If the wallet is already shown do nothing,
        // else add it to the list of shown wallets
        const wallet = wallets.find((wallet: any) => wallet.id === subscriptionWallet.id);
        if (!wallet) {
          wallets.unshift(subscriptionWallet);
          wallets.pop();
        }
      }
    } else if (selectedWalletId) {
      const selectedWallet =
        wallets.find((wallet: any) => wallet.id === selectedWalletId) || wallets[0];
      updatePaymentMethod(selectedWallet);
    } else {
      const selectedWallet = wallets[0];
      updatePaymentMethod(selectedWallet);
    }
    setWalletsToShow(wallets);
  }, [userWallets]);

  const updatePaymentMethod = ({ id, walletType }: Record<string, any>) => {
    SegmentIO.transactionEngaged({
      ui_object: 'radio_button',
      ui_object_detail: walletType,
      ui_action: 'enabled',
      ui_access_point: 'transaction_flow',
      activityType: 'wallet',
    });
    changeSelectedWallet(id);
    paymentMethodTypeChange({ paymentMethodType: isWalletTypeOfBank(walletType) ? 'bank' : 'cc' });
  };

  const onShowAllMethodsClick = () => {
    const shownWalletsId = walletsToShow.map(({ id }) => id);
    const restWallets = userWallets.filter((wallet: any) => !shownWalletsId.includes(wallet.id));
    setWalletsToShow([...walletsToShow, ...restWallets]);
    setShouldShowAllPaymentMethods(true);
  };

  const onAddPaymentMethod = () => {
    SegmentIO.transactionEngaged({
      ui_object: 'button',
      ui_object_detail: 'new_payment_method',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });
    onAddPaymentMethodClicked();
  };

  return (
    <Fragment>
      <div>
        {walletsToShow.map((item: any) =>
          isWalletTypeOfBank(item.walletType) ? (
            <PaymentItem
              key={item.id}
              itemType="bank"
              item={item}
              cdn={cdn}
              bindFormSubmission={bindFormSubmission}
              onClick={() => updatePaymentMethod(item)}
              onDeletePaymentMethodClicked={onDeletePaymentMethodClicked}
              onUpdatePaymentMethodClicked={onUpdatePaymentMethodClicked}
              checked={item.id === selectedWalletId}
            />
          ) : (
            <PaymentItem
              key={item.id}
              itemType="card"
              item={item}
              cdn={cdn}
              bindFormSubmission={bindFormSubmission}
              createPayment={createPayment}
              onClick={() => updatePaymentMethod(item)}
              onDeletePaymentMethodClicked={onDeletePaymentMethodClicked}
              onUpdatePaymentMethodClicked={onUpdatePaymentMethodClicked}
              checked={item.id === selectedWalletId}
              isPaymentInProgress={payment.paymentStatus === TXN_MAP.STATUS.IN_PROGRESS}
              onCvvInputValueChange={onCvvInputValueChange}
              selectedCardCvv={wallet.selectedCardCvv}
            />
          )
        )}
        {userWallets.length > MINIMUM_VISIBLE_WALLETS_AMOUNT && !shouldShowAllPaymentMethods && (
          <RadioItem
            label={
              <FormattedMessage
                id="SHOW_ALL_SAVED_PAYMENT_METHODS"
                defaultMessage="Show all saved payment methods"
              />
            }
            onClick={onShowAllMethodsClick}
          />
        )}

        <div className="add">
          <button className="btn" onClick={onAddPaymentMethod}>
            + <FormattedMessage id="NEW_PAYMENT_METHOD" defaultMessage="New payment method" />
          </button>
          <div className="desktop-payment-method-bugs">
            <PaymentMethodBugs
              enabledCCTypes={enabledCCTypes}
              cdn={cdn}
              enabledPaymentMethods={enabledPaymentMethods}
            />
          </div>
        </div>
      </div>

      <style jsx>{`
        .add {
          padding-top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .btn {
            background-color: #fff;
            border: none;
            border-radius: 4px;
            color: ${colors.intuit_blue};
            font-size: 14px;
            font-family: AvenirNextforINTUIT-Demi;
            padding: 0;
            text-align: center;
            cursor: pointer;
          }

          .desktop-payment-method-bugs {
            display: inline-block;

            @media screen and (max-width: ${breakpoints.md}) {
              display: none;
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

function mapStateToProps(state: any) {
  const { wallet, config, payment, sale } = state;
  return {
    wallet,
    payment,
    cdn: config.endpoints.cdn,
    isActiveSubscription: saleSelectors.isSubscriptionActive(sale),
    sale,
  };
}

export default connect(mapStateToProps, {
  createPayment,
  paymentMethodTypeChange,
  changeSelectedWallet,
  onCvvInputValueChange: walletActions.onCvvInputValueChange,
  // @ts-ignore
})(SavedPaymentMethods);
