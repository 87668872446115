import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const phoneValidator: WalletValidatorFunction = (phone: any) => {
  const errors: ValidationError[] = [];
  if (typeof phone !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    const phoneRegExp = /^[0123456789 ()-]*$/;
    const result =
      phoneRegExp.test(phone) && phone.match(/\d/g) && phone.match(/\d/g).length === 10;

    if (!result) {
      errors.push(ValidationErrorEnum.INVALID_PHONE_NUMBER);
    }
  }

  return [errors.length === 0, errors];
};
