// @ts-nocheck

import debugCreator from 'debug';
import React, { Fragment } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';

import CountryField from 'components/Core/Payment/PaymentForm/Card/CountryField/CountryField';
import ScrollingForm from 'components/Core/Payment/PaymentForm/ScrollingForm/ScrollingForm';
import {
  CardField,
  CardTokenForm,
  ICardTokenCreateFormData,
} from 'components/Core/WalletForms/src/CreateCardTokenForm';
import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';
import { CardType } from 'components/Core/WalletForms/validations/src/types';
import { getPaymentMethodBug } from 'components/Shared/Icons/Icons';
import ValidatedLabeledInput from 'components/Shared/Inputs/ValidatedLabeledInput';
import SegmentIO from 'reporting/SegmentIO';
import { IXPSelectorState, getTrustedCheckoutVariation } from 'store/ixp/selector';
import { connect } from 'store/utils';
import { formErrorTranslator } from 'store/wallet/errorTranslators';
import { cardHelpers, creditCardHelpers } from 'store/wallet/helpers';
import { REMOTE_BUTTON_ACCESS_FUNCTIONALITY } from 'store/wallet/slice';
import { colors, breakpoints } from 'styles/cp';
import { Config } from 'types/Config';
import { TXN_MAP, COUNTRY } from 'types/constants';
import { Nullable } from 'types/utils';
const debug = debugCreator('CPV2:CardForm');

type CardFormProps = {
  paymentStatus?: string;
  companyLocale?: string;
  boundCreateCardFn?: (...args: any[]) => any;
  paymentMethodType: 'dc' | 'cc' | 'dc,cc';
  bindFormSubmission: (...args: any[]) => any;
  updateFormDraft: (...args: any[]) => any;
  createPayment: (...args: any[]) => any;
  stopPayment: (...args: any[]) => any;
  scrollToRef?: (...args: any[]) => any;
  updateCountry: (...args: any[]) => any;
  allowedCardTypes?: any[];
  enabledCCTypes?: any[];
  countryCode?: string;
  country: Record<string, any>;
  featureFlags: Record<string, any>;
  trustedCheckoutVariation: boolean;
  intl: IntlShape;
  endpoints: Config['endpoints'];
  cardDraft?: {
    values: ICardTokenCreateFormData;
    enumErrors: ICardTokenCreateFormData;
    touched: boolean;
  };
};

type CardFormState = {
  isAccountNumberTooltipOpen: boolean;
  isRoutingNumberTooltipOpen: boolean;
  paymentMethodType: null;
  forceWidgetToReinitialize: boolean;
  trustedCheckoutState: any;
  lastScrollTarget?: Nullable<object>;
  isLastScrollUserTriggered?: boolean;
};

export class CardForm extends React.Component<
  CardFormProps,
  CardFormState,
  { trustedCheckoutVariation?: string }
> {
  cardNameRef: React.RefObject<unknown>;
  cardNumberRef: React.RefObject<unknown>;
  cardExpDateRef: React.RefObject<unknown>;
  cardCvvRef: React.RefObject<unknown>;
  cardZipRef: React.RefObject<unknown>;
  static getDerivedStateFromProps(props: CardFormProps, state: CardFormState) {
    if (props.paymentMethodType !== state.paymentMethodType) {
      return {
        ...state,
        paymentMethodType: props.paymentMethodType,
        forceWidgetToReinitialize: true,
      };
    }
    return state;
  }

  constructor(props: CardFormProps) {
    super(props);
    debug('constructor');
    this.cardNameRef = React.createRef();
    this.cardNumberRef = React.createRef();
    this.cardExpDateRef = React.createRef();
    this.cardCvvRef = React.createRef();
    this.cardZipRef = React.createRef();
    this.state = {
      isAccountNumberTooltipOpen: false,
      isRoutingNumberTooltipOpen: false,
      paymentMethodType: null,
      forceWidgetToReinitialize: true,
      trustedCheckoutState: this.trustedCheckoutUtils.getInitialFieldsState(),
    };
    this.errorTranslator = formErrorTranslator();
  }
  handleFormSubmit = (values, walletFormikBag) => {
    const { setSubmitting } = walletFormikBag;
    this.props.createPayment({
      ccDetails: {
        ...values,
      },
    });
    setSubmitting(true);
  };
  bindSubmitForm = (fn: Function) => {
    // Bind the Wallet Form submission trigger to the interested remote parties
    // such as Redux, or a parent component. If they trigger this fn function,
    // Wallet Form (Formik) will start the submission process.
    if (this.props.bindFormSubmission) {
      // Have we already done this before?
      if (this.props.boundCreateCardFn === fn) {
        debug('We have already bound the form submission trigger. No action needed.');
        return;
      }
    }
    this.props.bindFormSubmission &&
      setTimeout(
        () =>
          this.props.bindFormSubmission({
            fn,
            functionality: REMOTE_BUTTON_ACCESS_FUNCTIONALITY.CARD_CREATE_PAYMENT,
          }),
        0
      );
  };

  trustedCheckoutUtils = {
    config: {
      cardNumber: {
        styling: {
          regular:
            'border-bottom-left-radius: 0; border-bottom-right-radius: 0; border-bottom: none;',
          addToWhenFieldError: {
            expDate: `border-top-color: ${colors.error};`,
            cvv: `border-top-color: ${colors.error};`,
          },
        },
      },
      expDate: {
        styling: {
          regular:
            'border-top-left-radius: 0; border-top-right-radius: 0; border-bottom-right-radius: 0; border-right: none;',
          addToWhenFieldError: {
            expDate: `border-top-color: ${colors.error};`,
            cvv: `border-left-color: ${colors.error};`,
          },
        },
      },
      cvv: {
        styling: {
          regular:
            'border-top-left-radius: 0; border-top-right-radius: 0; border-bottom-left-radius: 0;',
        },
      },
    },
    createFieldsState: (styleSetter: Function, errorSetter?: Function) => {
      const configEntries = Object.entries(this.trustedCheckoutUtils.config);
      const fields = {};
      configEntries.forEach(([fieldName, fieldConfig]) => {
        const { style, error } = fields[fieldName] || {};
        fields[fieldName] = {
          style: styleSetter ? styleSetter(fieldName, fieldConfig) : style,
          error: errorSetter ? errorSetter(fieldName, fieldConfig) : error,
        };
      });

      return fields;
    },
    createFieldErrorMessageSetter: (fieldName) => {
      if (this.props.trustedCheckoutVariation) {
        return (errorComponent) => {
          const isErrorChanged =
            errorComponent.props.id !== this.state.trustedCheckoutState[fieldName].error.props.id;

          if (isErrorChanged) {
            this.trustedCheckoutUtils.setFieldToState(fieldName, 'error', errorComponent);
            this.trustedCheckoutUtils.setFieldStylesOnErrorChange();
          }
        };
      }
    },
    getLabelIfNotTrustedCheckout: (label: JSX.Element) =>
      !this.props.trustedCheckoutVariation && label,
    getFieldStyle: (fieldName) => {
      return (
        this.props.trustedCheckoutVariation && this.state.trustedCheckoutState[fieldName].style
      );
    },
    getInitialFieldsState: () => {
      return this.trustedCheckoutUtils.createFieldsState(
        (
          _: never,
          fieldConfig: {
            styling: {
              regular: boolean;
            };
          }
        ) => fieldConfig.styling.regular,
        () => <></>
      );
    },
    getDefaultFieldStyles: () => {
      return this.trustedCheckoutUtils.createFieldsState(
        (
          _: never,
          fieldConfig: {
            styling: {
              regular: boolean;
            };
          }
        ) => fieldConfig.styling.regular
      );
    },
    setFieldToState: (fieldName: string, propName: string, toApply: object) => {
      const trustedCheckoutState = this.state.trustedCheckoutState;
      trustedCheckoutState[fieldName][propName] = toApply;
      this.setState({ trustedCheckoutState });
    },
    setFieldStylesOnErrorChange: () => {
      const calculateStyles = () => {
        const addErrorStyling = (newStyles) => {
          this.trustedCheckoutUtils.createFieldsState(
            (
              fieldName: string,
              fieldConfig: {
                styling: {
                  addToWhenFieldError: object[];
                };
              }
            ) => {
              const fieldError = this.state.trustedCheckoutState[fieldName].error.props.id;

              if (fieldError) {
                const errorStylingConfig = fieldConfig.styling.addToWhenFieldError;
                if (errorStylingConfig) {
                  Object.entries(errorStylingConfig).forEach(([fieldName, fieldStyle]) => {
                    newStyles[fieldName].style = `${newStyles[fieldName].style} ${fieldStyle}`;
                  });
                }
              }
            }
          );
        };

        const newStyles = this.trustedCheckoutUtils.getDefaultFieldStyles();
        addErrorStyling(newStyles);

        return newStyles;
      };
      const applyStylesToState = (newStyles) => {
        const newStyleEntries = Object.entries(newStyles);
        newStyleEntries.forEach(([fieldName, fieldConfig]) => {
          this.trustedCheckoutUtils.setFieldToState(fieldName, 'style', fieldConfig.style);
        });
      };

      const newStyles = calculateStyles();
      applyStylesToState(newStyles);
    },
    renderFieldErrorMessages: () => {
      const fieldEntries =
        this.props.trustedCheckoutVariation && Object.entries(this.state.trustedCheckoutState);

      return (
        fieldEntries && (
          <div className="trusted-error-wrapper">
            {fieldEntries.map(([fieldName, fieldState]) => {
              const isError = fieldState.error.props.id;
              return (
                isError && (
                  <div
                    key={`${fieldName}-error-message`}
                    className="error-row"
                    data-testid={`${fieldName}-error-message`}
                  >
                    {fieldState.error}
                  </div>
                )
              );
            })}
            <style jsx>{`
              .trusted-error-wrapper {
                margin-top: 8px;
                .error-row {
                  margin: 4px 0;
                }
              }
            `}</style>
          </div>
        )
      );
    },
  };

  componentDidUpdate() {
    if (this.state.forceWidgetToReinitialize) {
      this.setState({
        forceWidgetToReinitialize: false,
      });
    }
  }

  render() {
    debug('render');
    const {
      intl,
      paymentStatus,
      cardDraft,
      companyLocale = '',
      endpoints: { cdn },
      enabledCCTypes = [],
      featureFlags,
      trustedCheckoutVariation,
    } = this.props;

    const {
      values: cardDraftValues,
      touched: cardDraftTouched,
      enumErrors: cardDraftEnumErrors,
    } = cardDraft || {};
    const placeHolderName = intl.formatMessage({
      id: 'PAYFLOW_PLACEHOLDERS_FIRST_LAST_NAME',
      defaultMessage: 'First name Last name',
    });
    let creditCardHelperData = creditCardHelpers.initializing();

    const country = companyLocale.split('_')[1];
    const isUsOrCanada = country === COUNTRY.US || country === COUNTRY.CA;
    const shouldShowCountryField = featureFlags['should-show-country-field'] && isUsOrCanada;

    return (
      <CardTokenForm
        initialValues={cardDraftValues}
        handleSubmit={this.handleFormSubmit}
        bindSubmitForm={this.bindSubmitForm}
        validateOnBlur={true}
        validateOnChange={false}
        enableReinitialize={this.state.forceWidgetToReinitialize}
        allowedCardTypes={this.props.allowedCardTypes}
        zipCodeLength={this.props.countryCode === COUNTRY.US ? 5 : 4}
      >
        {({
          validateForm,
          isValidating,
          isSubmitting,
          setSubmitting,
          setFieldError,
          setErrors,
          setTouched,
          setFieldTouched,
          setFieldValue,
          enumErrors,
          touched,
          values,
          dirty,
        }) => {
          if (
            (cardDraftValues || dirty) &&
            cardDraftTouched &&
            this.state.forceWidgetToReinitialize
          ) {
            // We are here because we just started this form from a draft. Let's restore all of the errors back.
            setErrors(cardDraftEnumErrors);
            validateForm().then(() => {
              setTouched(cardDraftTouched);
            });
          } else if (!dirty && this.state.forceWidgetToReinitialize) {
            setErrors({});
          }
          // Tell Redux to stop payment. CardTokenForm found errors, the form is invalid.
          if (isSubmitting && !isValidating && Object.keys(enumErrors).length > 0) {
            setSubmitting(false);
            this.props.stopPayment();
          }
          // If Redux is telling us that there is an issue and the payment has failed, set the form to edit mode.
          if (isSubmitting && paymentStatus !== TXN_MAP.STATUS.IN_PROGRESS) {
            setSubmitting(false);
            if (
              !isValidating &&
              (paymentStatus === TXN_MAP.STATUS.ERROR || paymentStatus === TXN_MAP.STATUS.DECLINED)
            ) {
              setFieldValue('name', '');
              setFieldValue('number', '');
              setFieldValue('expDate', '');
              setFieldValue('cvc', '');
              setFieldValue('zipCode', '');
            }
          }
          const scrollIfInError = ({ name }: { name: string }) => {
            const isInError =
              touched[name] && enumErrors[name] && typeof enumErrors[name][0] !== 'undefined';
            if (!isInError) {
              this.state.lastScrollTarget !== null &&
                this.setState({
                  lastScrollTarget: null,
                  isLastScrollUserTriggered: false,
                });
              return null;
            }
            let refName;
            switch (name) {
              case 'name':
                refName = this.cardNameRef;
                break;
              case 'number':
                refName = this.cardNumberRef;
                break;
              case 'expDate':
                refName = this.cardExpDateRef;
                break;
              case 'cvc':
                refName = this.cardCvvRef;
                break;
              case 'zipCode':
                refName = this.cardZipRef;
                break;
              default:
                refName = null;
            }
            this.props.scrollToRef && this.props.scrollToRef(refName, false);
          };
          const onCardFieldFocus = (
            _field: { name: string; onFocus?: Function },
            fieldRef: React.ElementRef<any>
          ) => {
            return (e: React.FocusEvent<HTMLInputElement>) => {
              SegmentIO.cardFormFieldClicked(_field.name);
              // For tests this is necessary
              if (!e.target.name) {
                e.target.name = _field.name;
              }
              _field.onFocus && _field.onFocus(e);
              this.props.scrollToRef && this.props.scrollToRef(fieldRef, true);
            };
          };
          const onCardFieldChange = (_field: { name: string; onChange: Function }) => {
            return (e: React.ChangeEvent<HTMLInputElement>) => {
              // For tests this is necessary
              if (!e.target.name) {
                e.target.name = _field.name;
              }
              if (e.target.name === 'number') {
                //save current location before change value to raw.
                creditCardHelpers.onChange(creditCardHelperData, e);
                e.target.value = cardHelpers.getRawCardNumber(e.target.value);
                values[_field.name] = e.target.value;
                validateForm().then(() => {
                  setFieldTouched(
                    _field.name,
                    values.cardType === CardType.AMERICAN_EXPRESS &&
                      !enabledCCTypes.includes('amex')
                  );
                });
              }
              // Respect the rest of the event propagation
              _field.onChange && _field.onChange(e);
              // Only clear the errors from the field if the value has changed.
              if (values[_field.name] !== e.target.value) {
                setFieldError(_field.name, null);
              }
            };
          };
          const onCardFieldBlur = (_field: { name: string; onBlur: Function }) => {
            return (e: React.FocusEvent<HTMLInputElement>) => {
              const value = e.target.value;
              // For tests this is necessary
              if (!e.target.name) {
                e.target.name = _field.name;
              }
              _field.onBlur && _field.onBlur(e);
              // If the form is dirty, then update the form draft on the Redux Store.
              this.props.updateFormDraft &&
                this.props.updateFormDraft({
                  values,
                  enumErrors,
                  touched,
                });
              // If the value is empty then consider this field untouched.
              setFieldTouched(_field.name, value !== null && value !== '');
            };
          };
          const getCardIcon = () => {
            if (typeof values.cardType === 'string') {
              debug(`card type: ${values.cardType}`);
              return (
                <div className="bug-wrapper">
                  <span className="helper">
                    {getPaymentMethodBug(values.cardType, cdn, intl.formatMessage)}
                  </span>

                  <style jsx global>{`
                    .bug-wrapper {
                      white-space: nowrap;
                      text-align: center;
                      height: 16px;
                      margin: 8px;

                      .helper {
                        display: inline-block;

                        :global(img) {
                          height: 16px;
                        }
                      }
                    }
                  `}</style>
                </div>
              );
            }
            return null;
          };
          const numberErrorTranslator = (err: number) => {
            let errVal = this.errorTranslator(err);
            if (
              err === ValidationErrorEnum.INVALID_CARD_NUMBER &&
              values.cardType === CardType.AMERICAN_EXPRESS &&
              !enabledCCTypes.includes('amex')
            ) {
              errVal = 'PAYFLOW_VALIDATION_CARD_AMEX_NOT_ALLOWED';
            }
            return errVal;
          };
          const getCvvError = () => {
            const cvvErrors = enumErrors['cvc'];
            let error = cvvErrors[0];
            if (cvvErrors && cvvErrors.indexOf(ValidationErrorEnum.INVALID_CVC_AMEX) > -1) {
              error = ValidationErrorEnum.INVALID_CVC_AMEX;
            } else if (
              cvvErrors &&
              cvvErrors.indexOf(ValidationErrorEnum.INVALID_CVC_NON_AMEX) > -1
            ) {
              error = ValidationErrorEnum.INVALID_CVC_NON_AMEX;
            }
            return error;
          };
          /**
           * Helper to check if locale in a given country
           *
           * Curried function
           * @param {String} twoLetterCountryCode
           */
          const isIn = (twoLetterCountryCode: string) => {
            /**
             * Closure
             * @param {String} locale
             */
            const checkForCountryInLocale = (locale: string) =>
              locale && locale.indexOf(twoLetterCountryCode) > -1;
            return checkForCountryInLocale;
          };
          const isCanadianCompany = isIn(COUNTRY.CA)(companyLocale);
          const isUSACompany = isIn(COUNTRY.US)(companyLocale);

          return (
            <Fragment>
              <div
                className={'card-form-wrapper ' + (trustedCheckoutVariation ? 'trusted-form' : '')}
              >
                <div className="form-row card-number" data-testid="field-cardNumber">
                  <CardField name="number">
                    {({ field: { value, ...field } }) => (
                      <div ref={this.cardNumberRef}>
                        <ValidatedLabeledInput
                          {...field}
                          autoComplete={'cc-number'}
                          value={creditCardHelpers.formatCreditCard(
                            value,
                            values.cardType === CardType.AMERICAN_EXPRESS,
                            creditCardHelperData
                          )}
                          translator={numberErrorTranslator}
                          validationError={
                            touched[field.name] &&
                            enumErrors[field.name] &&
                            enumErrors[field.name][0]
                          }
                          validationValues={{ cardType: values.cardType }}
                          onFocus={onCardFieldFocus(field, this.cardNumberRef)}
                          onChange={onCardFieldChange(field)}
                          onBlur={onCardFieldBlur(field)}
                          onSelect={creditCardHelpers.onSelect(field, creditCardHelperData)}
                          onKeyPress={creditCardHelpers.onKeyPress(field, creditCardHelperData)}
                          onKeyDown={creditCardHelpers.onKeyDown(field, creditCardHelperData)}
                          label={this.trustedCheckoutUtils.getLabelIfNotTrustedCheckout(
                            <FormattedMessage
                              id="PAYFORM_CARD_NUMBER"
                              defaultMessage="Card number"
                            />
                          )}
                          inputMode="decimal"
                          pattern="[0-9 ]*"
                          disabled={isSubmitting}
                          type="text"
                          maxLength={values.cardType !== CardType.AMERICAN_EXPRESS ? 19 : 17}
                          dir="ltr"
                          placeholder="1234 5678 9000 0000"
                          inputCustomStyling={this.trustedCheckoutUtils.getFieldStyle('cardNumber')}
                          setFieldErrorMessage={this.trustedCheckoutUtils.createFieldErrorMessageSetter(
                            'cardNumber'
                          )}
                        >
                          {getCardIcon()}
                        </ValidatedLabeledInput>
                        {scrollIfInError(field)}
                      </div>
                    )}
                  </CardField>
                </div>

                <div className="mobile-break" />

                <CardField name="expDate">
                  {({ field }) => (
                    <div className="exp-date" ref={this.cardExpDateRef}>
                      <ValidatedLabeledInput
                        {...field}
                        autoComplete={'cc-exp'}
                        value={cardHelpers.formatExpirationDate(values['expDate'])}
                        translator={this.errorTranslator}
                        validationError={
                          touched[field.name] && enumErrors[field.name] && enumErrors[field.name][0]
                        }
                        onFocus={onCardFieldFocus(field, this.cardExpDateRef)}
                        onChange={onCardFieldChange(field)}
                        onBlur={onCardFieldBlur(field)}
                        disabled={isSubmitting}
                        label={this.trustedCheckoutUtils.getLabelIfNotTrustedCheckout(
                          <FormattedMessage id="PAYFORM_CARD_EXP_DATE" defaultMessage="Exp date" />
                        )}
                        inputMode="decimal"
                        pattern="[0-9\/]*"
                        maxLength="5"
                        dir="ltr"
                        placeholder={intl.locale === 'fr' ? 'MM/AA' : 'MM/YY'}
                        inputCustomStyling={this.trustedCheckoutUtils.getFieldStyle('expDate')}
                        setFieldErrorMessage={this.trustedCheckoutUtils.createFieldErrorMessageSetter(
                          'expDate'
                        )}
                      />
                      {scrollIfInError(field)}
                    </div>
                  )}
                </CardField>

                <CardField name="cvc">
                  {({ field }) => (
                    <div className="cvv" ref={this.cardCvvRef}>
                      <ValidatedLabeledInput
                        {...field}
                        autoComplete={'cc-csc'}
                        translator={this.errorTranslator}
                        validationError={
                          touched[field.name] && enumErrors[field.name] && getCvvError()
                        }
                        onFocus={onCardFieldFocus(field, this.cardCvvRef)}
                        onChange={onCardFieldChange(field)}
                        onBlur={onCardFieldBlur(field)}
                        disabled={isSubmitting}
                        label={this.trustedCheckoutUtils.getLabelIfNotTrustedCheckout(
                          <FormattedMessage id="PAYFORM_CARD_CVV_CODE" defaultMessage="CVV code" />
                        )}
                        inputMode="decimal"
                        pattern="[0-9]*"
                        type="text"
                        maxLength={values.cardType === CardType.AMERICAN_EXPRESS ? 4 : 3}
                        dir="ltr"
                        placeholder="123"
                        inputCustomStyling={this.trustedCheckoutUtils.getFieldStyle('cvv')}
                        setFieldErrorMessage={this.trustedCheckoutUtils.createFieldErrorMessageSetter(
                          'cvv'
                        )}
                      />
                      {scrollIfInError(field)}
                    </div>
                  )}
                </CardField>

                {this.trustedCheckoutUtils.renderFieldErrorMessages()}

                <div className="break" />
                <div className="mobile-break" />

                {!!trustedCheckoutVariation && <div className={'trusted-row-break'}></div>}

                <div className="form-row name-on-card" data-testid="field-name">
                  <CardField name="name">
                    {({ field }) => (
                      <div ref={this.cardNameRef}>
                        <ValidatedLabeledInput
                          {...field}
                          autoComplete={'cc-name'}
                          translator={this.errorTranslator}
                          validationError={
                            touched[field.name] &&
                            enumErrors[field.name] &&
                            enumErrors[field.name][0]
                          }
                          onBlur={onCardFieldBlur(field)}
                          onChange={onCardFieldChange(field)}
                          onFocus={onCardFieldFocus(field, this.cardNameRef)}
                          disabled={isSubmitting}
                          label={
                            <FormattedMessage
                              id="PAYFORM_NAME_ON_CARD"
                              defaultMessage="Name on card"
                            />
                          }
                          type="text"
                          maxLength="40"
                          placeholder={placeHolderName}
                        />
                        {scrollIfInError(field)}
                      </div>
                    )}
                  </CardField>
                </div>

                {shouldShowCountryField && (
                  <Fragment>
                    <div className="mobile-break" />

                    <div className="country">
                      <FormattedMessage id="COUNTRY" defaultMessage="Country" />
                      <CountryField
                        cdn={cdn}
                        country={this.props.country}
                        onChange={this.props.updateCountry}
                      />
                    </div>
                  </Fragment>
                )}

                <div className="mobile-break" />

                <div className="zip">
                  <CardField name="zipCode">
                    {({ field }) => (
                      <div ref={this.cardZipRef}>
                        <ValidatedLabeledInput
                          {...field}
                          autoComplete={'postal-code'}
                          translator={this.errorTranslator}
                          validationError={
                            touched[field.name] &&
                            enumErrors[field.name] &&
                            enumErrors[field.name][0]
                          }
                          onFocus={onCardFieldFocus(field, this.cardZipRef)}
                          onChange={onCardFieldChange(field)}
                          onBlur={onCardFieldBlur(field)}
                          disabled={isSubmitting}
                          label={
                            <FormattedMessage
                              id="PAYFLOW_CARD_POSTAL_CODE"
                              defaultMessage="Postal code"
                            />
                          }
                          inputMode={isUSACompany ? 'decimal' : undefined}
                          type="text"
                          maxLength="9"
                          dir="ltr"
                          placeholder={isCanadianCompany ? 'L4W 5G1' : '12345'}
                        />
                        {scrollIfInError(field)}
                      </div>
                    )}
                  </CardField>
                </div>
              </div>

              <style jsx>{`
                .card-form-wrapper {
                  display: flex;
                  flex-wrap: wrap;
                  justify-items: center;
                  align-items: flex-start;
                  row-gap: 16px;
                  padding-bottom: 12px;

                  @media screen and (max-width: ${breakpoints.md}) {
                    row-gap: 8px;
                  }

                  .break {
                    flex-basis: 100%;
                    width: 0;

                    @media screen and (max-width: ${breakpoints.md}) {
                      flex-basis: 0;
                    }
                  }

                  .mobile-break {
                    @media screen and (max-width: ${breakpoints.md}) {
                      flex-basis: 100%;
                      width: 0;
                    }
                  }

                  .card-number {
                    flex: 2;
                    padding-right: 21px;

                    @media screen and (max-width: ${breakpoints.md}) {
                      padding-right: 0;
                    }
                  }

                  .exp-date {
                    flex: 1;
                    margin-right: 20px;
                    min-width: 93px;
                    max-width: 130px;

                    @media screen and (max-width: ${breakpoints.md}) {
                      margin: 0;
                    }
                  }

                  .cvv {
                    flex: 1;
                    margin-left: 6px;
                    min-width: 74px;
                    max-width: 91px;

                    @media screen and (max-width: ${breakpoints.md}) {
                      margin: 0 6px;
                    }
                  }

                  .zip {
                    flex: ${shouldShowCountryField ? 1 : 2};
                    padding-left: 8px;
                    max-width: ${shouldShowCountryField ? '99px' : 'auto'};

                    @media screen and (max-width: ${breakpoints.md}) {
                      flex: auto;
                      max-width: 100%;
                      padding: 0;
                    }
                  }

                  .name-on-card {
                    flex: 2;
                    padding-right: ${shouldShowCountryField ? '21px' : '8px'};

                    @media screen and (max-width: ${breakpoints.md}) {
                      flex: auto;
                      padding-right: 0;
                    }
                  }

                  .country {
                    font-family: AvenirNextforINTUIT-Demi;
                    line-height: 20px;
                    font-size: 12px;
                    color: #4a4a4a;

                    flex: 1;
                    margin-right: 20px;
                    min-width: 93px;
                    max-width: 124px;

                    @media screen and (max-width: ${breakpoints.md}) {
                      flex: auto;
                      width: 100%;
                      margin: 0;
                      min-width: 0;
                      max-width: 100%;
                    }
                  }
                }

                .lock-wrap {
                  margin: 9px 15px 0 0;
                  opacity: 50%;
                }
                .payor-trust-row {
                  position: relative;
                }
                .payor-trust-input {
                  @media screen and (min-width: ${breakpoints.lg}) {
                    width: 250px;
                    display: inline-block;
                  }
                }
                ul {
                  @media screen and (max-width: ${breakpoints.lg}) {
                    display: none;
                  }
                  display: inline-block;
                  position: absolute;
                  top: 9px;
                  list-style: none;
                  text-align: center;

                  :global(li) {
                    display: inline-block;

                    &:not(first-child) {
                      margin-left: 5px;
                    }
                  }
                }
                .trusted-form {
                  row-gap: 0;
                  .trusted-row-break {
                    height: 16px;
                    @media screen and (max-width: ${breakpoints.md}) {
                      height: 8px;
                    }
                  }
                  .card-number {
                    flex: 100%;
                    padding-right: 0;
                    .input-wrapper {
                      border-radius: 0;
                    }
                  }
                  .exp-date,
                  .cvv {
                    flex: 50%;
                    min-width: unset;
                    max-width: unset;
                    margin-right: 0;
                    margin-left: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                  }
                }
              `}</style>
            </Fragment>
          );
        }}
      </CardTokenForm>
    );
  }
}

function mapStateToProps(store: IXPSelectorState) {
  return {
    trustedCheckoutVariation: getTrustedCheckoutVariation(store),
  };
}

export const CardFormWithIntl = injectIntl(CardForm);

export default ScrollingForm(connect(mapStateToProps)(CardFormWithIntl));
