import { ValidationErrorEnum } from './ValidationErrorEnum';
import { ValidationError, WalletValidatorFunction } from './types';

export const transitNumberValidator: WalletValidatorFunction = (transitNumber: any) => {
  const errors: ValidationError[] = [];

  if (typeof transitNumber !== 'string') {
    errors.push(ValidationErrorEnum.STRING_ERROR_MESSAGE);
  }

  if (errors.length === 0) {
    const transitNumberRegEx = /^\d{5}$/;
    const result = transitNumberRegEx.test(transitNumber);
    if (!result) {
      errors.push(ValidationErrorEnum.INVALID_TRANSIT_NUMBER);
    }
  }

  return [errors.length === 0, errors];
};
