import { FormikBag, FormikProps, InjectedFormikProps, FormikSharedConfig } from 'formik';
import React from 'react';

import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';
import { WalletValidatorFunction } from 'components/Core/WalletForms/validations/src/types';

export type Errors<T> = Partial<{ [K in keyof T | 'global']: ValidationErrorEnum[] }>;

export type AdditionalFormProps<Values> = {
  handleSubmit: (
    values: Values,
    formikBag: FormikBag<IWalletFormProps<Values>, Values>
  ) => void | Promise<void>;
  bindSubmitForm?: (submitFormFn: () => void) => void;
  additionalValidations?: Partial<{ [K in keyof Values]: WalletValidatorFunction[] }>;
} & FormikSharedConfig<Values>;

export type EnhancedFormikProps<T> = FormikProps<T> & { enumErrors: Errors<T> };

export interface IWalletFormProps<T, U = T> extends AdditionalFormProps<U> {
  children(props: EnhancedFormikProps<T>): JSX.Element;
}

export type Form<T, U> = (props: InjectedFormikProps<T & IWalletFormProps<U>, U>) => JSX.Element;

export const genericForm: Form<any, any> = <T, U>(
  props: InjectedFormikProps<T & IWalletFormProps<U>, U>
) => {
  // TODO: Can remove after https://github.com/jaredpalmer/formik/issues/1292 is addressed
  const { children, ...rest } = props;
  const enumErrors = rest.errors as any as Errors<U>;
  const newProps = {
    ...rest,
    enumErrors,
  };

  if (typeof props.bindSubmitForm === 'function') {
    props.bindSubmitForm(props.submitForm);
  }

  const onRemoteSubmitTriggered = (e: any) => {
    e.preventDefault();
    props.submitForm();
  };

  return <form onSubmit={(e) => onRemoteSubmitTriggered(e)}>{children(newProps)}</form>;
};
